import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Coord } from '@models/coord'
import { FusionInputButtonComponent } from '@module/fusion/components/fusion-input-button/fusion-input-button.component'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InputTextModule } from 'primeng/inputtext'
import { handleAddress } from '@shared/utils/value-form'
import { ButtonDirective } from 'primeng/button'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { latLng, Map, MapOptions, marker, Marker, tileLayer } from 'leaflet'

@Component({
  selector: 'arsb-side-coord-fusion',
  standalone: true,
  imports: [
    FusionInputButtonComponent,
    TranslateModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonDirective,
    LeafletModule,
  ],
  templateUrl: './side-coord-fusion.component.html',
  styleUrl: './side-coord-fusion.component.scss',
})
export class SideCoordFusionComponent implements OnInit {
  protected readonly handleAddress = handleAddress

  @Input() coord!: Coord
  @Input() rightSide!: boolean

  @Output() setValue = new EventEmitter<{ control: string; value: string }>()

  map?: Map
  mapOptions: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '...',
      }),
    ],
    zoom: 13,
    center: latLng(48.1119, -1.6806),
  }
  layers: Marker[] = []

  ngOnInit() {
    if (this.coord.latitude && this.coord.longitude) {
      this.layers = [marker([this.coord.latitude, this.coord.longitude])]
      this.map?.setView([this.coord.latitude, this.coord.longitude], 13)
    }
  }
}
