import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '@env/environment'
import { SearchedItem } from '@module/home/models/search'
import { Page } from '@models/page'

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private readonly http = inject(HttpClient)

  private readonly api = environment.apiUrl

  search(
    searchText?: string | undefined,
    latitude?: number | undefined,
    longitude?: number | undefined,
    distanceMeters?: number,
    page: number = 0,
    sorts: string[] = [],
  ) {
    let params = new HttpParams().set('page', page.toString()).set('size', 50)
    if (searchText) {
      params = params.set('searchText', searchText)
    }
    if (latitude) {
      params = params.set('latitude', latitude.toString())
    }
    if (longitude) {
      params = params.set('longitude', longitude.toString())
    }
    if (distanceMeters) {
      params = params.set('distanceMeters', distanceMeters.toString())
    }
    if (sorts.length > 0) {
      for (const sort of sorts) {
        params = params.append('sort', sort)
      }
    }
    return this.http.get<Page<SearchedItem>>(`${this.api}/search`, {
      params,
    })
  }
}
