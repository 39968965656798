<div
  [formGroup]="form"
  class="flex flex-col gap-3">
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="finessEj">{{ "structure.finess-ej" | translate }}</label>
      <input
        pInputText
        id="finessEj"
        formControlName="finessEj" />
    </div>
    <div class="flex flex-col">
      <label for="finessEg">{{ "structure.finess-eg" | translate }}</label>
      <input
        pInputText
        id="finessEg"
        formControlName="finessEg" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="siret">{{ "structure.siret" | translate }}</label>
      <input
        pInputText
        id="siret"
        formControlName="siret" />
    </div>
    <div class="flex flex-col">
      <label for="siren">{{ "structure.siren" | translate }}</label>
      <input
        pInputText
        id="siren"
        formControlName="siren" />
    </div>
  </div>
  <div class="grid xl:grid-cols-3 gap-4">
    <div class="flex flex-col">
      <label for="rna">{{ "structure.rna" | translate }}</label>
      <input
        pInputText
        id="rna"
        formControlName="rna" />
    </div>
  </div>
  <div class="flex flex-col">
    <label for="raisonSociale">{{ "structure.social-reason" | translate }}</label>
    <input
      pInputText
      id="raisonSociale"
      formControlName="raisonSociale" />
  </div>
  <div class="flex flex-col">
    <label for="enseigneCommerciale">{{ "structure.brand" | translate }}</label>
    <input
      pInputText
      id="enseigneCommerciale"
      formControlName="enseigneCommerciale" />
  </div>
  <div class="flex flex-col">
    <label for="nomUsage">{{ "structure.label-usage" | translate }}</label>
    <input
      pInputText
      id="nomUsage"
      formControlName="nomUsage" />
  </div>
  <div class="flex flex-col">
    <label for="secteurActivite">{{ "structure.sector-activity" | translate }}</label>
    <p-dropdown
      #sectorAct
      inputId="secteurActivite"
      styleClass="w-full"
      [options]="nosValues['secteurActiviteCode']"
      optionLabel="libelle"
      optionValue="code"
      formControlName="secteurActiviteCode"
      filter="true"
      [filterFields]="['code', 'libelle']"
      (onShow)="sectorAct.filterValue = null" />
  </div>
  <div class="flex flex-col">
    <label for="categorieEtablissement">{{ "structure.category" | translate }}</label>
    <p-dropdown
      #sectorAct
      inputId="categorieEtablissement"
      styleClass="w-full"
      [options]="nosValues['categorieEtablissementCode']"
      optionLabel="libelle"
      optionValue="code"
      formControlName="categorieEtablissementCode"
      filter="true"
      [filterFields]="['code', 'libelle']"
      (onShow)="sectorAct.filterValue = null" />
  </div>
  <div class="flex flex-col">
    <label for="categorieAgrEtablissement">{{ "structure.aggregate-category" | translate }}</label>
    <p-dropdown
      #sectorAct
      inputId="categorieAgrEtablissement"
      styleClass="w-full"
      [options]="nosValues['categorieAgrEtablissementCode']"
      optionLabel="libelle"
      optionValue="code"
      formControlName="categorieAgrEtablissementCode"
      filter="true"
      [filterFields]="['code', 'libelle']"
      (onShow)="sectorAct.filterValue = null" />
  </div>
  <hr class="border-2 my-4" />
  <arsb-center-coord-fusion formGroupName="structureCoord" />
</div>
