<div
  *ngIf="(ficheStructure || id === 'new') && formStructure"
  class="container relative">
  <p-breadcrumb
    class="max-w-full"
    [model]="breadcrumbItems"
    [home]="homeItem"></p-breadcrumb>
  <form
    [formGroup]="formStructure"
    class="py-4">
    <!-- Structure -->
    <arsb-form-component color="bg-blue-500">
      <div
        arsbContent
        class="flex-grow">
        <h1 class="mb-4">{{ "structure.structure" | translate }}</h1>

        <arsb-card-information
          [id]="id"
          [niveau]="ficheStructure?.niveau"
          [nationalStatut]="ficheStructure?.national?.statut"
          [nationalStatutDate]="ficheStructure?.national?.statutDate"
          [regionalStatut]="ficheStructure?.regional?.statut"
          [regionalStatutDate]="ficheStructure?.regional?.statutDate">
          <div
            arsbActions
            class="flex gap-3">
            <div
              *ngIf="id !== 'new'"
              class="flex flex-col items-end">
              <label for="disableStructure">{{ "common.disable" | translate }}</label>
              <p-inputSwitch
                inputId="disableStructure"
                formControlName="disableStructure"
                (onChange)="askForDeactivateOrReactivateStructure($event)" />
            </div>
          </div>
        </arsb-card-information>

        <div class="flex flex-col gap-4">
          <div class="flex items-center gap-4">
            <div
              *ngIf="id !== 'new'"
              class="flex flex-col">
              <label>
                {{ "structure.type" | translate }}
              </label>
              <div>
                <arsb-input
                  [value]="'structure.' + type | translate"
                  [hasCopyButton]="false"
                  [hasUpdateButton]="false"
                  [hasSetNullButton]="false" />
              </div>
            </div>
            <div
              *ngIf="id !== 'new'"
              class="flex flex-col">
              <label>
                {{ "structure.finess-ej" | translate }}
              </label>
              <div>
                <arsb-input
                  [value]="ficheStructure?.national?.finessEj ?? ficheStructure?.regional?.finessEj"
                  [hasUpdateButton]="false"
                  [hasSetNullButton]="false" />
              </div>
            </div>
            <div
              *ngIf="id !== 'new' && type === 'eg' && ficheStructure?.national?.finessEg"
              class="flex flex-col">
              <label>
                {{ "structure.finess-eg" | translate }}
              </label>
              <div>
                <arsb-input
                  [value]="ficheStructure?.national?.finessEg ?? ficheStructure?.regional?.finessEg"
                  [hasUpdateButton]="false"
                  [hasSetNullButton]="false" />
              </div>
            </div>
            <div
              *ngIf="id === 'new'"
              class="flex flex-col">
              <label for="type">
                {{ "structure.type" | translate }}
              </label>
              <div>
                <p-dropdown
                  inputId="type"
                  [options]="types"
                  styleClass="w-[202px]"
                  formControlName="type" />
              </div>
            </div>
            <div
              *ngIf="id === 'new'"
              class="flex flex-col">
              <label for="finessEj">
                {{ "structure.finess-ej" | translate }}
              </label>
              <div>
                <input
                  id="finessEj"
                  pInputText
                  formControlName="finessEj"
                  [maxlength]="9" />
              </div>
            </div>
            <div
              *ngIf="
                (id === 'new' &&
                  formStructure.controls['finessEj'].value?.length === 9 &&
                  formStructure.controls['type'].value === 'EG') ||
                (type === 'eg' && !ficheStructure?.national?.finessEg)
              "
              class="flex flex-col">
              <label for="finessEg">
                {{ "structure.finess-eg" | translate }}
              </label>
              <div>
                <input
                  id="finessEg"
                  pInputText
                  formControlName="finessEg"
                  [maxlength]="9" />
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex items-center gap-4">
              <div class="flex flex-col">
                <label for="siret">
                  {{ "structure.siret" | translate }}
                </label>
                <div>
                  <input
                    id="siret"
                    pInputText
                    formControlName="siret"
                    [placeholder]="ficheStructure?.national?.siret ?? ''"
                    [maxlength]="14" />
                </div>
              </div>
              <div class="flex flex-col">
                <label for="siren">
                  {{ "structure.siren" | translate }}
                </label>
                <div>
                  <input
                    id="siren"
                    pInputText
                    formControlName="siren"
                    [placeholder]="ficheStructure?.national?.siren ?? ''"
                    [maxlength]="9" />
                </div>
              </div>
              <div class="flex flex-col">
                <label for="rna">
                  {{ "structure.rna" | translate }}
                </label>
                <div>
                  <input
                    id="rna"
                    pInputText
                    formControlName="rna"
                    [placeholder]="ficheStructure?.national?.rna ?? ''"
                    [maxlength]="20" />
                </div>
              </div>
            </div>
            <span
              *ngIf="hasMaxLengthError(formStructure.controls['siret'])"
              class="text-red-400">
              {{ "error.max-length" | translate: { label: "structure.siret" | translate, length: 14 } }}
            </span>
            <span
              *ngIf="hasMaxLengthError(formStructure.controls['rna'])"
              class="text-red-400">
              {{ "error.max-length" | translate: { label: "structure.rna" | translate, length: 20 } }}
            </span>
          </div>
          <div class="mt-4 flex flex-col">
            <label for="social-reason">
              {{ "structure.social-reason" | translate }}
            </label>
            <div class="gap-4 grid grid-cols-2">
              <arsb-input
                [value]="ficheStructure?.national?.raisonSociale"
                (updateValue)="formStructure.controls['raisonSociale'].setValue($event)"
                (setNullValue)="formStructure.controls['raisonSociale'].setValue('')" />
              <input
                pInputText
                id="social-reason"
                formControlName="raisonSociale"
                (keyup.backspace)="setValueToNull(formStructure.controls['raisonSociale'])"
                [placeholder]="
                  getLabelPlaceholder(formStructure.controls['raisonSociale'], ficheStructure?.national?.raisonSociale)
                    | translate
                " />
            </div>
          </div>
          <div class="flex flex-col">
            <label for="enseigne-commerciale">
              {{ "structure.brand" | translate }}
            </label>
            <div class="gap-4 grid grid-cols-2">
              <arsb-input
                [value]="ficheStructure?.national?.enseigneCommerciale"
                (updateValue)="formStructure.controls['enseigneCommerciale'].setValue($event)"
                (setNullValue)="formStructure.controls['enseigneCommerciale'].setValue('')" />
              <input
                pInputText
                id="enseigne-commerciale"
                formControlName="enseigneCommerciale"
                (keyup.backspace)="setValueToNull(formStructure.controls['enseigneCommerciale'])"
                [placeholder]="
                  getLabelPlaceholder(
                    formStructure.controls['enseigneCommerciale'],
                    ficheStructure?.national?.enseigneCommerciale
                  ) | translate
                " />
            </div>
          </div>
          <div class="flex flex-col">
            <label for="label-usage">
              {{ "structure.label-usage" | translate }}
            </label>
            <input
              pInputText
              id="label-usage"
              formControlName="nomUsage" />
          </div>
          <div class="mt-4 flex flex-col">
            <label for="sector-act">
              {{ "structure.sector-activity" | translate }}
            </label>
            <div class="gap-4 grid grid-cols-2">
              <arsb-input
                [value]="ficheStructure?.national?.secteurActiviteLibelle"
                [hasUpdateButton]="Boolean(ficheStructure?.national?.secteurActiviteCode)"
                [hasSetNullButton]="false"
                (updateValue)="
                  formStructure.controls['secteurActiviteCode'].setValue(ficheStructure?.national?.secteurActiviteCode)
                " />
              <p-dropdown
                #sectorAct
                inputId="sector-act"
                styleClass="w-full"
                [options]="nosValues['secteurActiviteCode']"
                optionLabel="libelle"
                optionValue="code"
                [formControl]="getControl('secteurActiviteCode')"
                filter="true"
                [filterFields]="['code', 'libelle']"
                (onShow)="sectorAct.filterValue = null" />
            </div>
          </div>
          <div class="flex flex-col">
            <label for="aggregate-category">
              {{ "structure.aggregate-category" | translate }}
            </label>
            <div class="gap-4 grid grid-cols-2">
              <arsb-input
                [value]="ficheStructure?.national?.categorieAgrEtablissementLibelle"
                [hasUpdateButton]="Boolean(ficheStructure?.national?.categorieAgrEtablissementCode)"
                [hasSetNullButton]="false"
                (updateValue)="
                  formStructure.controls['categorieAgrEtablissementCode'].setValue(
                    ficheStructure?.national?.categorieAgrEtablissementCode
                  )
                " />
              <p-dropdown
                #aggregateCategory
                inputId="aggregate-category"
                styleClass="w-full"
                [options]="nosValues['categorieAgrEtablissementCode']"
                optionLabel="libelle"
                optionValue="code"
                [formControl]="getControl('categorieAgrEtablissementCode')"
                filter="true"
                [filterFields]="['code', 'libelle']"
                (onShow)="aggregateCategory.filterValue = null" />
            </div>
          </div>
          <div class="flex flex-col">
            <label for="category">
              {{ "structure.category" | translate }}
            </label>
            <div class="gap-4 grid grid-cols-2">
              <arsb-input
                [value]="ficheStructure?.national?.categorieEtablissementLibelle"
                [hasUpdateButton]="Boolean(ficheStructure?.national?.categorieEtablissementCode)"
                [hasSetNullButton]="false"
                (updateValue)="
                  formStructure.controls['categorieEtablissementCode'].setValue(
                    ficheStructure?.national?.categorieEtablissementCode
                  )
                " />
              <p-dropdown
                #category
                inputId="category"
                styleClass="w-full"
                [options]="nosValues['categorieEtablissementCode']"
                optionLabel="libelle"
                optionValue="code"
                [formControl]="getControl('categorieEtablissementCode')"
                filter="true"
                [filterFields]="['code', 'libelle']"
                (onShow)="category.filterValue = null" />
            </div>
          </div>
        </div>
      </div>
    </arsb-form-component>
    <!-- Structure -->

    <!-- Coordinates -->
    <arsb-form-component
      class="mt-8 mb-4"
      color="bg-cyan-500">
      <div
        arsbContent
        class="flex-grow">
        <arsb-coordinates
          [national]="ficheStructure?.national?.structureCoord"
          [regional]="ficheStructure?.regional?.structureCoord"
          formGroupName="structureCoord"></arsb-coordinates>
      </div>
    </arsb-form-component>
    <!-- Coordinates -->

    <div
      *ngIf="id !== 'new'"
      class="border-b border-slate-300 my-4"></div>

    <!-- MSS -->
    <arsb-form-component
      *ngIf="id !== 'new'"
      class="mt-8 mb-4"
      color="bg-green-500">
      <div
        arsbContent
        class="flex-grow">
        <div class="mb-4 flex items-center justify-between">
          <h2 class="flex items-center gap-4">
            {{ "mss.title" | translate }}
            <div
              *ngIf="ficheStructure?.msses?.length"
              class="circle bg-green-500 text-base"
              [pTooltip]="'mss.length' | translate: { length: ficheStructure?.msses?.length }">
              {{ ficheStructure?.msses?.length }}
            </div>
          </h2>
          <div class="flex gap-4">
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              severity="success"
              [pTooltip]="'mss.add-mss' | translate"
              (click)="openMssDialog()"></button>
            <button
              pButton
              type="button"
              [ngClass]="{ 'p-button-danger': showDisabledMsses, 'p-button-info': !showDisabledMsses }"
              [icon]="showDisabledMsses ? 'pi pi-eye' : 'pi pi-eye-slash'"
              [pTooltip]="'toggle.invisible' | translate: { label: 'mss.mss' | translate | lowercase }"
              (click)="showDisabledMsses = !showDisabledMsses"></button>
            <button
              *ngIf="(ficheStructure?.msses?.length ?? 0) > 3"
              pButton
              type="button"
              severity="info"
              [icon]="showAllMsses ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              [pTooltip]="'toggle.see-more' | translate"
              (click)="showAllMsses = !showAllMsses"></button>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <arsb-card
            *ngFor="let mss of msses"
            [iconLabel]="mss.niveauUsage"
            [iconTooltip]="'mss.niveau-small.' + mss.niveauUsage | translate"
            [label]="mss.mssAddress"
            [date]="mss.statutDate"
            [statut]="mss.statut"
            [canDeactivate]="true"
            [canActivate]="true"
            [canUpdate]="true"
            (deactivate)="deactivateMss(mss)"
            (activate)="activateMss(mss)"
            (update)="openMssDialog(mss)"></arsb-card>
        </div>
      </div>
    </arsb-form-component>
    <!-- MSS -->

    <!-- Activities -->
    <arsb-form-component
      *ngIf="id !== 'new'"
      class="mt-8 mb-4"
      color="bg-yellow-500">
      <div
        arsbContent
        class="flex-grow">
        <div class="mb-4 flex items-center justify-between">
          <h2 class="flex items-center gap-4">
            {{ "structure.activities" | translate }}
            <div
              *ngIf="ficheStructure?.activities?.length"
              class="circle bg-yellow-500 text-base"
              [pTooltip]="'activity.length' | translate: { length: ficheStructure?.activities?.length }">
              {{ ficheStructure?.activities?.length }}
            </div>
          </h2>
          <div class="flex gap-4">
            <button
              pButton
              type="button"
              [ngClass]="{ 'p-button-danger': showDisabledActivities, 'p-button-info': !showDisabledActivities }"
              [icon]="showDisabledActivities ? 'pi pi-eye' : 'pi pi-eye-slash'"
              [pTooltip]="'toggle.invisible' | translate: { label: 'activity.activities' | translate | lowercase }"
              (click)="showDisabledActivities = !showDisabledActivities"></button>
            <button
              *ngIf="(ficheStructure?.activities?.length ?? 0) > 3"
              pButton
              type="button"
              severity="info"
              [icon]="showAllActivities ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              [pTooltip]="'toggle.see-more' | translate"
              (click)="showAllActivities = !showAllActivities"></button>
            <button
              pButton
              type="button"
              severity="info"
              icon="pi pi-sort-alt"
              (click)="sortActivityPanel.toggle($event)"></button>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <arsb-card
            *ngFor="let activity of activities"
            icon="pi pi-briefcase"
            [link]="['/', 'person', activity.personId]"
            [linkParams]="{ exerciseProId: activity.exerciseProId }"
            [label]="activity.nom + ' ' + activity.prenom"
            [label2]="activity.libProf | titlecase"
            [onTwoLines]="true"
            [date]="activity.statutDate"
            [statut]="activity.statut"></arsb-card>
        </div>
      </div>
    </arsb-form-component>
    <!-- Activities -->

    <!-- Services -->
    <arsb-form-component
      *ngIf="id !== 'new'"
      class="mt-8 mb-4"
      color="bg-orange-500">
      <div
        arsbContent
        class="flex-grow">
        <h2>{{ "structure.services" | translate }}</h2>
        <div class="flex flex-col gap-2">
          <div
            *ngFor="let i of [1, 2]"
            class="flex gap-4">
            <input
              pInputText
              [value]="'service' + i" />
            <input
              pInputText
              [value]="'la valeur' + i"
              class="w-full" />
          </div>
        </div>
      </div>
    </arsb-form-component>
    <!-- Services -->

    <div
      *ngIf="id !== 'new'"
      class="border-b border-slate-300 my-4"></div>

    <!-- Parents -->
    <arsb-form-component
      *ngIf="id !== 'new'"
      class="mt-8 mb-4"
      color="bg-red-500">
      <div
        arsbContent
        class="flex-grow">
        <div class="mb-4 flex justify-between items-center">
          <h2>{{ "structure.parents" | translate }}</h2>
          <div class="flex gap-2">
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              severity="success"
              [pTooltip]="'structure.link-relation' | translate"
              (click)="openLinkStructureModal()"></button>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <arsb-card
            *ngFor="let parent of ficheStructure?.parents"
            icon="pi pi-building"
            [label]="parent.parentStructure.raisonSociale"
            [label2]="'structure.relation.' + parent.id.relationType | translate"
            [link]="['/', 'structure', parent.id.parentStructureId]"
            [date]="parent.statutDate"
            [statut]="parent.statut"
            [canDeactivate]="true"
            (deactivate)="askDeactivateLinkStructure(parent)"></arsb-card>
        </div>
      </div>
    </arsb-form-component>
    <!-- Parents -->

    <!-- Area activity -->
    <arsb-form-component
      *ngIf="id !== 'new'"
      class="mt-8 mb-4"
      color="bg-indigo-500">
      <div
        arsbContent
        class="flex-grow">
        <h2>{{ "structure.area-activity" | translate }}</h2>
        <div class="flex flex-col gap-2">
          <div
            *ngFor="let i of [1, 2]"
            class="flex gap-4">
            <input
              pInputText
              [value]="'service' + i" />
            <input
              pInputText
              [value]="'la valeur' + i"
              class="w-full" />
          </div>
        </div>
      </div>
    </arsb-form-component>
    <!-- Area activity -->

    <div class="mt-4 flex justify-end">
      <button
        pButton
        type="button"
        [label]="'common.save' | translate"
        [disabled]="formStructure.invalid"
        class="w-36"
        (click)="submit()"></button>
    </div>
  </form>

  <div
    *ngIf="ficheStructure?.extraElementGroups?.length"
    class="my-4">
    <arsb-complements
      [id]="id"
      [extraElementsGroups]="ficheStructure!.extraElementGroups"
      origin="structure" />
  </div>

  <div class="max-[1800px]:hidden min-[1800px]:fixed bottom-12 left-[1700px] right-8 flex justify-center gap-4">
    <button
      *ngIf="id !== 'new'"
      pButton
      type="button"
      icon="pi pi-undo"
      severity="info"
      outlined="true"
      size="large"
      [pTooltip]="'common.undo' | translate"
      tooltipPosition="left"
      (click)="resetForm()"></button>
    <button
      pButton
      type="button"
      icon="pi pi-save"
      size="large"
      [pTooltip]="'common.save' | translate"
      tooltipPosition="left"
      [disabled]="formStructure.invalid"
      (click)="submit()"></button>
  </div>
</div>
<p-overlayPanel #sortActivityPanel>
  <div class="flex flex-col gap-4">
    <button
      pButton
      type="button"
      [label]="'sort.nom' | translate"
      (click)="sortFieldActivities = 'nom'; sortActivityPanel.hide()"></button>
    <button
      pButton
      type="button"
      [label]="'sort.profession' | translate"
      (click)="sortFieldActivities = 'libProf'; sortActivityPanel.hide()"></button>
  </div>
</p-overlayPanel>
