import { Component, inject, OnInit } from '@angular/core'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { SelectButtonModule } from 'primeng/selectbutton'
import { FormsModule } from '@angular/forms'
import { InputTextModule } from 'primeng/inputtext'
import { ButtonDirective } from 'primeng/button'
import { SearchSimpleComponent } from '@module/home/components/search-simple/search-simple.component'
import { SearchAdvancedComponent } from '@module/home/components/search-advanced/search-advanced.component'
import { ViewMapComponent } from '@module/home/components/view-map/view-map.component'
import { ViewTableComponent } from '@module/home/components/view-table/view-table.component'
import { TooltipModule } from 'primeng/tooltip'
import { SearchService } from '@services/search.service'
import {
  AdvancedQueryParams,
  SearchedItem,
  SearchedPerson,
  SearchedStructure,
  SimpleQueryParams,
} from '@module/home/models/search'
import { ActivatedRoute, Router } from '@angular/router'
import { first } from 'rxjs'
import { StructureService } from '@services/structure.service'
import { PersonService } from '@services/person.service'
import { Page } from '@models/page'

@Component({
  selector: 'arsb-home',
  standalone: true,
  imports: [
    TranslateModule,
    SelectButtonModule,
    FormsModule,
    InputTextModule,
    ButtonDirective,
    SearchSimpleComponent,
    SearchAdvancedComponent,
    ViewMapComponent,
    ViewTableComponent,
    TooltipModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  private readonly translate = inject(TranslateService)
  private readonly router = inject(Router)
  private readonly route = inject(ActivatedRoute)

  private readonly structureService = inject(StructureService)
  private readonly personService = inject(PersonService)
  private readonly searchService = inject(SearchService)

  searchTypeOptions: { label: string; value: string }[] = []
  searchType: 'simple' | 'advanced' = 'simple'
  viewTypeOptions: { label: string; value: string; icon: string }[] = [
    { label: 'home.view.map', icon: 'pi pi-map', value: 'map' },
    { label: 'home.view.table', icon: 'pi pi-table', value: 'table' },
  ]
  viewType: 'map' | 'table' = 'map'
  simpleQueryParams?: SimpleQueryParams
  advancedQueryParams?: AdvancedQueryParams

  searchText?: string
  location?: { latitude: number; longitude: number; range: number }

  items: (SearchedItem | SearchedStructure | SearchedPerson)[] = []
  page: number = 0
  totalRecords: number = 0
  tableType: 'simple' | 'structure' | 'person' = 'simple'
  sortFields: string[] = []

  ngOnInit() {
    this.translate.get(['home.search.simple', 'home.search.advanced']).subscribe((res) => {
      this.searchTypeOptions = [
        { label: res['home.search.simple'], value: 'simple' },
        { label: res['home.search.advanced'], value: 'advanced' },
      ]
    })

    this.route.queryParams.pipe(first()).subscribe((params) => {
      if (params['searchType']) {
        this.searchType = params['searchType']
      }
      if (params['viewType']) {
        this.viewType = params['viewType']
      }
      this.simpleQueryParams = {
        query: params['query'],
        address: params['address'],
        latitude: params['latitude'],
        longitude: params['longitude'],
        range: params['range'],
      }
      this.advancedQueryParams = {
        type: params['type'],
        address: params['address'],
        latitude: params['latitude'],
        longitude: params['longitude'],
        range: params['range'],
        finessStartWith: params['finessStartWith'],
        siretStartWith: params['siretStartWith'],
        rnaStartWith: params['rnaStartWith'],
        adresseMatch: params['adresseMatch'],
        codePostalStartWith: params['codePostalStartWith'],
        libCommuneContain: params['libCommuneContain'],
      }
      this.location = {
        latitude: params['latitude'],
        longitude: params['longitude'],
        range: (params['range'] ?? 0) * 1000,
      }

      if (this.searchType === 'simple' && this.simpleQueryParams.latitude && this.simpleQueryParams.longitude) {
        this.simpleResearch()
      }

      if (this.searchType === 'advanced' && this.advancedQueryParams.type) {
        this.advancedResearch()
      }
    })
  }

  changeUrl(params: { key: string; value: any }[]) {
    this.route.queryParams.subscribe((activatedParams) => {
      const newParams = { ...activatedParams }
      params.forEach((param) => {
        if (param.value) {
          newParams[param.key] = param.value
        } else {
          delete newParams[param.key]
        }
      })

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: newParams,
      })
    })
  }

  simpleResearch(event?: {
    searchText: string
    address: string
    latitude: number
    longitude: number
    distanceMeters: number
  }) {
    if (event) {
      this.location = { latitude: event.latitude, longitude: event.longitude, range: event.distanceMeters }
      this.searchText = event.searchText
      this.changeUrl([
        { key: 'query', value: event.searchText },
        { key: 'address', value: event.address },
        { key: 'latitude', value: event.latitude },
        { key: 'longitude', value: event.longitude },
        { key: 'range', value: event.distanceMeters / 1000 },
      ])
      this.simpleQueryParams = {
        query: event.searchText,
        address: event.address,
        latitude: event.latitude,
        longitude: event.longitude,
        range: event.distanceMeters / 1000,
      }
    }
    this.searchService
      .search(
        this.simpleQueryParams?.query,
        this.simpleQueryParams?.latitude,
        this.simpleQueryParams?.longitude,
        (this.simpleQueryParams?.range ?? 0) * 1000,
        this.page,
        this.sorts,
      )
      .subscribe((res) => {
        this.setItems(res, event)
      })
  }

  advancedResearch(event?: AdvancedQueryParams) {
    if (event) {
      this.advancedQueryParams = event
      this.location = { latitude: event.latitude, longitude: event.longitude, range: event.distanceMeters! }
      this.changeUrl([
        { key: 'type', value: event.type },
        { key: 'address', value: event.address },
        { key: 'latitude', value: event.latitude },
        { key: 'longitude', value: event.longitude },
        { key: 'range', value: event.range },
        { key: 'finessStartWith', value: event.finessStartWith },
        { key: 'siretStartWith', value: event.siretStartWith },
        { key: 'rnaStartWith', value: event.rnaStartWith },
        { key: 'adresseMatch', value: event.adresseMatch },
        { key: 'codePostalStartWith', value: event.codePostalStartWith },
        { key: 'libCommuneContain', value: event.libCommuneContain },
      ])
    }
    switch (this.advancedQueryParams!.type) {
      case 'structure':
        this.structureService.search(this.page, this.advancedQueryParams!, this.sorts).subscribe((res) => {
          this.setItems(res, event)
        })
        break
      case 'person':
        break
    }
  }

  searchNextPage(page?: number) {
    this.page += 1
    if (page || page === 0) {
      this.page = page
    }
    switch (this.searchType) {
      case 'simple':
        this.simpleResearch()
        break
      case 'advanced':
        this.advancedResearch()
        break
    }
  }

  updateTableType() {
    if (this.searchType === 'simple') {
      this.tableType = 'simple'
    } else {
      this.tableType = this.advancedQueryParams?.type ?? 'simple'
    }
  }

  reloadData() {
    this.page = 0
    switch (this.searchType) {
      case 'simple':
        this.simpleResearch({
          searchText: this.simpleQueryParams?.query ?? '',
          address: this.simpleQueryParams?.address ?? '',
          latitude: this.simpleQueryParams?.latitude ?? 0,
          longitude: this.simpleQueryParams?.longitude ?? 0,
          distanceMeters: (this.simpleQueryParams?.range ?? 0) * 1000,
        })
        break
      case 'advanced':
        this.advancedResearch(this.advancedQueryParams!)
        break
    }
  }

  private get sorts() {
    if (this.sortFields.length > 0) {
      return this.sortFields
    }

    if (this.viewType === 'map') {
      // return ['distance,asc', this.mainColumn + ',asc']
      return [this.mainColumn + ',asc']
    }
    return [this.mainColumn + ',asc']
  }

  private get mainColumn() {
    if (this.searchType === 'simple') {
      return 'label'
    }
    switch (this.advancedQueryParams?.type) {
      case 'structure':
        return 'raisonSociale'
      case 'person':
        return 'label'
    }
    return 'label'
  }

  private setItems(res: Page<any>, event?: any) {
    this.updateTableType()
    if (this.viewType === 'map' && this.items.length > 0 && !event) {
      this.items = this.items.concat(res.content)
    } else {
      this.items = res.content
    }
    this.totalRecords = res.totalElements
  }
}
