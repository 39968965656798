import { Component, inject, Input, OnInit } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { InputTextModule } from 'primeng/inputtext'
import { PaginatorModule } from 'primeng/paginator'
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms'
import { InputComponent } from '@shared/components/input/input.component'
import { Nos } from '@models/nos'
import { CenterCoordFusionComponent } from '@module/fusion/components/center-coord-fusion/center-coord-fusion.component'

@Component({
  selector: 'arsb-center-structure-fusion',
  standalone: true,
  imports: [
    TranslateModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    InputComponent,
    CenterCoordFusionComponent,
  ],
  templateUrl: './center-structure-fusion.component.html',
  styleUrl: './center-structure-fusion.component.scss',
})
export class CenterStructureFusionComponent implements OnInit {
  protected readonly formGroupDirective = inject(FormGroupDirective)

  @Input() nosValues!: { [key: string]: Nos[] }

  form!: FormGroup

  ngOnInit() {
    this.form = this.formGroupDirective.form as FormGroup
  }
}
