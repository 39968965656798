import { GcsObject } from '@models/gcs-object'

export interface ExtraElementGroup extends GcsObject {
  label: string
  extraElements: ExtraElement[]
}

export interface ExtraElement {
  element: Element
  elementData: any
}

export interface Element extends GcsObject {
  label: string
  type: ElementTypes
  enumValues?: EnumValue[]
}

export interface EnumValue extends GcsObject {
  label: string
}

export enum ElementTypes {
  BOOLEAN = 'BOOLEAN',
  TEXT = 'TEXT',
  ENUM = 'ENUM',
  FILE = 'FILE',
  GEO_ZONE = 'GEO_ZONE',
}
