import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Address } from '@models/address'
import { environment } from '@env/environment'
import { FormGroup } from '@angular/forms'
import { Map, Marker, marker } from 'leaflet'

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private readonly http = inject(HttpClient)

  constructor() {}

  getAddresses(address: string, codePostal?: string) {
    let params = new HttpParams()
    if (address) {
      params = params.set('address', address)
    }
    if (codePostal) {
      params = params.set('codePostal', codePostal)
    }
    return this.http.get<Address[]>(`${environment.apiUrl}/address`, { params })
  }

  setMapAddress(address: Address, map: Map | undefined, layers: Marker[], form?: FormGroup) {
    layers.forEach((layer) => layer.remove())

    const [longitude, latitude] = address.geometry.coordinates
    map?.setView([latitude, longitude], 13)
    layers.push(marker([latitude, longitude]))
    form?.patchValue({ latitude, longitude })
  }
}
