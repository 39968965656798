<form
  *ngIf="form"
  [formGroup]="form">
  <h2>{{ "coordinates.title" | translate }}</h2>

  <arsb-card-information
    [id]="national?.id!"
    [nationalStatut]="national?.statut"
    [nationalStatutDate]="national?.statutDate"
    [regionalStatut]="regional?.statut"
    [regionalStatutDate]="regional?.statutDate">
  </arsb-card-information>

  <div class="flex flex-col gap-4">
    <div class="flex flex-col">
      <label for="compl-dest">
        {{ "coordinates.compl-dest" | translate }}
      </label>
      <div class="grid grid-cols-2 gap-4">
        <arsb-input
          [value]="national?.complDest"
          (updateValue)="form.controls['complDest'].setValue($event)"
          (setNullValue)="form.controls['complDest'].setValue('')" />
        <input
          id="compl-dest"
          pInputText
          formControlName="complDest"
          (keyup.backspace)="setValueToNull(form.controls['complDest'])"
          [placeholder]="getLabelPlaceholder(form.controls['complDest'], national?.complDest) | translate" />
      </div>
      <span
        *ngIf="hasMaxLengthError(form.controls['complDest'])"
        class="text-red-400">
        {{ "error.max-length" | translate: { label: "coordinates.compl-dest" | translate, length: 38 } }}
      </span>
    </div>
    <div class="flex flex-col">
      <label for="compl-geo">
        {{ "coordinates.compl-geo" | translate }}
      </label>
      <div class="grid grid-cols-2 gap-4">
        <arsb-input
          [value]="national?.complGeo"
          (updateValue)="form.controls['complGeo'].setValue($event)"
          (setNullValue)="form.controls['complGeo'].setValue('')" />
        <input
          id="compl-geo"
          pInputText
          formControlName="complGeo"
          (keyup.backspace)="setValueToNull(form.controls['complGeo'])"
          [placeholder]="getLabelPlaceholder(form.controls['complGeo'], national?.complGeo) | translate" />
      </div>
      <span
        *ngIf="hasMaxLengthError(form.controls['complGeo'])"
        class="text-red-400">
        {{ "error.max-length" | translate: { label: "coordinates.compl-geo" | translate, length: 38 } }}
      </span>
    </div>
  </div>
  <div class="mt-8 grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4">
    <div class="flex flex-col mb-1">
      <label for="lane-number">
        {{ "coordinates.lane-number" | translate }}
      </label>
      <div class="flex gap-4">
        <div class="flex gap-x-4">
          <arsb-input
            [value]="national?.numVoie"
            (updateValue)="form.controls['numVoie'].setValue($event)"
            (setNullValue)="form.controls['numVoie'].setValue('')"
            inputClass="w-32" />
          <arsb-input
            [value]="national?.indiceRepVoie"
            (updateValue)="form.controls['indiceRepVoie'].setValue($event)"
            (setNullValue)="form.controls['indiceRepVoie'].setValue('')"
            inputClass="w-32" />
        </div>
        <div class="flex gap-x-4">
          <input
            id="lane-number"
            pInputText
            formControlName="numVoie"
            (keyup.backspace)="setValueToNull(form.controls['numVoie'])"
            [placeholder]="getLabelPlaceholder(form.controls['numVoie'], national?.numVoie) | translate"
            class="w-32" />
          <input
            pInputText
            formControlName="indiceRepVoie"
            (keyup.backspace)="setValueToNull(form.controls['indiceRepVoie'])"
            [placeholder]="getLabelPlaceholder(form.controls['indiceRepVoie'], national?.indiceRepVoie) | translate"
            class="w-32" />
        </div>
      </div>
    </div>
    <div></div>
    <div class="flex flex-col gap-4">
      <div class="flex flex-col">
        <label for="type-voie">
          {{ "coordinates.lane-type" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.libTypeVoie"
            (updateValue)="form.controls['libTypeVoie'].setValue($event)"
            (setNullValue)="form.controls['libTypeVoie'].setValue('')" />
          <input
            id="type-voie"
            pInputText
            formControlName="libTypeVoie"
            (keyup.backspace)="setValueToNull(form.controls['libTypeVoie'])"
            [placeholder]="getLabelPlaceholder(form.controls['libTypeVoie'], national?.libTypeVoie) | translate" />
        </div>
      </div>
      <div class="flex flex-col">
        <label for="lane-label">
          {{ "coordinates.lane-label" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.libVoie"
            (updateValue)="form.controls['libVoie'].setValue($event)"
            (setNullValue)="form.controls['libVoie'].setValue('')" />
          <input
            id="lane-label"
            pInputText
            formControlName="libVoie"
            (keyup.backspace)="setValueToNull(form.controls['libVoie'])"
            [placeholder]="getLabelPlaceholder(form.controls['libVoie'], national?.libVoie) | translate" />
        </div>
      </div>
      <div class="flex flex-col">
        <label>{{ "coordinates.address" | translate }}</label>
        <input
          pInputText
          [value]="handleAddress(this.form!, this.national)"
          disabled />
      </div>
      <div class="flex flex-col">
        <label for="mention-distrib">
          {{ "coordinates.mention-distrib" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.mentionDistVoie"
            (updateValue)="form.controls['mentionDistVoie'].setValue($event)"
            (setNullValue)="form.controls['mentionDistVoie'].setValue('')" />
          <input
            id="mention-distrib"
            pInputText
            formControlName="mentionDistVoie"
            (keyup.backspace)="setValueToNull(form.controls['mentionDistVoie'])"
            [placeholder]="
              getLabelPlaceholder(form.controls['mentionDistVoie'], national?.mentionDistVoie) | translate
            " />
        </div>
      </div>
    </div>
    <div
      *ngIf="form"
      class="flex flex-col gap-4">
      <div class="flex flex-col">
        <label for="cedex">
          {{ "coordinates.office" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.bureauCedex"
            (updateValue)="form.controls['bureauCedex'].setValue($event)"
            (setNullValue)="form.controls['bureauCedex'].setValue('')" />
          <input
            id="cedex"
            pInputText
            formControlName="bureauCedex"
            (keyup.backspace)="setValueToNull(form.controls['bureauCedex'])"
            [placeholder]="getLabelPlaceholder(form.controls['bureauCedex'], national?.bureauCedex) | translate" />
        </div>
      </div>
      <div class="flex flex-col">
        <label for="city">
          {{ "coordinates.city" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.libCommune"
            (updateValue)="form.controls['libCommune'].setValue($event)"
            (setNullValue)="form.controls['libCommune'].setValue('')" />
          <p-autoComplete
            inputId="libCommune"
            formControlName="libCommune"
            class="w-full"
            styleClass="w-full"
            inputStyleClass="w-full"
            [suggestions]="addresses"
            (completeMethod)="searchAddress()"
            (onSelect)="selectAddress($event)"
            (keyup.backspace)="setValueToNull(form.controls['libCommune'])"
            [placeholder]="getLabelPlaceholder(form.controls['libCommune'], national?.libCommune) | translate"
            field="properties.label">
            <ng-template
              let-item
              pTemplate="item">
              <div class="flex items-center gap-4">
                <i class="pi pi-map-marker"></i>
                <span>{{ item.properties.label }}</span>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="flex flex-col">
        <label for="code-postal">
          {{ "coordinates.postal-code" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.codePostal"
            [isPostalCode]="true"
            (updateValue)="form.controls['codePostal'].setValue($event)"
            (setNullValue)="form.controls['codePostal'].setValue('')" />
          <input
            id="code-postal"
            pInputText
            formControlName="codePostal"
            [attr.disabled]="isDisabled(form.controls['codePostal'])"
            (keyup.backspace)="setValueToNull(form.controls['codePostal'])"
            [placeholder]="getLabelPlaceholder(form.controls['codePostal'], national?.codePostal) | translate"
            (input)="onCodePostalChange()" />
        </div>
        <span
          *ngIf="hasPatternError(form.controls['codePostal'])"
          class="text-red-400">
          {{ "error.pattern" | translate: { label: "coordinates.postal-code" | translate } }}
        </span>
      </div>
      <div class="flex flex-col">
        <label for="city-code">
          {{ "coordinates.city-code" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.codeCommune"
            [isPostalCode]="true"
            (updateValue)="form.controls['codeCommune'].setValue($event)"
            (setNullValue)="form.controls['codeCommune'].setValue('')" />
          <input
            id="city-code"
            pInputText
            formControlName="codeCommune"
            [attr.disabled]="isDisabled(form.controls['codeCommune'])"
            (keyup.backspace)="setValueToNull(form.controls['codeCommune'])"
            [placeholder]="getLabelPlaceholder(form.controls['codeCommune'], national?.codeCommune) | translate" />
        </div>
        <span
          *ngIf="hasPatternError(form.controls['codeCommune'])"
          class="text-red-400">
          {{ "error.pattern" | translate: { label: "coordinates.city-code" | translate } }}
        </span>
      </div>
    </div>
    <div
      *ngIf="form"
      class="mt-6 flex flex-col gap-4 justify-center">
      <div class="flex flex-col">
        <label for="phone-home">
          {{ "coordinates.phone-home" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.tel"
            [isPhone]="true"
            (updateValue)="form.controls['tel'].setValue($event)"
            (setNullValue)="form.controls['tel'].setValue('')" />
          <input
            id="phone-home"
            pInputText
            formControlName="tel"
            [attr.disabled]="isDisabled(form.controls['tel'])"
            (keyup.backspace)="setValueToNull(form.controls['tel'])"
            [placeholder]="getLabelPlaceholder(form.controls['tel'], national?.tel) | translate" />
        </div>
        <span
          *ngIf="hasMinLengthError(form.controls['tel'])"
          class="text-red-400">
          {{ "error.min-length" | translate: { length: 10 } }}
        </span>
        <span
          *ngIf="hasPatternError(form.controls['tel'])"
          class="text-red-400">
          {{ "error.pattern" | translate: { label: "coordinates.phone-home" | translate } }}
        </span>
      </div>
      <div class="flex flex-col">
        <label for="phone-cell">
          {{ "coordinates.phone-cell" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.portable"
            [isPhone]="true"
            (updateValue)="form.controls['portable'].setValue($event)"
            (setNullValue)="form.controls['portable'].setValue('')" />
          <input
            id="phone-cell"
            pInputText
            formControlName="portable"
            [attr.disabled]="isDisabled(form.controls['portable'])"
            (keyup.backspace)="setValueToNull(form.controls['portable'])"
            [placeholder]="getLabelPlaceholder(form.controls['portable'], national?.portable) | translate" />
        </div>
        <span
          *ngIf="hasMinLengthError(form.controls['portable'])"
          class="text-red-400">
          {{ "error.min-length" | translate: { length: 10 } }}
        </span>
        <span
          *ngIf="hasPatternError(form.controls['portable'])"
          class="text-red-400">
          {{ "error.pattern" | translate: { label: "coordinates.phone-cell" | translate } }}
        </span>
      </div>
      <div class="flex flex-col">
        <label for="phone-other">
          {{ "coordinates.phone-other" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.telOther"
            [isPhone]="true"
            (updateValue)="form.controls['telOther'].setValue($event)"
            (setNullValue)="form.controls['telOther'].setValue('')" />
          <input
            id="phone-other"
            pInputText
            formControlName="telOther"
            [attr.disabled]="isDisabled(form.controls['telOther'])"
            (keyup.backspace)="setValueToNull(form.controls['telOther'])"
            [placeholder]="getLabelPlaceholder(form.controls['telOther'], national?.telOther) | translate" />
        </div>
        <span
          *ngIf="hasMinLengthError(form.controls['telOther'])"
          class="text-red-400">
          {{ "error.min-length" | translate: { length: 10 } }}
        </span>
        <span
          *ngIf="hasPatternError(form.controls['telOther'])"
          class="text-red-400">
          {{ "error.pattern" | translate: { label: "coordinates.phone-other" | translate } }}
        </span>
      </div>
      <div class="flex flex-col">
        <label for="fax">
          {{ "coordinates.fax" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.fax"
            [isPhone]="true"
            (updateValue)="form.controls['fax'].setValue($event)"
            (setNullValue)="form.controls['fax'].setValue('')" />
          <input
            id="fax"
            pInputText
            formControlName="fax"
            [attr.disabled]="isDisabled(form.controls['fax'])"
            (keyup.backspace)="setValueToNull(form.controls['fax'])"
            [placeholder]="getLabelPlaceholder(form.controls['fax'], national?.fax) | translate" />
        </div>
        <span
          *ngIf="hasMinLengthError(form.controls['fax'])"
          class="text-red-400">
          {{ "error.min-length" | translate: { length: 10 } }}
        </span>
        <span
          *ngIf="hasPatternError(form.controls['fax'])"
          class="text-red-400">
          {{ "error.pattern" | translate: { label: "coordinates.fax" | translate } }}
        </span>
      </div>
      <div class="flex flex-col">
        <label for="email">
          {{ "coordinates.email" | translate }}
        </label>
        <div class="gap-4 grid grid-cols-2">
          <arsb-input
            [value]="national?.email"
            (updateValue)="form.controls['email'].setValue($event)"
            (setNullValue)="form.controls['email'].setValue('')" />
          <input
            id="email"
            pInputText
            formControlName="email"
            (keyup.backspace)="setValueToNull(form.controls['email'])"
            [placeholder]="getLabelPlaceholder(form.controls['email'], national?.email) | translate" />
        </div>
        <span
          *ngIf="hasIsEmailError(form.controls['email'])"
          class="text-red-400">
          {{ "error.email" | translate }}
        </span>
      </div>
    </div>
    <div class="mt-6">
      <div
        leaflet
        [leafletOptions]="mapOptions"
        [leafletLayers]="layers"
        (leafletMapReady)="map = $event"
        class="h-[300px] rounded-2xl relative">
        <div class="absolute top-2 right-2 z-[1000]">
          <button
            pButton
            type="button"
            icon="pi pi-map"
            (click)="locate()"></button>
        </div>
      </div>
    </div>
  </div>
</form>
