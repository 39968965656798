<div class="container">
  <div class="flex items-center justify-center mx-auto gap-12">
    <h1 class="text-secondary text-2xl">{{ "home.research" | translate }} :</h1>
    <p-selectButton
      [options]="searchTypeOptions"
      [(ngModel)]="searchType"
      (onChange)="changeUrl([{ key: 'searchType', value: searchType }])"
      [unselectable]="true" />
  </div>
  <div class="my-8 py-12 px-6 bg-primary-200 rounded-2xl shadow">
    @switch (searchType) {
      @case ("simple") {
        <arsb-search-simple
          [queryParams]="simpleQueryParams"
          (search)="page = 0; simpleResearch($event)" />
      }
      @case ("advanced") {
        <arsb-search-advanced
          [queryParams]="advancedQueryParams"
          (search)="page = 0; advancedResearch($event)" />
      }
    }
  </div>
  <div class="my-8">
    <div class="mx-8 mb-8">
      <p-selectButton
        [options]="viewTypeOptions"
        [(ngModel)]="viewType"
        [unselectable]="true"
        (onChange)="reloadData(); changeUrl([{ key: 'viewType', value: viewType }])"
        optionLabel="icon"
        optionValue="value">
        <ng-template
          let-item
          pTemplate>
          <i
            [class]="'text-xl ' + item.icon"
            [pTooltip]="item.label | translate"></i>
        </ng-template>
      </p-selectButton>
    </div>
    @switch (viewType) {
      @case ("map") {
        <arsb-view-map
          [items]="items"
          [location]="location"
          (searchNextPage)="searchNextPage()" />
      }
      @case ("table") {
        <arsb-view-table
          [items]="items"
          [type]="tableType"
          [totalRecords]="totalRecords"
          (searchNextPage)="searchNextPage($event)"
          (sortFields)="sortFields = $event" />
      }
    }
  </div>
</div>
