import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { InputTextModule } from 'primeng/inputtext'
import { NgClass, NgIf } from '@angular/common'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { Clipboard } from '@angular/cdk/clipboard'
import { MessageService } from 'primeng/api'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'arsb-input',
  standalone: true,
  imports: [InputTextModule, NgClass, NgIf, IconFieldModule, InputIconModule, TooltipModule, TranslateModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent {
  private readonly clipboard = inject(Clipboard)
  private readonly messageService = inject(MessageService)
  private readonly translate = inject(TranslateService)

  @Input() value?: string = ''
  @Input() inputClass?: string = 'w-full'
  @Input() hasCopyButton: boolean = true
  @Input() hasUpdateButton: boolean = true
  @Input() hasSetNullButton: boolean = true
  @Input() isPhone: boolean = false
  @Input() isPostalCode: boolean = false

  @Output() updateValue = new EventEmitter<string>()
  @Output() setNullValue = new EventEmitter<void>()

  get inputValue() {
    if (this.isPhone && this.value) {
      return this.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
    }
    if (this.isPostalCode && this.value) {
      return this.value.replace(/(\d{2})(\d{3})/, '$1 $2')
    }
    return this.value
  }

  copyFieldValue() {
    this.clipboard.copy(this.value!)
    this.messageService.add({ severity: 'info', summary: this.translate.instant('common.copy') })
  }

  updateFieldValue() {
    this.updateValue.emit(this.inputValue)
    this.messageService.add({ severity: 'info', summary: this.translate.instant('common.updated') })
  }

  setNullFieldValue() {
    this.setNullValue.emit()
    this.messageService.add({ severity: 'info', summary: this.translate.instant('common.set-null') })
  }
}
