<div
  *ngFor="let extraElementGroup of extraElementsGroups"
  class="mt-4">
  <h3>{{ extraElementGroup.label }}</h3>
  <div class="mt-1 mb-4 grid grid-cols-2 lg:grid-cols-4 2xl:grid-cols-6 gap-2">
    <div
      *ngFor="let extraElement of extraElementGroup.extraElements"
      class="flex flex-col"
      [ngClass]="{
        'col-span-1': extraElement.element.type === ElementTypes.BOOLEAN,
        'col-span-2 2xl:col-span-3': extraElement.element.type === ElementTypes.ENUM,
        'col-span-2 lg:col-span-4 2xl:col-span-6':
          extraElement.element.type === ElementTypes.FILE || extraElement.element.type === ElementTypes.GEO_ZONE,
        'col-span-2': extraElement.element.type === ElementTypes.TEXT,
      }">
      @switch (extraElement.element.type) {
        @case (ElementTypes.BOOLEAN) {
          <label [for]="extraElement.element.id">{{ extraElement.element.label }}</label>
          <p-checkbox
            [inputId]="extraElement.element.id"
            [(ngModel)]="extraElement.elementData"
            (onChange)="postExtraElement(extraElement, extraElement.elementData)"
            [binary]="true" />
        }
        @case (ElementTypes.ENUM) {
          <label [for]="extraElement.element.id">{{ extraElement.element.label }}</label>
          <p-dropdown
            [inputId]="extraElement.element.id"
            [(ngModel)]="extraElement.elementData"
            [options]="extraElement.element.enumValues"
            optionValue="id"
            class="w-full"
            styleClass="w-full"
            (onChange)="postExtraElement(extraElement, extraElement.elementData)" />
        }
        @case (ElementTypes.FILE) {
          <div class="flex items-center gap-4">
            <button
              *ngIf="extraElement.elementData && !extraElement.elementData.url"
              pButton
              icon="pi pi-download"
              type="button"
              (click)="downloadFile(extraElement)"></button>
            <img
              *ngIf="extraElement.elementData && extraElement.elementData.url"
              [src]="extraElement.elementData.url"
              [alt]="extraElement.elementData.entityLabel"
              (click)="downloadFile(extraElement)"
              class="h-20 cursor-pointer" />
            <label
              [for]="extraElement.element.id"
              class="file">
              <span>{{ extraElement.element.label }}</span>
              <i class="pi pi-upload text-2xl text-primary font-bold"></i>
              <input
                type="file"
                [id]="extraElement.element.id"
                (change)="postFile(extraElement, $event)" />
            </label>
          </div>
        }
        @case (ElementTypes.TEXT) {
          <label [for]="extraElement.element.id">{{ extraElement.element.label }}</label>
          <textarea
            [id]="extraElement.element.id"
            pInputTextarea
            [(ngModel)]="extraElement.elementData"
            (blur)="postExtraElement(extraElement, extraElement.elementData)"
            class="w-full"></textarea>
        }
        @case (ElementTypes.GEO_ZONE) {
          <label [for]="extraElement.element.id">{{ extraElement.element.label }}</label>
          <p-chips
            [inputId]="extraElement.element.id"
            class="w-full"
            styleClass="w-full"
            inputStyleClass="w-full"
            [(ngModel)]="extraElement.elementData"
            (onBlur)="postExtraElement(extraElement, extraElement.elementData)" />
        }
      }
    </div>
  </div>
</div>
