<div class="flex flex-col lg:flex-row">
  <div
    [ngClass]="{
      'w-0': !items.length,
      'w-full lg:w-1/3 xl:w-1/4': items.length,
    }"
    class="transition-all overflow-clip">
    <ul
      #scrollContainer
      class="list-none lg:h-[600px] overflow-y-auto border border-solid rounded-l-lg"
      infiniteScroll
      [infiniteScrollContainer]="scrollContainer"
      (scrolled)="onScroll()">
      <li
        *ngFor="let item of modifiedItems; let i = index"
        class="w-full border-b border-solid">
        <a
          [routerLink]="['/', item.type, item.id]"
          class="px-4 py-2 flex gap-4">
          <div class="flex flex-col items-center">
            <span
              class="h-8 w-8 rounded-full flex items-center justify-center"
              [ngClass]="{
                'bg-primary': i + 1 !== selectedIndex,
                'bg-secondary text-white': i + 1 === selectedIndex,
              }">
              {{ i + 1 }}
            </span>
            <i
              class="text-2xl pi text-gray-600"
              [ngClass]="{ 'pi-building': item.type === 'structure', 'pi-user': item.type === 'person' }"></i>
          </div>
          <div class="flex-grow flex flex-col">
            <span class="font-bold">{{ item.label }}</span>
            <span
              *ngIf="item.adresse"
              class="text-sm text-gray-600">
              {{ item.adresse }}, {{ item.codePostal }} {{ item.libCommune }}
            </span>
          </div>
        </a>
      </li>
    </ul>
  </div>
  <div class="flex-grow">
    <div
      leaflet
      [leafletOptions]="mapOptions"
      [leafletLayers]="layers"
      (leafletMapReady)="onMapLoad($event)"
      class="h-[400px] lg:h-[600px] rounded-r-lg"
      [ngClass]="{ 'rounded-l-lg': !items.length }"></div>
  </div>
</div>
