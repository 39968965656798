import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '@env/environment'
import { Field } from '@models/field'
import { Activity, FicheActivity } from '@models/activity'

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private readonly http = inject(HttpClient)

  private readonly api = environment.apiUrl

  getActivity(id: string) {
    return this.http.get<FicheActivity>(`${this.api}/activity/${id}`)
  }

  getFields(subField?: string) {
    let params = new HttpParams()
    if (subField) params = params.set('subEntityPath', subField)
    return this.http.get<Field[]>(`${this.api}/activity/fields`, { params })
  }

  createActivity(activity: Activity) {
    return this.http.post<Activity>(`${this.api}/activity`, activity)
  }

  updateActivity(activityId: string, activity: Activity) {
    return this.http.put<Activity>(`${this.api}/activity/${activityId}`, activity)
  }

  deactivateActivity(activityId: string) {
    return this.http.delete(`${this.api}/activity/${activityId}`)
  }

  reactivateActivity(activityId: string) {
    return this.http.patch(`${this.api}/activity/${activityId}`, {})
  }
}
