<div class="flex flex-col md:flex-row justify-center gap-4">
  <a href="#">{{ "footer.group-esante" | translate }}</a>
  <span>|</span>
  <a href="#">{{ "footer.site-plan" | translate }}</a>
  <span>|</span>
  <a href="#">{{ "footer.legal-information" | translate }}</a>
  <span>|</span>
  <a href="#">{{ "footer.cookies" | translate }}</a>
  <span>|</span>
  <a href="#">{{ "footer.gcu" | translate }}</a>
</div>
<div class="text-center text-xs text-slate-600">v{{ version }}</div>
