import { CanActivateFn } from '@angular/router'
import { inject } from '@angular/core'
import { KeycloakService } from 'keycloak-angular'

export const authenticatedGuard: CanActivateFn = (route, state) => {
  const keycloak = inject(KeycloakService)

  return new Promise(async (resolve, reject) => {
    if (keycloak.isLoggedIn()) {
      return resolve(true)
    }
    await keycloak.login()
    return resolve(false)
  })
}
