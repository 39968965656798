<a
  *ngIf="link"
  [routerLink]="link"
  [queryParams]="linkParams">
  <ng-template [ngTemplateOutlet]="card"></ng-template>
</a>

<ng-container *ngIf="!link">
  <ng-template [ngTemplateOutlet]="card"></ng-template>
</ng-container>

<ng-template #card>
  <p-card [styleClass]="cardClass">
    <div class="flex gap-3 items-center">
      <div
        [pTooltip]="iconTooltip"
        tooltipPosition="top"
        class="h-12 w-12 rounded-full bg-primary-200 flex justify-center items-center">
        <i [ngClass]="iconClass"></i>
      </div>
      <div class="grow flex justify-between">
        <div class="flex flex-col">
          <div class="text-sm flex gap-2">
            <span>{{ label }}</span>
            <span *ngIf="label2 && !onTwoLines">&middot;</span>
            <span *ngIf="label2 && !onTwoLines">{{ label2 }}</span>
          </div>
          <span
            class="text-xs text-gray-500"
            *ngIf="label2 && onTwoLines">
            {{ label2 }}
          </span>
          <div class="flex gap-2 text-xs text-gray-500">
            <span>{{ "statut." + statut | translate }}</span>
            <span *ngIf="date && statut">&middot;</span>
            <span>{{ date | date: "dd/MM/yyyy" }}</span>
          </div>
        </div>
        <div class="flex gap-2">
          <button
            *ngIf="statut !== Statut.DEACTIVATED && canDeactivate"
            pButton
            icon="pi pi-times"
            severity="danger"
            [outlined]="true"
            size="small"
            type="button"
            [pTooltip]="'common.deactivate' | translate"
            (click)="clickDeactivate($event)"></button>
          <button
            *ngIf="statut === Statut.DEACTIVATED && canActivate"
            pButton
            icon="pi pi-check"
            severity="success"
            [outlined]="true"
            size="small"
            type="button"
            [pTooltip]="'common.activate' | translate"
            (click)="clickActivate($event)"></button>
          <button
            *ngIf="statut !== Statut.DEACTIVATED && canUpdate"
            pButton
            icon="pi pi-pencil"
            severity="info"
            [outlined]="true"
            size="small"
            type="button"
            [pTooltip]="'common.update' | translate"
            (click)="clickUpdate($event)"></button>
        </div>
      </div>
    </div>
  </p-card>
</ng-template>
