import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { Mss } from '@models/mss'

@Injectable({
  providedIn: 'root',
})
export class MssService {
  private readonly http = inject(HttpClient)

  private readonly api = `${environment.apiUrl}/mss`

  addMss(type: 'structure' | 'person', id: string, mss: { mssAddress: string; niveauUsage: string }) {
    return this.http.post<Mss>(`${this.api}`, { ...mss, [type]: { id: id } })
  }

  updateMss(type: 'structure' | 'person', id: string, mssId: string, mss: { mssAddress: string; niveauUsage: string }) {
    mssId = encodeURIComponent(mssId)
    return this.http.put<Mss>(`${this.api}/${mssId}`, { ...mss, [type]: { id: id } })
  }

  deactivateMss(id: string) {
    id = encodeURIComponent(id)
    return this.http.delete<Mss>(`${this.api}/${id}`)
  }

  activateMss(id: string) {
    id = encodeURIComponent(id)
    return this.http.patch<Mss>(`${this.api}/${id}`, {})
  }
}
