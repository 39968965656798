<div class="flex items-center justify-evenly gap-4">
  <div class="flex flex-col gap-4">
    <div class="flex gap-4">
      <div class="flex flex-col gap-1">
        <label for="search">{{ "home.research" | translate }}</label>
        <input
          pInputText
          type="text"
          class="w-96"
          [(ngModel)]="values.query"
          (keydown.enter)="searchSimple()"
          id="search" />
      </div>
      <div class="flex flex-col gap-1">
        <label for="location">{{ "home.location" | translate }}</label>
        <input
          pInputText
          type="text"
          class="w-96"
          (keydown)="values.location.longitude = 0; values.location.latitude = 0"
          (keydown.enter)="searchSimple()"
          [(ngModel)]="values.location.address"
          (blur)="setCoordinates()"
          id="location" />
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <label for="range">{{ "home.range" | translate }} : {{ values.range }}km</label>
      <div class="mx-2">
        <p-slider
          id="range"
          min="1"
          max="100"
          styleClass="w-96 secondary"
          [(ngModel)]="values.range" />
      </div>
    </div>
  </div>
  <button
    pButton
    type="submit"
    icon="pi pi-search"
    severity="secondary"
    [label]="'home.research' | translate"
    (click)="searchSimple()"></button>
</div>
