<div class="flex flex-col gap-3">
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "coordinates.compl-dest" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.complDest"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'complDest', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "coordinates.compl-geo" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.complGeo"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'complGeo', value: $event })" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "coordinates.lane-number" | translate }}</span>
      <div class="flex gap-4">
        <arsb-fusion-input-button
          [value]="coord.numVoie"
          [buttonRight]="!rightSide"
          inputClass="w-20"
          (setValue)="setValue.emit({ control: 'numVoie', value: $event })" />
        <arsb-fusion-input-button
          [value]="coord.indiceRepVoie"
          [buttonRight]="!rightSide"
          inputClass="w-20"
          (setValue)="setValue.emit({ control: 'indiceRepVoie', value: $event })" />
      </div>
    </div>
    <div class="flex flex-col">
      <span>{{ "coordinates.lane-type" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.libTypeVoie"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'libTypeVoie', value: $event })" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "coordinates.lane-label" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.libVoie"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'libVoie', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "coordinates.mention-distrib" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.mentionDistVoie"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'mentionDistVoie', value: $event })" />
    </div>
  </div>
  <div class="flex flex-col">
    <span>{{ "coordinates.address" | translate }}</span>
    <input
      pInputText
      id="adresse"
      [value]="handleAddress(undefined, coord)"
      disabled />
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "coordinates.office" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.bureauCedex"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'bureauCedex', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "coordinates.city" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.libCommune"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'libCommune', value: $event })" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "coordinates.postal-code" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.codePostal"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'codePostal', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "coordinates.city-code" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.codeCommune"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'codeCommune', value: $event })" />
    </div>
  </div>
  <div class="my-4">
    <div
      leaflet
      [leafletOptions]="mapOptions"
      [leafletLayers]="layers"
      (leafletMapReady)="map = $event"
      class="h-[300px] rounded-2xl relative"></div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "coordinates.phone-home" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.tel"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'tel', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "coordinates.phone-cell" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.portable"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'portable', value: $event })" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "coordinates.phone-other" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.telOther"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'telOther', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "coordinates.fax" | translate }}</span>
      <arsb-fusion-input-button
        [value]="coord.fax"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'fax', value: $event })" />
    </div>
  </div>
  <div class="flex flex-col">
    <span>{{ "coordinates.email" | translate }}</span>
    <arsb-fusion-input-button
      [value]="coord.email"
      [buttonRight]="!rightSide"
      (setValue)="setValue.emit({ control: 'email', value: $event })" />
  </div>
</div>
