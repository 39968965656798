import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly http = inject(HttpClient)

  private endpoint = environment.apiUrl

  download(elementData: { entityId: string; entityClass: string; entityLabel: string }) {
    return this.http.get(`${this.endpoint}/file/${elementData.entityId}`, {
      responseType: 'blob',
    })
  }
}
