import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CardModule } from 'primeng/card'
import { RouterLink } from '@angular/router'
import { TranslateModule } from '@ngx-translate/core'
import { DatePipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common'
import { ButtonModule } from 'primeng/button'
import { Statut } from '@models/gcs-object'
import { NIVEAU_USAGE } from '@models/mss'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'arsb-card',
  standalone: true,
  imports: [
    CardModule,
    RouterLink,
    TranslateModule,
    NgIf,
    NgClass,
    ButtonModule,
    DatePipe,
    NgTemplateOutlet,
    TooltipModule,
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardComponent {
  protected readonly Statut = Statut
  @Input() icon?: string
  @Input() iconLabel?: string
  @Input() iconTooltip?: string
  @Input() label!: string
  @Input() label2?: string
  @Input() onTwoLines: boolean = false
  @Input() date?: Date

  @Input() statut?: Statut

  @Input() link?: string[]
  @Input() linkParams?: { [key: string]: string }
  @Input() canDeactivate: boolean = false
  @Input() canActivate: boolean = false
  @Input() canUpdate: boolean = false

  @Output() deactivate = new EventEmitter<void>()
  @Output() activate = new EventEmitter<void>()
  @Output() update = new EventEmitter<void>()

  get cardClass(): string {
    if (this.statut === Statut.DEACTIVATED) {
      return 'bg-red-200'
    }
    return ''
  }

  clickDeactivate(event: Event) {
    event.stopPropagation()
    event.preventDefault()
    this.deactivate.emit()
  }

  clickActivate(event: Event) {
    event.stopPropagation()
    event.preventDefault()
    this.activate.emit()
  }

  clickUpdate(event: Event) {
    event.stopPropagation()
    event.preventDefault()
    this.update.emit()
  }

  get iconClass() {
    switch (this.iconLabel) {
      case NIVEAU_USAGE._4_PRINCIPALE:
        return 'pi pi-angle-double-up text-red-600 text-3xl'
      case NIVEAU_USAGE._3_DEFAULT:
        return 'pi pi-angle-up text-orange-600 text-3xl'
      case NIVEAU_USAGE._2_CONSULTEE:
        return 'pi pi-angle-down text-blue-600 text-3xl'
      case NIVEAU_USAGE._1_CONSULTEE_ALEATOIREMENT:
        return 'pi pi-angle-double-down text-green-600 text-3xl'
      case NIVEAU_USAGE._0_NON_UTILISEE:
        return 'pi pi-minus-circle text-gray-600 text-3xl'
    }

    return this.icon
  }
}
