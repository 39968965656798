<div
  [formGroup]="form"
  class="flex flex-col gap-3">
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="complDest">{{ "coordinates.compl-dest" | translate }}</label>
      <input
        pInputText
        id="complDest"
        formControlName="complDest" />
    </div>
    <div class="flex flex-col">
      <label for="complGeo">{{ "coordinates.compl-geo" | translate }}</label>
      <input
        pInputText
        id="complGeo"
        formControlName="complGeo" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="numVoie">{{ "coordinates.lane-number" | translate }}</label>
      <div class="flex gap-4">
        <input
          pInputText
          id="numVoie"
          formControlName="numVoie"
          class="w-20" />
        <input
          pInputText
          id="indiceRepVoie"
          formControlName="indiceRepVoie"
          class="w-20" />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="libTypeVoie">{{ "coordinates.lane-type" | translate }}</label>
      <input
        pInputText
        id="libTypeVoie"
        formControlName="libTypeVoie" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="libVoie">{{ "coordinates.lane-label" | translate }}</label>
      <input
        pInputText
        id="libVoie"
        formControlName="libVoie" />
    </div>
    <div class="flex flex-col">
      <label for="mentionDistVoie">{{ "coordinates.mention-distrib" | translate }}</label>
      <input
        pInputText
        id="mentionDistVoie"
        formControlName="mentionDistVoie" />
    </div>
  </div>
  <div class="flex flex-col">
    <label for="adresse">{{ "coordinates.address" | translate }}</label>
    <input
      pInputText
      id="adresse"
      [value]="handleAddress(this.form)"
      disabled />
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="bureauCedex">{{ "coordinates.office" | translate }}</label>
      <input
        pInputText
        id="bureauCedex"
        formControlName="bureauCedex" />
    </div>
    <div class="flex flex-col">
      <label for="libCommune">{{ "coordinates.city" | translate }}</label>
      <input
        pInputText
        id="libCommune"
        formControlName="libCommune" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="codePostal">{{ "coordinates.postal-code" | translate }}</label>
      <input
        pInputText
        id="codePostal"
        formControlName="codePostal" />
    </div>
    <div class="flex flex-col">
      <label for="codeCommune">{{ "coordinates.city-code" | translate }}</label>
      <input
        pInputText
        id="codeCommune"
        formControlName="codeCommune" />
    </div>
  </div>
  <div class="my-4">
    <div
      leaflet
      [leafletOptions]="mapOptions"
      [leafletLayers]="layers"
      (leafletMapReady)="map = $event"
      class="h-[300px] rounded-2xl relative">
      <div class="absolute top-2 right-2 z-[1000]">
        <button
          pButton
          type="button"
          icon="pi pi-map"
          (click)="locate()"></button>
      </div>
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="tel">{{ "coordinates.phone-home" | translate }}</label>
      <input
        pInputText
        id="tel"
        formControlName="tel" />
    </div>
    <div class="flex flex-col">
      <label for="portable">{{ "coordinates.phone-cell" | translate }}</label>
      <input
        pInputText
        id="portable"
        formControlName="portable" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <label for="telOther">{{ "coordinates.phone-other" | translate }}</label>
      <input
        pInputText
        id="telOther"
        formControlName="telOther" />
    </div>
    <div class="flex flex-col">
      <label for="fax">{{ "coordinates.fax" | translate }}</label>
      <input
        pInputText
        id="fax"
        formControlName="fax" />
    </div>
  </div>
  <div class="flex flex-col">
    <label for="email">{{ "coordinates.email" | translate }}</label>
    <input
      pInputText
      id="email"
      formControlName="email" />
  </div>
</div>
