import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { ButtonDirective } from 'primeng/button'
import { FormsModule } from '@angular/forms'
import { InputTextModule } from 'primeng/inputtext'
import { SliderModule } from 'primeng/slider'
import { TranslateModule } from '@ngx-translate/core'
import { PrimeTemplate } from 'primeng/api'
import { AddressService } from '@services/address.service'
import { AdvancedQueryParams } from '@module/home/models/search'
import { SelectButtonModule } from 'primeng/selectbutton'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'arsb-search-advanced',
  standalone: true,
  imports: [
    ButtonDirective,
    FormsModule,
    InputTextModule,
    SliderModule,
    TranslateModule,
    PrimeTemplate,
    SelectButtonModule,
    TooltipModule,
  ],
  templateUrl: './search-advanced.component.html',
  styleUrl: './search-advanced.component.scss',
})
export class SearchAdvancedComponent implements OnChanges {
  private readonly addressService = inject(AddressService)

  @Input() queryParams?: AdvancedQueryParams

  @Output() search = new EventEmitter<AdvancedQueryParams>()

  values: {
    type: 'structure' | 'person'
    location: { address: string; latitude: number; longitude: number }
    range: number
    structure: {
      finessStartWith: string
      siretStartWith: string
      rnaStartWith: string
      adresseMatch: string
      codePostalStartWith: string
      libCommuneContain: string
    }
  } = {
    type: 'structure',
    location: { address: '', latitude: 0, longitude: 0 },
    range: 20,
    structure: {
      finessStartWith: '',
      siretStartWith: '',
      rnaStartWith: '',
      adresseMatch: '',
      codePostalStartWith: '',
      libCommuneContain: '',
    },
  }
  typeOptions: { label: string; value: string; icon: string; constant?: boolean }[] = [
    { label: 'home.query.structure', icon: 'pi pi-building', value: 'structure' },
    { label: 'home.query.person', icon: 'pi pi-user', value: 'person', constant: true },
  ]

  ngOnChanges(changes: SimpleChanges) {
    if (changes['queryParams'] && changes['queryParams'].currentValue) {
      this.values.type = changes['queryParams'].currentValue.type ?? 'structure'
      this.values.location = {
        address: changes['queryParams'].currentValue.address,
        latitude: changes['queryParams'].currentValue.latitude ?? 0,
        longitude: changes['queryParams'].currentValue.longitude ?? 0,
      }
      this.values.range = changes['queryParams'].currentValue.range ?? 20

      if (this.values.type === 'structure') {
        this.values.structure = {
          finessStartWith: changes['queryParams'].currentValue.finessStartWith ?? '',
          siretStartWith: changes['queryParams'].currentValue.siretStartWith ?? '',
          rnaStartWith: changes['queryParams'].currentValue.rnaStartWith ?? '',
          adresseMatch: changes['queryParams'].currentValue.adresseMatch ?? '',
          codePostalStartWith: changes['queryParams'].currentValue.codePostalStartWith ?? '',
          libCommuneContain: changes['queryParams'].currentValue.libCommuneContain ?? '',
        }
      }
    }
  }

  searchAdvanced() {
    if (this.values.location.latitude === 0 && this.values.location.longitude === 0 && this.values.location.address) {
      this.addressService.getAddresses(this.values.location.address).subscribe((addresses) => {
        this.values.location.latitude = addresses[0].geometry.coordinates[1]
        this.values.location.longitude = addresses[0].geometry.coordinates[0]
        this.emit()
      })
    } else {
      this.emit()
    }
  }

  private emit() {
    this.search.emit({
      type: 'structure',
      address: this.values.location.address,
      latitude: this.values.location.latitude,
      longitude: this.values.location.longitude,
      range: this.values.range,
      distanceMeters: this.values.range * 1000,

      finessStartWith: this.values.structure.finessStartWith,
      siretStartWith: this.values.structure.siretStartWith,
      rnaStartWith: this.values.structure.rnaStartWith,
      adresseMatch: this.values.structure.adresseMatch,
      codePostalStartWith: this.values.structure.codePostalStartWith,
      libCommuneContain: this.values.structure.libCommuneContain,
    })
  }

  setCoordinates() {
    this.addressService.getAddresses(this.values.location.address).subscribe((addresses) => {
      this.values.location.latitude = addresses[0].geometry.coordinates[1]
      this.values.location.longitude = addresses[0].geometry.coordinates[0]
    })
  }
}
