<p-card>
  <div class="ps-4 flex flex-col gap-3">
    <div class="flex justify-between items-start">
      <div class="flex flex-col xl:flex-row gap-6">
        <div class="flex flex-col">
          <span>{{ "exercise-pro.civ" | translate }}</span>
          <input
            pInputText
            [(ngModel)]="exercisePro.libCiv"
            disabled />
        </div>
        <div class="flex flex-col">
          <span>{{ "exercise-pro.nom" | translate }}</span>
          <input
            pInputText
            [(ngModel)]="exercisePro.nom"
            disabled />
        </div>
        <div class="flex flex-col">
          <span>{{ "exercise-pro.prenom" | translate }}</span>
          <input
            pInputText
            [(ngModel)]="exercisePro.prenom"
            disabled />
        </div>
      </div>
      <div class="flex gap-4">
        <button
          pButton
          type="button"
          severity="info"
          icon="pi pi-pencil"
          [pTooltip]="'exercise-pro.edit' | translate"
          (click)="updateExercisePro.emit(exercisePro)"></button>
      </div>
    </div>
    <div class="flex flex-col">
      <span>{{ "exercise-pro.profession" | translate }}</span>
      <input
        pInputText
        [(ngModel)]="exercisePro.libProf"
        class="w-[25rem]"
        disabled />
    </div>
    <div class="flex flex-col">
      <span>{{ "exercise-pro.category-profession" | translate }}</span>
      <input
        pInputText
        [(ngModel)]="exercisePro.libCatProf"
        class="w-[25rem]"
        disabled />
    </div>

    <div class="mt-4 flex justify-between">
      <h3>{{ "structure.activities" | translate }}</h3>
      <div class="flex gap-4">
        <button
          pButton
          type="button"
          [ngClass]="{ 'p-button-danger': showDisabledActivities, 'p-button-info': !showDisabledActivities }"
          [icon]="showDisabledActivities ? 'pi pi-eye' : 'pi pi-eye-slash'"
          [pTooltip]="'toggle.invisible' | translate: { label: 'activity.activities' | translate | lowercase }"
          (click)="showDisabledActivities = !showDisabledActivities"></button>
        <button
          pButton
          type="button"
          severity="success"
          icon="pi pi-plus"
          [pTooltip]="'activity.new' | translate"
          (click)="createActivity.emit(exercisePro)"></button>
      </div>
    </div>

    <p-table [value]="filteredActivities">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "activity.mode-exercice" | translate }}</th>
          <th>{{ "activity.fonction" | translate }}</th>
          <th>{{ "coordinates.title" | translate }}</th>
          <th>{{ "structure.label" | translate }}</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-activity>
        <tr [ngClass]="{ 'bg-red-100': activity.statut === Statut.DEACTIVATED }">
          <td>{{ activity.libModeExercice }}</td>
          <td>{{ activity.libFonction }}</td>
          <td>
            <div
              *ngIf="activity.activityCoord?.libCommune"
              class="flex flex-col">
              <span>{{ activity.activityCoord.adresse }}</span>
              <span>{{ activity.activityCoord.codePostal }}, {{ activity.activityCoord.libCommune }}</span>
            </div>
          </td>
          <td>
            <arsb-card
              *ngIf="activity.structure"
              icon="pi pi-building"
              [label]="activity.structure.raisonSociale"
              [label2]="activity.structure.libCommune"
              [onTwoLines]="true"
              [link]="['/', 'structure', activity.structure.id]"
              [date]="activity.structure.statutDate"
              [statut]="activity.structure.statut"></arsb-card>
          </td>
          <td>
            <div class="flex flex-col gap-2">
              <button
                pButton
                *ngIf="activity.statut !== Statut.DEACTIVATED"
                type="button"
                severity="info"
                icon="pi pi-pencil"
                [pTooltip]="'activity.edit' | translate"
                (click)="updateActivity.emit({ exercisePro: exercisePro, activity: activity })"></button>
              <button
                pButton
                *ngIf="activity.statut !== Statut.DEACTIVATED"
                type="button"
                severity="danger"
                icon="pi pi-times"
                [pTooltip]="'activity.deactivate' | translate"
                (click)="deactivateActivity.emit({ exercisePro: exercisePro, activity: activity })"></button>
              <button
                pButton
                *ngIf="activity.statut === Statut.DEACTIVATED && person?.statut !== Statut.DEACTIVATED"
                type="button"
                severity="success"
                icon="pi pi-check"
                [pTooltip]="'activity.reactivate' | translate"
                (click)="reactivateActivity.emit({ exercisePro: exercisePro, activity: activity })"></button>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!--p-tabView [scrollable]="true">
      <p-tabPanel
        *ngFor="let activity of filteredActivities"
        [header]="activity.libFonction"
        [headerStyleClass]="activity.statut == Statut.DEACTIVATED ? 'deactivated' : ''">
        <arsb-person-activity
          [activity]="activity"
          (updateActivity)="updateActivity.emit({ exercisePro: exercisePro, activity: $event })" />
      </p-tabPanel>
    </p-tabView-->
  </div>
</p-card>
