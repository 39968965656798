import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FicheStructure, Structure } from '@models/structure'
import { InputTextModule } from 'primeng/inputtext'
import { NgIf } from '@angular/common'
import { PaginatorModule } from 'primeng/paginator'
import { ReactiveFormsModule } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { FusionInputButtonComponent } from '@module/fusion/components/fusion-input-button/fusion-input-button.component'
import { SideCoordFusionComponent } from '@module/fusion/components/side-coord-fusion/side-coord-fusion.component'

@Component({
  selector: 'arsb-side-structure-fusion',
  standalone: true,
  imports: [
    InputTextModule,
    NgIf,
    PaginatorModule,
    ReactiveFormsModule,
    TranslateModule,
    FusionInputButtonComponent,
    SideCoordFusionComponent,
  ],
  templateUrl: './side-structure-fusion.component.html',
  styleUrl: './side-structure-fusion.component.scss',
})
export class SideStructureFusionComponent implements OnInit {
  @Input() structure!: FicheStructure
  @Input() rightSide!: boolean

  @Output() setValue = new EventEmitter<{ control: string; value: string; subGroup?: string }>()

  fusion?: Structure

  ngOnInit() {
    this.fusion = this.structure.fusion
  }
}
