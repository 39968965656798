import { Component, inject, Input, OnChanges, OnInit } from '@angular/core'
import { ButtonDirective } from 'primeng/button'
import { CardInformationComponent } from '@shared/components/card-information/card-information.component'
import { InputComponent } from '@shared/components/input/input.component'
import { InputTextModule } from 'primeng/inputtext'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { PhoneDirective } from '@shared/directives/phone.directive'
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { Coord } from '@models/coord'
import { NgIf } from '@angular/common'
import { latLng, Map, MapOptions, Marker, marker, tileLayer } from 'leaflet'
import { AddressService } from '@services/address.service'
import { MessageService } from 'primeng/api'
import { PostalCodeDirective } from '@shared/directives/postal-code.directive'
import { hasIsEmailError, hasMaxLengthError, hasMinLengthError, hasPatternError } from '@shared/utils/error-form'
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete'
import { Address } from '@models/address'
import { getLabelPlaceholder, handleAddress, isDisabled, setValueToNull } from '@shared/utils/value-form'
import { InputMaskModule } from 'primeng/inputmask'

@Component({
  selector: 'arsb-coordinates',
  standalone: true,
  imports: [
    ButtonDirective,
    CardInformationComponent,
    InputComponent,
    InputTextModule,
    LeafletModule,
    PhoneDirective,
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    PostalCodeDirective,
    AutoCompleteModule,
    InputMaskModule,
  ],
  templateUrl: './coordinates.component.html',
  styleUrl: './coordinates.component.scss',
})
export class CoordinatesComponent implements OnInit, OnChanges {
  @Input() national?: Coord
  @Input() regional?: Coord
  @Input() formGroupName!: string

  private readonly translate = inject(TranslateService)
  private readonly messageService = inject(MessageService)
  protected readonly formGroupDirective = inject(FormGroupDirective)

  private readonly addressService = inject(AddressService)

  protected readonly hasPatternError = hasPatternError
  protected readonly hasMinLengthError = hasMinLengthError
  protected readonly hasIsEmailError = hasIsEmailError
  protected readonly isDisabled = isDisabled
  protected readonly setValueToNull = setValueToNull
  protected readonly getLabelPlaceholder = getLabelPlaceholder
  protected readonly hasMaxLengthError = hasMaxLengthError
  protected readonly handleAddress = handleAddress

  public form?: FormGroup
  addresses: Address[] = []

  map?: Map
  mapOptions: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '...',
      }),
    ],
    zoom: 13,
    center: latLng(48.1119, -1.6806),
  }
  layers: Marker[] = []

  ngOnInit() {
    if (
      (this.national?.latitude && this.national?.longitude) ||
      (this.regional?.latitude && this.regional?.longitude)
    ) {
      const { latitude, longitude } =
        this.regional?.latitude && this.regional?.longitude ? this.regional : this.national!
      this.layers = [marker([latitude, longitude])]
      this.mapOptions.center = latLng(latitude, longitude)
    }
  }

  ngOnChanges() {
    this.form = this.formGroupDirective.form.get(this.formGroupName) as FormGroup
  }

  searchAddress() {
    const address = `${handleAddress(this.form!, this.national)} ${this.form?.get('codePostal')?.value ?? ''} ${this.form?.get('libCommune')?.value ?? ''}`
    this.form!.controls['libCommune'].patchValue(this.form!.controls['libCommune'].value.toUpperCase())
    this.addressService.getAddresses(address).subscribe((addresses) => {
      this.addresses = addresses
    })
  }

  locate() {
    const address = `${handleAddress(this.form!, this.national)} ${this.form?.get('codePostal')?.value ?? ''} ${this.form?.get('libCommune')?.value ?? ''}`
    this.addressService.getAddresses(address).subscribe((addresses) => {
      if (addresses.length === 0) {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('common.errors.empty-addresses'),
        })
        return
      }
      this.addressService.setMapAddress(addresses[0], this.map, this.layers, this.form)
    })
  }

  selectAddress(event: AutoCompleteSelectEvent) {
    const address: Address = event.value
    this.form?.patchValue({
      address: handleAddress(this.form!, this.national),
      libCommune: address.properties.city.toUpperCase(),
      codePostal: address.properties.postcode,
      codeCommune: address.properties.citycode,
      latitude: address.geometry.coordinates[1],
      longitude: address.geometry.coordinates[0],
    })

    this.map?.setView([address.geometry.coordinates[1], address.geometry.coordinates[0]], 13)
    this.layers = [marker([address.geometry.coordinates[1], address.geometry.coordinates[0]])]
  }

  onCodePostalChange() {
    const codePostal = this.form!.controls['codePostal'].value.replace(/ /g, '')
    if (/^\d{5}$/.test(codePostal)) {
      this.addressService.getAddresses(handleAddress(this.form!, this.national), codePostal).subscribe((addresses) => {
        if (addresses.length == 0) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('common.error'),
            detail: this.translate.instant('common.errors.empty-addresses'),
          })
          return
        }

        this.form?.patchValue({
          address: handleAddress(this.form!, this.national),
          libCommune: addresses[0].properties.city.toUpperCase(),
          codeCommune: addresses[0].properties.citycode,
          latitude: addresses[0].geometry.coordinates[1],
          longitude: addresses[0].geometry.coordinates[0],
        })

        this.map?.setView([addresses[0].geometry.coordinates[1], addresses[0].geometry.coordinates[0]], 13)
        this.layers = [marker([addresses[0].geometry.coordinates[1], addresses[0].geometry.coordinates[0]])]
      })
    }
  }
}
