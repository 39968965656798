import { Component, inject, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { LowerCasePipe, NgClass, NgForOf, NgIf } from '@angular/common'
import { FichePerson, Person } from '@models/person'
import { PersonService } from '@services/person.service'
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { CardInformationComponent } from '@shared/components/card-information/card-information.component'
import { InputComponent } from '@shared/components/input/input.component'
import { InputTextModule } from 'primeng/inputtext'
import { ButtonModule } from 'primeng/button'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { NosService } from '@services/nos.service'
import { Nos } from '@models/nos'
import { forkJoin, mergeMap, of, tap } from 'rxjs'
import { DropdownModule } from 'primeng/dropdown'
import { PhoneDirective } from '@shared/directives/phone.directive'
import { PersonExerciseProComponent } from '@module/person/components/person-exercise-pro/person-exercise-pro.component'
import { ExercisePro } from '@models/exercise-pro'
import { TabView, TabViewModule } from 'primeng/tabview'
import { DialogService } from 'primeng/dynamicdialog'
import { NewExerciseProComponent } from '@module/person/dialogs/new-exercise-pro/new-exercise-pro.component'
import { NewActivityComponent } from '@module/person/dialogs/new-activity/new-activity.component'
import { Fields } from '@models/field'
import { ExerciseProService } from '@services/exercise-pro.service'
import { Activity } from '@models/activity'
import { getFieldsNos } from '@shared/utils/fields-nos'
import { ActivityService } from '@services/activity.service'
import { CoordinatesComponent } from '@shared/components/coordinates/coordinates.component'
import { FormComponentComponent } from '@shared/components/form-component/form-component.component'
import { Statut } from '@models/gcs-object'
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch'
import { getLabelPlaceholder, handleAddress, handleText, setValueToNull } from '@shared/utils/value-form'
import { CardComponent } from '@shared/components/card/card.component'
import { Mss } from '@models/mss'
import { MssDialogComponent } from '@shared/dialogs/mss-dialog/mss-dialog.component'
import { MssService } from '@services/mss.service'

@Component({
  standalone: true,
  imports: [
    BreadcrumbModule,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    CardInformationComponent,
    InputComponent,
    InputTextModule,
    ButtonModule,
    LeafletModule,
    DropdownModule,
    PhoneDirective,
    PersonExerciseProComponent,
    NgForOf,
    TabViewModule,
    CoordinatesComponent,
    FormComponentComponent,
    LowerCasePipe,
    NgClass,
    InputSwitchModule,
    CardComponent,
  ],
  templateUrl: './person.component.html',
  styleUrl: './person.component.scss',
})
export class PersonComponent implements OnInit {
  private readonly route = inject(ActivatedRoute)
  private readonly translate = inject(TranslateService)
  private readonly formBuilder = inject(FormBuilder)
  private readonly confirmationService = inject(ConfirmationService)
  private readonly messageService = inject(MessageService)
  private readonly dialogService = inject(DialogService)
  private readonly router = inject(Router)

  private readonly personService = inject(PersonService)
  private readonly nosService = inject(NosService)
  private readonly exerciseProService = inject(ExerciseProService)
  private readonly activityService = inject(ActivityService)
  private readonly mssService = inject(MssService)

  @ViewChild('tabView') tabView?: TabView
  protected readonly Statut = Statut
  protected readonly setValueToNull = setValueToNull
  protected readonly getLabelPlaceholder = getLabelPlaceholder
  id: string = this.route.snapshot.params['id']

  fichePerson?: FichePerson
  fieldsPerson?: Fields
  nosValues: { [key: string]: Nos[] } = {}
  exercisePros: ExercisePro[] = []

  breadcrumbItems: MenuItem[] = []
  homeItem: MenuItem = { icon: 'pi pi-home', routerLink: '/' }

  formPerson?: FormGroup
  showAllMsses = false
  showDisabledMsses = false
  showDisabledExercisePro: boolean = false

  ngOnInit() {
    this.translate.get('person.label').subscribe((label) => {
      this.breadcrumbItems = [{ label: label }, { label: this.id }]
    })

    this.route.params.subscribe((params) => {
      this.id = params['id']
      this.setPerson()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })
  }

  private setPerson() {
    if (this.id === 'new') {
      this.setPersonFields().subscribe(({ fields, nosValues }) => {
        this.fieldsPerson = fields
        this.nosValues = nosValues
        this.setFormPerson()
      })
    } else {
      forkJoin([this.setPersonFields(), this.personService.getFichePerson(this.id)])
        .pipe(
          tap(([{ fields, nosValues }, fichePerson]) => {
            this.fieldsPerson = fields
            this.nosValues = nosValues
            this.fichePerson = fichePerson
            this.setFormPerson()
          }),
          mergeMap(() => this.exerciseProService.getExercisesProByPerson(this.id)),
        )
        .subscribe((exercisesPro) => {
          this.exercisePros = exercisesPro
          const queryParams = this.route.snapshot.queryParams
          if (this.tabView && queryParams['exerciseProId']) {
            this.tabView.activeIndex = this.exercisePros.findIndex(
              (exercisePro) => exercisePro.id === queryParams['exerciseProId'],
            )
            document.getElementById('exercisePro')?.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        })
    }
  }

  private setPersonFields() {
    return getFieldsNos(
      forkJoin([this.personService.getPersonFields(), this.personService.getPersonFields('personCoord')]),
      this.nosService,
      [null, 'personCoord'],
    )
  }

  private setFormPerson() {
    const person = this.fichePerson?.regional
    this.formPerson = this.formBuilder.group({
      disablePerson: [person?.statut === Statut.DEACTIVATED],
      nomUsage: [
        {
          value: person?.nomUsage,
          disabled: !this.fieldsPerson!['nomUsage']?.isSurchargeable || person?.statut === Statut.DEACTIVATED,
        },
      ],
      prenomUsage: [
        {
          value: person?.prenomUsage,
          disabled: !this.fieldsPerson!['prenomUsage']?.isSurchargeable || person?.statut === Statut.DEACTIVATED,
        },
      ],
      codeCiv: [
        {
          value: person?.codeCiv,
          disabled: !this.fieldsPerson!['codeCiv']?.isSurchargeable || person?.statut === Statut.DEACTIVATED,
        },
      ],
      libCiv: [
        {
          value: person?.libCiv,
          disabled: !this.fieldsPerson!['libCiv']?.isSurchargeable || person?.statut === Statut.DEACTIVATED,
        },
      ],
      personCoord: this.formBuilder.group({
        complDest: [
          {
            value: person?.personCoord?.complDest,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.maxLength(38)],
        ],
        complGeo: [
          {
            value: person?.personCoord?.complGeo,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.maxLength(38)],
        ],
        numVoie: [
          {
            value: person?.personCoord?.numVoie,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        indiceRepVoie: [
          {
            value: person?.personCoord?.indiceRepVoie,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        libTypeVoie: [
          {
            value: person?.personCoord?.libTypeVoie,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        libVoie: [
          {
            value: person?.personCoord?.libVoie,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        mentionDistVoie: [
          {
            value: person?.personCoord?.mentionDistVoie,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        bureauCedex: [
          {
            value: person?.personCoord?.bureauCedex,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        codePostal: [
          {
            value: person?.personCoord?.codePostal,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^[0-9]{5}$')],
        ],
        libCommune: [
          {
            value: person?.personCoord?.libCommune,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        codeCommune: [
          {
            value: person?.personCoord?.codeCommune,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^[0-9][A-B0-9][0-9]{3}$')],
        ],
        tel: [
          {
            value: person?.personCoord?.tel,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        fax: [
          {
            value: person?.personCoord?.fax,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        email: [
          {
            value: person?.personCoord?.email,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.email],
        ],
        portable: [
          {
            value: person?.personCoord?.portable,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        telOther: [
          {
            value: person?.personCoord?.telOther,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        latitude: [
          {
            value: person?.personCoord?.latitude,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
        longitude: [
          {
            value: person?.personCoord?.longitude,
            disabled: person?.statut === Statut.DEACTIVATED,
          },
        ],
      }),
    })
  }

  submit() {
    if (this.formPerson?.invalid) return

    const person = {
      ...this.formPerson?.value,
      nomUsage: handleText(this.formPerson?.get('nomUsage')!),
      personCoord: {
        ...this.formPerson?.get('personCoord')?.value,
        complDest: handleText(this.formPerson?.get('personCoord')?.get('complDest')!),
        complGeo: handleText(this.formPerson?.get('personCoord')?.get('complGeo')!),
        numVoie: handleText(this.formPerson?.get('personCoord')?.get('numVoie')!),
        indiceRepVoie: handleText(this.formPerson?.get('personCoord')?.get('indiceRepVoie')!),
        libTypeVoie: handleText(this.formPerson?.get('personCoord')?.get('libTypeVoie')!),
        libVoie: handleText(this.formPerson?.get('personCoord')?.get('libVoie')!),
        mentionDistVoie: handleText(this.formPerson?.get('personCoord')?.get('mentionDistVoie')!),
        bureauCedex: handleText(this.formPerson?.get('personCoord')?.get('bureauCedex')!),
        adresse: handleAddress(
          this.formPerson?.controls['personCoord'] as FormGroup,
          this.fichePerson?.national?.personCoord,
        ),
        codePostal: this.formPerson?.get('personCoord')?.get('codePostal')?.value?.replace(/ /g, ''),
        codeCommune: this.formPerson?.get('personCoord')?.get('codeCommune')?.value?.replace(/ /g, ''),
        tel: this.formPerson?.get('personCoord')?.get('tel')?.value?.replace(/[ .*]/g, ''),
        portable: this.formPerson?.get('personCoord')?.get('portable')?.value?.replace(/[ .*]/g, ''),
        fax: this.formPerson?.get('personCoord')?.get('fax')?.value?.replace(/[ .*]/g, ''),
        telOther: this.formPerson?.get('personCoord')?.get('telOther')?.value?.replace(/[ .*]/g, ''),
        email: handleText(this.formPerson?.get('personCoord')?.get('email')!, {
          unaccent: false,
          lowercase: true,
        }),
      },
    } as Person

    if (person.codeCiv && this.nosValues['codeCiv'].some((civ) => civ.code === person.codeCiv)) {
      person.libCiv = this.nosValues['codeCiv'].find((civ) => civ.code === person.codeCiv)!.libelle
    }

    if (this.id === 'new') {
      this.personService.createPerson(person).subscribe({
        next: (person) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('common.success'),
            detail: this.translate.instant('person.created'),
          })
          this.router.navigate(['/', 'person', person.id])
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('common.error'),
            detail: this.translate.instant('common.errors.' + error.status),
          })
        },
      })
    } else {
      this.personService.updatePerson(this.id, person).subscribe({
        next: (person) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('common.success'),
            detail: this.translate.instant('person.updated'),
          })
          this.fichePerson = { ...this.fichePerson!, regional: person.regional }
          this.setFormPerson()
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('common.error'),
            detail: this.translate.instant('common.errors.' + error.status),
          })
        },
      })
    }
  }

  openNewExerciseProModal(exercisePro?: ExercisePro) {
    forkJoin([
      getFieldsNos(this.exerciseProService.getFields(), this.nosService),
      exercisePro ? this.exerciseProService.getExercisePro(exercisePro.id) : of(null),
    ]).subscribe(([{ fields, nosValues }, ficheExercisePro]) => {
      const ref = this.dialogService.open(NewExerciseProComponent, {
        header: exercisePro ? this.translate.instant('exercise-pro.edit') : this.translate.instant('exercise-pro.new'),
        width: '70%',
        height: '90%',
        data: {
          personId: this.id,
          person: this.fichePerson?.regional,
          exercisePro: ficheExercisePro,
          fields: fields,
          nosValues: nosValues,
        },
      })

      ref.onClose.subscribe((result: ExercisePro) => {
        if (result) {
          if (exercisePro) {
            this.exerciseProService.updateExercisePro(exercisePro.id, result).subscribe((exercisePro) => {
              this.exercisePros = this.exercisePros.filter((ep) => ep.id !== exercisePro.id)
              this.exercisePros.push(exercisePro)
              this.tabView!.activeIndex = this.exercisePros.length - 1
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('common.success'),
                detail: this.translate.instant('exercise-pro.updated'),
              })
            })
          } else {
            this.exerciseProService.createExercisePro(result).subscribe((exercisePro) => {
              this.exercisePros.push(exercisePro)
              this.tabView!.activeIndex = this.exercisePros.length - 1
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('common.success'),
                detail: this.translate.instant('exercise-pro.created'),
              })
            })
          }
        }
      })
    })
  }

  openNewActivityModal(exercisePro: ExercisePro, activity?: Activity) {
    forkJoin([
      getFieldsNos(this.activityService.getFields(), this.nosService),
      activity ? this.activityService.getActivity(activity.id) : of(null),
    ]).subscribe(([{ fields, nosValues }, ficheActivity]) => {
      const ref = this.dialogService.open(NewActivityComponent, {
        header: activity ? this.translate.instant('activity.edit') : this.translate.instant('activity.new'),
        width: '70%',
        height: '90%',
        data: {
          exercisePro: exercisePro,
          activity: ficheActivity,
          fields: fields,
          nosValues: nosValues,
        },
      })

      ref.onClose.subscribe((result: Activity) => {
        if (result) {
          if (!exercisePro.activities) {
            exercisePro.activities = []
          }

          if (activity) {
            this.activityService.updateActivity(activity.id, result).subscribe((activity) => {
              exercisePro.activities = exercisePro.activities?.filter((a) => a.id !== activity.id)
              exercisePro.activities?.push(activity)
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('common.success'),
                detail: this.translate.instant('activity.updated'),
              })
            })
          } else {
            this.activityService.createActivity(result).subscribe((activity) => {
              exercisePro.activities?.push(activity)
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('common.success'),
                detail: this.translate.instant('activity.created'),
              })
            })
          }
        }
      })
    })
  }

  get filteredExercisePros() {
    if (this.showDisabledExercisePro) {
      return this.exercisePros.sort((a, b) => {
        if (a.statut === Statut.DEACTIVATED && b.statut !== Statut.DEACTIVATED) {
          return 1
        } else if (a.statut !== Statut.DEACTIVATED && b.statut === Statut.DEACTIVATED) {
          return -1
        } else {
          return 0
        }
      })
    }

    return this.exercisePros.filter((exercisePro) => exercisePro.statut != Statut.DEACTIVATED)
  }

  askForDeactivateOrReactivatePerson(event: InputSwitchChangeEvent) {
    if (event.checked) {
      this.confirmationService.confirm({
        message: this.translate.instant('person.confirm.disable'),
        header: this.translate.instant('common.deactivation'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => this.deactivatePerson(),
        reject: () => this.formPerson?.get('disablePerson')?.setValue(false),
      })
    } else {
      if (this.exercisePros?.flatMap((ep) => ep.activities).some((a) => a?.statut === Statut.DEACTIVATED)) {
        this.confirmationService.confirm({
          message: this.translate.instant('activity.deactivated.reactivation-parent'),
          header: this.translate.instant('common.reactivation'),
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'none',
          rejectIcon: 'none',
          rejectButtonStyleClass: 'p-button-text',
          accept: () => this.activatePerson(),
          reject: () => this.formPerson?.get('disablePerson')?.setValue(true),
        })
      } else {
        this.activatePerson()
      }
    }
  }

  private deactivatePerson() {
    this.personService.deactivatePerson(this.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('common.success'),
          detail: this.translate.instant('person.disabled'),
        })
        this.setPerson()
      },
      error: (error) => {
        this.formPerson?.get('disablePerson')?.setValue(false)
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('common.errors.' + error.status),
        })
      },
    })
  }

  private activatePerson() {
    this.personService.reactivatePerson(this.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('common.success'),
          detail: this.translate.instant('person.reactivated'),
        })
        this.setPerson()
      },
      error: (error) => {
        this.formPerson?.get('disablePerson')?.setValue(true)
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('common.errors.' + error.status),
        })
      },
    })
  }

  getControl(control: string) {
    return this.formPerson?.get(control) as FormControl
  }

  deactivateActivity(event: { exercisePro: ExercisePro; activity: Activity }) {
    this.activityService.deactivateActivity(event.activity.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('common.success'),
          detail: this.translate.instant('activity.disabled'),
        })
        this.exercisePros
          .find((exercisePro) => exercisePro.id === event.exercisePro.id)!
          .activities!.find((activity) => activity.id === event.activity.id)!.statut = Statut.DEACTIVATED
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('common.errors.' + error.status),
        })
      },
    })
  }

  reactivateActivity(event: { exercisePro: ExercisePro; activity: Activity }) {
    this.activityService.reactivateActivity(event.activity.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('common.success'),
          detail: this.translate.instant('activity.reactivated'),
        })
        this.exercisePros
          .find((exercisePro) => exercisePro.id === event.exercisePro.id)!
          .activities!.find((activity) => activity.id === event.activity.id)!.statut = Statut.UPDATED
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('common.errors.' + error.status),
        })
      },
    })
  }

  get msses() {
    if (this.showDisabledMsses) {
      return this.fichePerson?.msses
        ?.sort((a, b) => {
          if (a.statut === Statut.DEACTIVATED && b.statut !== Statut.DEACTIVATED) {
            return 1
          } else if (a.statut !== Statut.DEACTIVATED && b.statut === Statut.DEACTIVATED) {
            return -1
          } else {
            return Number.parseInt(b.niveauUsage.slice(1, 2)) - Number.parseInt(a.niveauUsage.slice(1, 2))
          }
        })
        .slice(0, this.showAllMsses ? Infinity : 3)
    }

    return this.fichePerson?.msses
      ?.filter((mss) => mss.statut !== Statut.DEACTIVATED)
      .sort((a, b) => Number.parseInt(b.niveauUsage.slice(1, 2)) - Number.parseInt(a.niveauUsage.slice(1, 2)))
      .slice(0, this.showAllMsses ? Infinity : 3)
  }

  openMssDialog(mss?: Mss) {
    const ref = this.dialogService.open(MssDialogComponent, {
      header: this.translate.instant('mss.title'),
      width: '50%',
      height: '50%',
      data: mss,
    })

    ref.onClose.subscribe((result) => {
      if (result) {
        if (mss) {
          this.mssService.updateMss('person', this.id, mss.id, result).subscribe((mss) => {
            const index = this.fichePerson?.msses?.findIndex((m) => m.id === mss.id)
            if (index !== -1) {
              this.fichePerson!.msses![index!] = mss
            }

            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('common.success'),
              detail: this.translate.instant('mss.updated'),
            })
          })
        } else {
          this.mssService.addMss('person', this.id, result).subscribe((mss) => {
            this.fichePerson = { ...this.fichePerson!, msses: [...(this.fichePerson?.msses ?? []), mss] }
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('common.success'),
              detail: this.translate.instant('mss.added'),
            })
          })
        }
      }
    })
  }

  deactivateMss(mss: Mss) {
    this.mssService.deactivateMss(mss.id).subscribe((mss) => {
      const index = this.fichePerson?.msses?.findIndex((m) => m.id === mss.id)
      if (index !== -1) {
        this.fichePerson!.msses![index!] = mss
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('common.success'),
        detail: this.translate.instant('mss.deactivated'),
      })
    })
  }

  activateMss(mss: Mss) {
    this.mssService.activateMss(mss.id).subscribe((mss) => {
      const index = this.fichePerson?.msses?.findIndex((m) => m.id === mss.id)
      if (index !== -1) {
        this.fichePerson!.msses![index!] = mss
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('common.success'),
        detail: this.translate.instant('mss.activated'),
      })
    })
  }

  resetForm() {
    this.formPerson?.reset(this.fichePerson?.regional)
    this.messageService.add({
      severity: 'info',
      summary: this.translate.instant('common.info'),
      detail: this.translate.instant('form.reset'),
    })
  }
}
