import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '@env/environment'
import { FicheStructure, Structure, StructureRelationType } from '@models/structure'
import { Field } from '@models/field'
import { map } from 'rxjs'
import { Statut } from '@models/gcs-object'
import { Page } from '@models/page'
import { AdvancedQueryParams, SearchedStructure } from '@module/home/models/search'

@Injectable({
  providedIn: 'root',
})
export class StructureService {
  private readonly http = inject(HttpClient)

  private readonly api = environment.apiUrl

  constructor() {}

  getFicheStructure(id: string) {
    return this.http
      .get<FicheStructure>(`${this.api}/structure/${id}`)
      .pipe(map((ficheStructure: FicheStructure) => this.mapFicheStructure(ficheStructure)))
  }

  createStructure(structure: Structure) {
    return this.http
      .post<FicheStructure>(`${this.api}/structure`, structure)
      .pipe(map((ficheStructure: FicheStructure) => this.mapFicheStructure(ficheStructure)))
  }

  updateStructure(id: string, structure: Structure) {
    return this.http
      .put<FicheStructure>(`${this.api}/structure/${id}`, structure)
      .pipe(map((ficheStructure: FicheStructure) => this.mapFicheStructure(ficheStructure)))
  }

  deactivateStructure(id: string) {
    return this.http
      .delete<FicheStructure>(`${this.api}/structure/${id}`)
      .pipe(map((ficheStructure: FicheStructure) => this.mapFicheStructure(ficheStructure)))
  }

  reactivateStructure(id: string) {
    return this.http
      .patch<FicheStructure>(`${this.api}/structure/${id}`, {})
      .pipe(map((ficheStructure: FicheStructure) => this.mapFicheStructure(ficheStructure)))
  }

  getStructureFields(subField?: string) {
    let params = new HttpParams()
    if (subField) params = params.set('subEntityPath', subField)
    return this.http.get<Field[]>(`${this.api}/structure/fields`, { params })
  }

  activateStructureRelation(childId: string, parentId: string, relation: StructureRelationType) {
    return this.http.patch(`${this.api}/structureRelation`, {
      childStructureId: childId,
      parentStructureId: parentId,
      relationType: relation,
    })
  }

  deactivateStructureRelation(childId: string, parentId: string, relation: StructureRelationType) {
    return this.http.delete(`${this.api}/structureRelation`, {
      body: {
        childStructureId: childId,
        parentStructureId: parentId,
        relationType: relation,
      },
    })
  }

  search(page: number, p: AdvancedQueryParams, defaultSorts: string[]) {
    let params = new HttpParams().set('page', page).set('size', 50)
    Object.keys(p).forEach((key) => {
      const typedKey = key as keyof AdvancedQueryParams
      if (p[typedKey] && typedKey !== 'type') {
        params = params.set(key, p[typedKey] as any)
      }
    })
    if (!params.has('distanceMeters') && params.has('range')) {
      params = params.set('distanceMeters', p.range * 1000)
    }
    if (defaultSorts?.length) {
      defaultSorts.forEach((sort) => {
        params = params.append('sort', sort)
      })
    }
    return this.http.get<Page<SearchedStructure>>(`${this.api}/structure/search`, {
      params: params,
    })
  }

  private mapFicheStructure(ficheStructure: FicheStructure): FicheStructure {
    return {
      ...ficheStructure,
      parents: ficheStructure.parents
        ?.filter((parent) => parent !== null)
        ?.sort((a, b) => (a?.statut === Statut.DEACTIVATED ? 1 : Statut.DEACTIVATED ? -1 : 0)),
    }
  }

  fusion(id1: string, id2: string, structure: any) {
    return this.http.post(`${this.api}/structure/fusion/${id1}/${id2}`, structure)
  }
}
