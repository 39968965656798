<form [formGroup]="form">
  <div class="mt-4 flex flex-col">
    <label for="mss-address">
      {{ "mss.address" | translate }}
    </label>
    <input
      pInputText
      id="mss-address"
      formControlName="mssAddress"
      class="w-full" />
  </div>
  <div class="mt-4 flex flex-col">
    <label for="niveau-usage">
      {{ "mss.niveau-usage" | translate }}
    </label>
    <p-dropdown
      inputId="niveau-usage"
      styleClass="w-full"
      [options]="niveauxUsage"
      formControlName="niveauUsage" />
  </div>

  <div class="mt-8 flex justify-end gap-4">
    <button
      pButton
      type="button"
      [label]="'common.cancel' | translate"
      [outlined]="true"
      (click)="ref.close()"></button>
    <button
      pButton
      type="button"
      [label]="'common.save' | translate"
      [disabled]="form.invalid"
      (click)="save()"></button>
  </div>
</form>
