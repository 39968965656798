import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ExercisePro } from '@models/exercise-pro'
import { TabViewModule } from 'primeng/tabview'
import { LowerCasePipe, NgClass, NgForOf, NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { PersonActivityComponent } from '@module/person/components/person-activity/person-activity.component'
import { ButtonDirective } from 'primeng/button'
import { CardModule } from 'primeng/card'
import { TooltipModule } from 'primeng/tooltip'
import { Activity } from '@models/activity'
import { Statut } from '@models/gcs-object'
import { TableModule } from 'primeng/table'
import { CardComponent } from '@shared/components/card/card.component'
import { Person } from '@models/person'
import { InputTextModule } from 'primeng/inputtext'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'arsb-person-exercise-pro',
  standalone: true,
  imports: [
    TabViewModule,
    NgForOf,
    TranslateModule,
    PersonActivityComponent,
    ButtonDirective,
    CardModule,
    TooltipModule,
    LowerCasePipe,
    NgClass,
    TableModule,
    CardComponent,
    NgIf,
    InputTextModule,
    FormsModule,
  ],
  templateUrl: './person-exercise-pro.component.html',
  styleUrl: './person-exercise-pro.component.scss',
})
export class PersonExerciseProComponent {
  @Input() exercisePro!: ExercisePro
  @Input() person?: Person

  @Output() createActivity = new EventEmitter<ExercisePro>()
  @Output() updateExercisePro = new EventEmitter<ExercisePro>()
  @Output() updateActivity = new EventEmitter<{ exercisePro: ExercisePro; activity: Activity }>()
  @Output() deactivateActivity = new EventEmitter<{ exercisePro: ExercisePro; activity: Activity }>()
  @Output() reactivateActivity = new EventEmitter<{ exercisePro: ExercisePro; activity: Activity }>()

  protected readonly Statut = Statut

  showDisabledActivities = false

  get filteredActivities() {
    if (this.showDisabledActivities) {
      return (
        this.exercisePro.activities?.sort((a, b) => {
          if (a.statut == Statut.DEACTIVATED && b.statut != Statut.DEACTIVATED) {
            return 1
          } else if (a.statut != Statut.DEACTIVATED && b.statut == Statut.DEACTIVATED) {
            return -1
          } else {
            return 0
          }
        }) ?? []
      )
    }

    return this.exercisePro.activities?.filter((activity) => activity.statut != Statut.DEACTIVATED) ?? []
  }
}
