import { Coord } from '@models/coord'
import { GcsObject, Statut } from '@models/gcs-object'
import { Mss } from '@models/mss'
import { ExtraElementGroup } from '@models/extra-element'

export interface FicheStructure extends GcsObject {
  national?: Structure
  regional?: Structure
  fusion: Structure
  parents?: StructureParent[]
  activities?: ActivityStructure[]
  msses: Mss[]
  extraElementGroups: ExtraElementGroup[]
}

export interface Structure extends GcsObject {
  raisonSociale: string
  enseigneCommerciale: string
  nomUsage: string
  finessEj: string
  finessEg: string
  type: TypeStructure
  siret: string
  siren: string
  rna: string
  secteurActiviteCode: string
  secteurActiviteLibelle: string
  categorieEtablissementCode: string
  categorieEtablissementLibelle: string
  categorieAgrEtablissementCode: string
  categorieAgrEtablissementLibelle: string
  categorieAgrEtablissementLibelleCourt: string

  structureCoord?: Coord
}

export enum TypeStructure {
  EJ = 'EJ',
  EG = 'EG',
}

export interface StructureParent {
  id: {
    relationType: StructureRelationType
    parentStructureId: string
    childStructureId: string
  }

  statut: Statut
  statutDate: Date
  niveau: string

  childStructure: {
    id: string
    raisonSociale: string
  }
  parentStructure: {
    id: string
    raisonSociale: string
  }
}

export interface ActivityStructure extends GcsObject {
  personId: string
  exerciseProId: string
  prenom: string
  nom: string
  libProf: string
}

export enum StructureRelationType {
  'ENTITE_JURIDIQUE' = 'ENTITE_JURIDIQUE',
}
