import { Component, inject } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete'
import { ButtonModule } from 'primeng/button'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { DropdownModule } from 'primeng/dropdown'
import { Structure, StructureRelationType } from '@models/structure'
import { StructureService } from '@services/structure.service'

@Component({
  selector: 'arsb-link-structure-dialog',
  standalone: true,
  imports: [TranslateModule, AutoCompleteModule, ButtonModule, DropdownModule, ReactiveFormsModule],
  templateUrl: './link-structure-dialog.component.html',
  styleUrl: './link-structure-dialog.component.scss',
})
export class LinkStructureDialogComponent {
  protected readonly ref = inject(DynamicDialogRef)
  private readonly config = inject(DynamicDialogConfig)
  private readonly formBuilder = inject(FormBuilder)
  private readonly translate = inject(TranslateService)

  private readonly structureService = inject(StructureService)

  raisonSociale: string = this.config.data.raisonSociale
  relationTypes: {
    label: string
    value: StructureRelationType
  }[] = Object.values(StructureRelationType).map((value) => ({
    label: this.translate.instant(`structure.relation.${value}`),
    value: value,
  }))
  structures: Structure[] = []

  form = this.formBuilder.group({
    parentId: [null, Validators.required],
    relation: [null, Validators.required],
  })

  saveLink() {
    if (this.form.invalid) return
    this.ref.close(this.form.value)
  }

  researchStructures(event: AutoCompleteCompleteEvent) {
    if (event.query.length < 3) return

    // TODO utiliser un nouvel endpoint pour rechercher les structures
    // this.structureService.search(0, event.query).subscribe((structures) => {
    //   this.structures = structures.content
    // })
  }
}
