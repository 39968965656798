<p-card>
  <div class="flex flex-col gap-3">
    <div class="flex justify-between items-start">
      <div class="flex flex-col gap-1">
        <span class="font-bold">{{ "activity.id" | translate }}</span>
        <span>{{ activity.id }}</span>
      </div>
      <div class="flex gap-4">
        <button
          pButton
          type="button"
          severity="info"
          icon="pi pi-pencil"
          [pTooltip]="'activity.edit' | translate"
          (click)="updateActivity.emit(activity)"></button>
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <span class="font-bold">{{ "activity.fonction" | translate }}</span>
      <div class="flex gap-4">
        <span>{{ activity.codeFonction }}</span>
        <span>-</span>
        <span>{{ activity.libFonction }}</span>
      </div>
    </div>
    <div class="flex flex-col gap-1">
      <span class="font-bold">{{ "activity.mode-exercice" | translate }}</span>
      <div class="flex gap-4">
        <span>{{ activity.codeModeExercice }}</span>
        <span>-</span>
        <span>{{ activity.libModeExercice }}</span>
      </div>
    </div>
    <div
      *ngIf="activity.codeGenreAct"
      class="flex flex-col gap-1">
      <span class="font-bold">{{ "activity.genre-activity" | translate }}</span>
      <div class="flex gap-4">
        <span>{{ activity.codeGenreAct }}</span>
        <span>-</span>
        <span>{{ activity.libGenreAct }}</span>
      </div>
    </div>
    <div
      *ngIf="activity.codeMotifFinAct"
      class="flex flex-col gap-1">
      <span class="font-bold">{{ "activity.motif-fin-activity" | translate }}</span>
      <div class="flex gap-4">
        <span>{{ activity.codeMotifFinAct }}</span>
        <span>-</span>
        <span>{{ activity.libMotifFinAct }}</span>
      </div>
    </div>
  </div>
  <div
    *ngIf="activity.structure"
    class="mt-8">
    <span class="font-bold">{{ "structure.label" | translate }}</span>
    <arsb-card
      icon="pi pi-building"
      [label]="activity.structure.raisonSociale"
      [link]="['/', 'structure', activity.structure.id]"
      [date]="activity.structure.statutDate"
      [statut]="activity.structure.statut"></arsb-card>
  </div>
</p-card>
