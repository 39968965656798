import { GcsObject } from '@models/gcs-object'

export interface Mss extends GcsObject {
  niveauUsage: NIVEAU_USAGE
  mssAddress: string
}

export enum NIVEAU_USAGE {
  _0_NON_UTILISEE = '_0_NON_UTILISEE',
  _1_CONSULTEE_ALEATOIREMENT = '_1_CONSULTEE_ALEATOIREMENT',
  _2_CONSULTEE = '_2_CONSULTEE',
  _3_DEFAULT = '_3_DEFAULT',
  _4_PRINCIPALE = '_4_PRINCIPALE',
}
