import { Component } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import packageJson from '../../../../../package.json'

@Component({
  selector: 'arsb-footer',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  version = packageJson.version
}
