import { Component, Input, TemplateRef, ViewChild } from '@angular/core'
import { DatePipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { Statut } from '@models/gcs-object'

@Component({
  selector: 'arsb-card-information',
  standalone: true,
  imports: [DatePipe, TranslateModule, NgIf, NgTemplateOutlet, NgClass],
  templateUrl: './card-information.component.html',
  styleUrl: './card-information.component.scss',
})
export class CardInformationComponent {
  @Input() id?: string
  @Input() niveau?: string
  @Input() nationalStatut?: string
  @Input() nationalStatutDate?: Date
  @Input() regionalStatut?: string
  @Input() regionalStatutDate?: Date

  @ViewChild('right', { static: true }) right: TemplateRef<any> | null = null

  get isNationalAfterRegional(): boolean {
    return Boolean(
      this.nationalStatutDate && this.regionalStatutDate && this.nationalStatutDate > this.regionalStatutDate,
    )
  }

  get isDeactivated(): boolean {
    return Boolean(this.nationalStatut === Statut.DEACTIVATED || this.regionalStatut === Statut.DEACTIVATED)
  }
}
