{
  "name": "arsb-app",
  "version": "0.0.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.12",
    "@angular/compiler": "^17.3.12",
    "@angular/core": "^17.3.12",
    "@angular/forms": "^17.3.12",
    "@angular/platform-browser": "^17.3.12",
    "@angular/platform-browser-dynamic": "^17.3.12",
    "@angular/router": "^17.3.12",
    "@angular/service-worker": "^17.3.12",
    "@asymmetrik/ngx-leaflet": "^17.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "jwt-decode": "^3.1.2",
    "keycloak-angular": "^15.3.0",
    "keycloak-js": "^25.0.0",
    "leaflet": "^1.9.4",
    "ngx-infinite-scroll": "^17.0.1",
    "prettier": "^3.2.5",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.12",
    "@types/jasmine": "~5.1.0",
    "@types/leaflet": "^1.9.12",
    "autoprefixer": "^10.4.19",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.38",
    "tailwindcss": "^3.4.3",
    "typescript": "~5.4.2"
  }
}
