<form [formGroup]="form">
  <arsb-card-information
    *ngIf="exercisePro"
    [nationalStatut]="exercisePro.national?.statut"
    [nationalStatutDate]="exercisePro.national?.statutDate"
    [regionalStatut]="exercisePro.regional?.statut"
    [regionalStatutDate]="exercisePro.regional?.statutDate">
  </arsb-card-information>

  <div class="flex flex-col gap-2">
    <div class="flex flex-col">
      <label for="codeProf">
        {{ "exercise-pro.profession" | translate }}
      </label>
      <div
        class="gap-4 grid"
        [ngClass]="{ 'grid-cols-2': !Boolean(exercisePro?.national) }">
        <arsb-input
          [value]="exercisePro?.national?.libProf"
          [hasSetNullButton]="false"
          (updateValue)="form.controls['codeProf'].setValue(exercisePro?.national?.codeProf)" />
        <p-dropdown
          #codeProf
          *ngIf="!Boolean(exercisePro?.national)"
          inputId="codeProf"
          styleClass="w-full"
          [options]="nosValues['codeProf']"
          optionLabel="libelle"
          optionValue="code"
          formControlName="codeProf"
          filter="true"
          [filterFields]="['code', 'libelle']"
          [showClear]="true"
          (onShow)="codeProf.filterValue = null" />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="codeCatProf">
        {{ "exercise-pro.category-profession" | translate }}
      </label>
      <div
        class="gap-4 grid"
        [ngClass]="{ 'grid-cols-2': !Boolean(exercisePro?.national) }">
        <arsb-input
          [value]="exercisePro?.national?.libCatProf"
          [hasSetNullButton]="false"
          (updateValue)="form.controls['codeCatProf'].setValue(exercisePro?.national?.codeCatProf)" />
        <p-dropdown
          #codeCatProf
          *ngIf="!Boolean(exercisePro?.national)"
          inputId="codeCatProf"
          styleClass="w-full"
          [options]="nosValues['codeCatProf']"
          optionLabel="libelle"
          optionValue="code"
          formControlName="codeCatProf"
          filter="true"
          [filterFields]="['code', 'libelle']"
          [showClear]="true"
          (onShow)="codeCatProf.filterValue = null" />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="codeCiv">
        {{ "exercise-pro.civ" | translate }}
      </label>
      <div class="gap-4 grid grid-cols-2">
        <arsb-input
          [value]="exercisePro?.national?.libCiv"
          [hasSetNullButton]="false"
          (updateValue)="form.controls['codeCiv'].setValue(exercisePro?.national?.codeCiv)" />
        <p-dropdown
          #codeCiv
          inputId="codeCiv"
          styleClass="w-full"
          [options]="nosValues['codeCiv']"
          optionLabel="libelle"
          optionValue="code"
          formControlName="codeCiv"
          filter="true"
          [filterFields]="['code', 'libelle']"
          [showClear]="true"
          (onShow)="codeCiv.filterValue = null" />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="nom">
        {{ "exercise-pro.nom" | translate }}
      </label>
      <div class="gap-4 grid grid-cols-2">
        <arsb-input
          [value]="exercisePro?.national?.nom"
          [hasUpdateButton]="!Boolean(person?.nomUsage)"
          [hasSetNullButton]="!Boolean(person?.nomUsage)"
          (updateValue)="form.controls['nom'].setValue($event)"
          (setNullValue)="form.controls['nom'].setValue('')" />
        <input
          id="nom"
          pInputText
          formControlName="nom"
          arsbText />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="prenom">
        {{ "exercise-pro.prenom" | translate }}
      </label>
      <div class="gap-4 grid grid-cols-2">
        <arsb-input
          [value]="exercisePro?.national?.prenom"
          [hasUpdateButton]="!Boolean(person?.prenomUsage)"
          [hasSetNullButton]="!Boolean(person?.prenomUsage)"
          (updateValue)="form.controls['prenom'].setValue($event)"
          (setNullValue)="form.controls['prenom'].setValue('')" />
        <input
          id="prenom"
          pInputText
          formControlName="prenom" />
      </div>
    </div>
  </div>

  <div class="mt-8 flex justify-end gap-4">
    <button
      pButton
      type="button"
      [label]="'common.cancel' | translate"
      [outlined]="true"
      (click)="ref.close()"></button>
    <button
      pButton
      type="button"
      [label]="'common.save' | translate"
      [disabled]="form.invalid"
      (click)="save()"></button>
  </div>
</form>
