import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { ButtonDirective } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { SliderModule } from 'primeng/slider'
import { FormsModule } from '@angular/forms'
import { AddressService } from '@services/address.service'
import { SimpleQueryParams } from '@module/home/models/search'
import { MessageService } from 'primeng/api'

@Component({
  selector: 'arsb-search-simple',
  standalone: true,
  imports: [ButtonDirective, InputTextModule, TranslateModule, SliderModule, FormsModule],
  templateUrl: './search-simple.component.html',
  styleUrl: './search-simple.component.scss',
})
export class SearchSimpleComponent implements OnChanges {
  private readonly messageService = inject(MessageService)
  private readonly translate = inject(TranslateService)

  private readonly addressService = inject(AddressService)

  @Input() queryParams?: SimpleQueryParams

  @Output() search = new EventEmitter<{
    searchText: string
    address: string
    latitude: number
    longitude: number
    distanceMeters: number
  }>()

  values: {
    query: string
    location: { address: string; latitude: number; longitude: number }
    range: number
  } = {
    query: '',
    location: { address: '', latitude: 0, longitude: 0 },
    range: 20,
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['queryParams'] && changes['queryParams'].currentValue) {
      this.values.query = changes['queryParams'].currentValue.query
      this.values.location = {
        address: changes['queryParams'].currentValue.address,
        latitude: changes['queryParams'].currentValue.latitude ?? 0,
        longitude: changes['queryParams'].currentValue.longitude ?? 0,
      }
      this.values.range = changes['queryParams'].currentValue.range ?? 20
    }
  }

  searchSimple() {
    if (!this.values.location.address) {
      this.messageService.add({
        severity: 'error',
        summary: this.translate.instant('common.error'),
        detail: this.translate.instant('error.missing-address'),
      })
      return
    }

    if (this.values.location.latitude === 0 && this.values.location.longitude === 0) {
      this.addressService.getAddresses(this.values.location.address).subscribe((addresses) => {
        this.values.location.latitude = addresses[0].geometry.coordinates[1]
        this.values.location.longitude = addresses[0].geometry.coordinates[0]
        this.emit()
      })
    } else {
      this.emit()
    }
  }

  private emit() {
    this.search.emit({
      searchText: this.values.query,
      address: this.values.location.address,
      latitude: this.values.location.latitude,
      longitude: this.values.location.longitude,
      distanceMeters: this.values.range * 1000,
    })
  }

  setCoordinates() {
    this.addressService.getAddresses(this.values.location.address).subscribe((addresses) => {
      this.values.location.latitude = addresses[0].geometry.coordinates[1]
      this.values.location.longitude = addresses[0].geometry.coordinates[0]
    })
  }
}
