import { Component, inject } from '@angular/core'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ButtonDirective } from 'primeng/button'
import { DropdownModule } from 'primeng/dropdown'
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { Mss, NIVEAU_USAGE } from '@models/mss'
import { InputComponent } from '@shared/components/input/input.component'
import { InputTextModule } from 'primeng/inputtext'

@Component({
  selector: 'arsb-mss-dialog',
  standalone: true,
  imports: [
    AutoCompleteModule,
    ButtonDirective,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    InputComponent,
    InputTextModule,
  ],
  templateUrl: './mss-dialog.component.html',
  styleUrl: './mss-dialog.component.scss',
})
export class MssDialogComponent {
  protected readonly ref = inject(DynamicDialogRef)
  private readonly config = inject(DynamicDialogConfig)
  private readonly formBuilder = inject(FormBuilder)
  private readonly translate = inject(TranslateService)

  mss?: Mss = this.config.data
  niveauxUsage = Object.values(NIVEAU_USAGE).map((niveau) => ({
    label: this.translate.instant('mss.niveau.' + niveau),
    value: niveau,
  }))

  form = this.formBuilder.group({
    mssAddress: [
      {
        value: this.mss?.mssAddress,
        disabled: this.mss !== undefined,
      },
      [Validators.required, Validators.email],
    ],
    niveauUsage: [this.mss?.niveauUsage, [Validators.required]],
  })

  save() {
    this.ref.close(this.form.value)
  }
}
