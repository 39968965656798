import { Component, inject, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { ExercisePro } from '@models/exercise-pro'
import { ButtonDirective } from 'primeng/button'
import { TranslateModule } from '@ngx-translate/core'
import { Activity, FicheActivity } from '@models/activity'
import { Fields } from '@models/field'
import { Nos } from '@models/nos'
import { CardInformationComponent } from '@shared/components/card-information/card-information.component'
import { DropdownModule } from 'primeng/dropdown'
import { InputComponent } from '@shared/components/input/input.component'
import { NgForOf, NgIf } from '@angular/common'
import { StructureService } from '@services/structure.service'
import { FicheStructure, Structure } from '@models/structure'
import { CardComponent } from '@shared/components/card/card.component'
import { CardModule } from 'primeng/card'
import { AutoCompleteCompleteEvent, AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete'
import { CoordinatesComponent } from '@shared/components/coordinates/coordinates.component'
import { handleAddress, handleText } from '@shared/utils/value-form'
import { Coord } from '@models/coord'

@Component({
  selector: 'arsb-new-activity',
  standalone: true,
  imports: [
    ButtonDirective,
    TranslateModule,
    ReactiveFormsModule,
    CardInformationComponent,
    DropdownModule,
    InputComponent,
    NgIf,
    CardComponent,
    CardModule,
    AutoCompleteModule,
    NgForOf,
    CoordinatesComponent,
  ],
  templateUrl: './new-activity.component.html',
  styleUrl: './new-activity.component.scss',
})
export class NewActivityComponent implements OnInit {
  protected readonly ref = inject(DynamicDialogRef)
  private readonly config = inject(DynamicDialogConfig)
  private readonly formBuilder = inject(FormBuilder)

  private readonly structureService = inject(StructureService)

  exercisePro: ExercisePro = this.config.data.exercisePro
  activity?: FicheActivity = this.config.data.activity
  fields: Fields = this.config.data.fields
  nosValues: { [key: string]: Nos[] } = this.config.data.nosValues
  form = this.formBuilder.group({
    typeIdpp: [this.activity?.regional?.typeIdpp],
    idPp: [this.activity?.regional?.idPp],
    codeFonction: [
      this.activity?.regional?.codeFonction,
      this.activity?.national?.codeFonction ? [] : [Validators.required],
    ],
    libFonction: [this.activity?.regional?.libFonction],
    codeModeExercice: [
      this.activity?.regional?.codeModeExercice,
      this.activity?.national?.codeModeExercice ? [] : [Validators.required],
    ],
    libModeExercice: [this.activity?.regional?.libModeExercice],
    codeGenreAct: [
      this.activity?.regional?.codeGenreAct,
      this.activity?.national?.codeGenreAct ? [] : [Validators.required],
    ],
    libGenreAct: [this.activity?.regional?.libGenreAct],
    codeMotifFinAct: [this.activity?.regional?.codeMotifFinAct],
    libMotifFinAct: [this.activity?.regional?.libMotifFinAct],
    personId: [this.activity?.regional?.personId ?? this.exercisePro.person.id, Validators.required],
    codeCatProf: [this.activity?.regional?.codeCatProf ?? this.exercisePro.codeCatProf, Validators.required],
    libCatProf: [this.activity?.regional?.libCatProf ?? this.exercisePro.libCatProf, Validators.required],
    codeProf: [this.activity?.regional?.codeProf ?? this.exercisePro.codeProf, Validators.required],
    libProf: [this.activity?.regional?.libProf ?? this.exercisePro.libProf, Validators.required],
    structure: this.formBuilder.group({
      id: [this.activity?.regional?.structure?.id, this.activity?.national?.structure?.id ? [] : [Validators.required]],
      raisonSociale: [this.activity?.regional?.structure?.raisonSociale],
    }),
    exercisePro: this.formBuilder.group({
      id: [this.exercisePro.id],
    }),
    activityCoord: this.formBuilder.group({
      complDest: [this.activity?.regional?.activityCoord?.complDest, [Validators.maxLength(38)]],
      complGeo: [this.activity?.regional?.activityCoord?.complGeo, [Validators.maxLength(38)]],
      numVoie: [this.activity?.regional?.activityCoord?.numVoie],
      indiceRepVoie: [this.activity?.regional?.activityCoord?.indiceRepVoie],
      libTypeVoie: [this.activity?.regional?.activityCoord?.libTypeVoie],
      libVoie: [this.activity?.regional?.activityCoord?.libVoie],
      mentionDistVoie: [this.activity?.regional?.activityCoord?.mentionDistVoie],
      bureauCedex: [this.activity?.regional?.activityCoord?.bureauCedex],
      codePostal: [
        this.activity?.regional?.activityCoord?.codePostal?.replace(/^(\d{2})(\d{3})/, '$1 $2'),
        [Validators.pattern('^[0-9]{2} [0-9]{3}$')],
      ],
      libCommune: [this.activity?.regional?.activityCoord?.libCommune],
      codeCommune: [
        this.activity?.regional?.activityCoord?.codeCommune?.replace(/^([A-B\d]{2})(\d{3})/, '$1 $2'),
        [Validators.pattern('^[0-9][A-B0-9] [0-9]{3}$')],
      ],
      tel: [
        this.activity?.regional?.activityCoord?.tel?.replace(/(.{2})/g, '$1 '),
        [Validators.minLength(14), Validators.maxLength(14), Validators.pattern('^0[0-9]( [0-9]{2}){4}$')],
      ],
      fax: [
        this.activity?.regional?.activityCoord?.fax?.replace(/(.{2})/g, '$1 '),
        [Validators.minLength(14), Validators.maxLength(14), Validators.pattern('^0[0-9]( [0-9]{2}){4}$')],
      ],
      email: [this.activity?.regional?.activityCoord?.email, [Validators.email]],
      portable: [
        this.activity?.regional?.activityCoord?.portable?.replace(/(.{2})/g, '$1 '),
        [Validators.minLength(14), Validators.maxLength(14), Validators.pattern('^0[0-9]( [0-9]{2}){4}$')],
      ],
      telOther: [
        this.activity?.regional?.activityCoord?.telOther?.replace(/(.{2})/g, '$1 '),
        [Validators.minLength(14), Validators.maxLength(14), Validators.pattern('^0[0-9]( [0-9]{2}){4}$')],
      ],
      latitude: [this.activity?.regional?.activityCoord?.latitude],
      longitude: [this.activity?.regional?.activityCoord?.longitude],
    }),
  })

  structure?: FicheStructure
  suggestedStructures: Structure[] = []

  ngOnInit() {
    if (this.activity?.national?.structure?.id) {
      this.structureService.getFicheStructure(this.activity?.national?.structure?.id).subscribe((structure) => {
        this.structure = structure
      })
    }
  }

  save() {
    if (this.form.invalid) return

    const activity: Activity = {
      ...(this.form.value as unknown as Activity),
      activityCoord: {
        ...this.form?.get('activityCoord')?.value,
        complDest: handleText(this.form?.get('activityCoord')?.get('complDest')!),
        complGeo: handleText(this.form?.get('activityCoord')?.get('complGeo')!),
        numVoie: handleText(this.form?.get('activityCoord')?.get('numVoie')!),
        indiceRepVoie: handleText(this.form?.get('activityCoord')?.get('indiceRepVoie')!),
        libTypeVoie: handleText(this.form?.get('activityCoord')?.get('libTypeVoie')!),
        libVoie: handleText(this.form?.get('activityCoord')?.get('libVoie')!),
        mentionDistVoie: handleText(this.form?.get('activityCoord')?.get('mentionDistVoie')!),
        bureauCedex: handleText(this.form?.get('activityCoord')?.get('bureauCedex')!),
        adresse: handleAddress(
          this.form?.controls['activityCoord'] as FormGroup,
          this.activity?.national?.activityCoord,
        ),
        codePostal: this.form?.get('activityCoord')?.get('codePostal')?.value?.replace(/ /g, '')!,
        codeCommune: this.form?.get('activityCoord')?.get('codeCommune')?.value?.replace(/ /g, '')!,
        tel: this.form?.get('activityCoord')?.get('tel')?.value?.replace(/ /g, '')!,
        portable: this.form?.get('activityCoord')?.get('portable')?.value?.replace(/ /g, '')!,
        fax: this.form?.get('activityCoord')?.get('fax')?.value?.replace(/ /g, '')!,
        telOther: this.form?.get('activityCoord')?.get('telOther')?.value?.replace(/ /g, '')!,
        email: handleText(this.form?.get('activityCoord')?.get('email')!, {
          unaccent: false,
          lowercase: true,
        }),
      } as Coord,
    }

    if (activity.typeIdpp && this.nosValues['typeIdpp']?.some((nos) => nos.code === activity.typeIdpp)) {
      activity.idPp = this.nosValues['typeIdpp'].find((nos) => nos.code === activity.typeIdpp)!.libelle
    }
    if (activity.codeFonction && this.nosValues['codeFonction']?.some((nos) => nos.code === activity.codeFonction)) {
      activity.libFonction = this.nosValues['codeFonction'].find((nos) => nos.code === activity.codeFonction)!.libelle
    }
    if (
      activity.codeModeExercice &&
      this.nosValues['codeModeExercice']?.some((nos) => nos.code === activity.codeModeExercice)
    ) {
      activity.libModeExercice = this.nosValues['codeModeExercice'].find(
        (nos) => nos.code === activity.codeModeExercice,
      )!.libelle
    }
    if (activity.codeGenreAct && this.nosValues['codeGenreAct']?.some((nos) => nos.code === activity.codeGenreAct)) {
      activity.libGenreAct = this.nosValues['codeGenreAct'].find((nos) => nos.code === activity.codeGenreAct)!.libelle
    }
    if (
      activity.codeMotifFinAct &&
      this.nosValues['codeMotifFinAct']?.some((nos) => nos.code === activity.codeMotifFinAct)
    ) {
      activity.libMotifFinAct = this.nosValues['codeMotifFinAct'].find(
        (nos) => nos.code === activity.codeMotifFinAct,
      )!.libelle
    }

    if (activity.structure && !activity.structure.id && this.structure) {
      activity.structure.id = this.structure.id
    }

    this.ref.close(activity)
  }

  researchStructures(event: AutoCompleteCompleteEvent) {
    if (event.query.length < 3) return

    // TODO utiliser un nouvel endpoint pour rechercher les structures
    // this.structureService.search(0, event.query).subscribe((structures) => {
    //   this.suggestedStructures = structures.content
    // })
  }

  selectStructure(event: AutoCompleteSelectEvent) {
    this.form.get('structure')!.setValue({ id: event.value.id, raisonSociale: event.value.raisonSociale })
  }
}
