import { Component, inject, OnInit } from '@angular/core'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api'
import { StructureService } from '@services/structure.service'
import { ActivityStructure, FicheStructure, Structure, StructureParent, TypeStructure } from '@models/structure'
import { DatePipe, LowerCasePipe, NgClass, NgForOf, NgIf, TitleCasePipe } from '@angular/common'
import { NosService } from '@services/nos.service'
import { forkJoin, switchMap, tap } from 'rxjs'
import { Nos } from '@models/nos'
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { InputComponent } from '@shared/components/input/input.component'
import { InputTextModule } from 'primeng/inputtext'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { DropdownModule } from 'primeng/dropdown'
import { ButtonModule } from 'primeng/button'
import { CalendarModule } from 'primeng/calendar'
import { CardInformationComponent } from '@shared/components/card-information/card-information.component'
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch'
import { Statut } from '@models/gcs-object'
import { CardComponent } from '@shared/components/card/card.component'
import { Fields } from '@models/field'
import { DialogService } from 'primeng/dynamicdialog'
import { LinkStructureDialogComponent } from '@module/structure/dialogs/link-structure-dialog/link-structure-dialog.component'
import { PhoneDirective } from '@shared/directives/phone.directive'
import { getFieldsNos } from '@shared/utils/fields-nos'
import { CoordinatesComponent } from '@shared/components/coordinates/coordinates.component'
import { FormComponentComponent } from '@shared/components/form-component/form-component.component'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { getLabelPlaceholder, handleAddress, handleText, setValueToNull } from '@shared/utils/value-form'
import { hasMaxLengthError } from '@shared/utils/error-form'
import { Mss } from '@models/mss'
import { MssDialogComponent } from '@shared/dialogs/mss-dialog/mss-dialog.component'
import { ComplementsComponent } from '@shared/components/complements/complements.component'
import { MssService } from '@services/mss.service'

@Component({
  standalone: true,
  imports: [
    BreadcrumbModule,
    TranslateModule,
    NgIf,
    ReactiveFormsModule,
    InputComponent,
    InputTextModule,
    NgForOf,
    LeafletModule,
    AutoCompleteModule,
    DropdownModule,
    ButtonModule,
    CalendarModule,
    TitleCasePipe,
    DatePipe,
    CardInformationComponent,
    InputSwitchModule,
    FormsModule,
    CardComponent,
    PhoneDirective,
    CoordinatesComponent,
    FormComponentComponent,
    LowerCasePipe,
    NgClass,
    OverlayPanelModule,
    ComplementsComponent,
  ],
  templateUrl: './structure.component.html',
  styleUrl: './structure.component.scss',
})
export class StructureComponent implements OnInit {
  private readonly route = inject(ActivatedRoute)
  private readonly translate = inject(TranslateService)
  private readonly formBuilder = inject(FormBuilder)
  private readonly messageService = inject(MessageService)
  private readonly confirmationService = inject(ConfirmationService)
  private readonly router = inject(Router)
  private readonly dialogService = inject(DialogService)

  private readonly structureService = inject(StructureService)
  private readonly mssService = inject(MssService)
  private readonly nosService = inject(NosService)

  protected readonly setValueToNull = setValueToNull
  protected readonly getLabelPlaceholder = getLabelPlaceholder
  protected readonly hasMaxLengthError = hasMaxLengthError
  protected readonly Boolean = Boolean

  id: string = this.route.snapshot.params['id']
  ficheStructure?: FicheStructure
  fieldsStructure?: Fields
  nosValues: { [key: string]: Nos[] } = {}

  breadcrumbItems: MenuItem[] = []
  homeItem: MenuItem = { icon: 'pi pi-home', routerLink: '/' }
  types: { label: string; value: string }[] = []
  formStructure?: FormGroup

  showAllActivities = false
  showDisabledActivities = false
  showAllMsses = false
  showDisabledMsses = false
  sortFieldActivities: 'nom' | 'libProf' = 'nom'

  ngOnInit() {
    this.translate.get('structure.label').subscribe((label) => {
      this.breadcrumbItems = [{ label: label }, { label: this.id }]
    })

    const keys = Object.keys(TypeStructure)
    this.translate.get(keys.map((key) => 'structure.' + key.toLowerCase())).subscribe((values) => {
      keys.forEach((key) => {
        this.types.push({ label: values['structure.' + key.toLowerCase()], value: key })
      })
    })

    this.route.params.subscribe((params) => {
      this.id = params['id']
      this.setStructure()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    })
  }

  private setStructure() {
    if (this.id === 'new') {
      this.setStructureFields().subscribe(({ fields, nosValues }) => {
        this.fieldsStructure = fields
        this.nosValues = nosValues
        this.setFormStructure()
      })
      return
    } else {
      forkJoin([this.setStructureFields(), this.structureService.getFicheStructure(this.id)])
        .pipe(
          tap(([{ fields, nosValues }, ficheStructure]) => {
            this.fieldsStructure = fields
            this.nosValues = nosValues
            this.ficheStructure = ficheStructure
          }),
        )
        .subscribe((_) => {
          this.setFormStructure()
        })
    }
  }

  private setStructureFields() {
    return getFieldsNos(
      forkJoin([
        this.structureService.getStructureFields(),
        this.structureService.getStructureFields('structureCoord'),
      ]),
      this.nosService,
      [null, 'structureCoord'],
    )
  }

  private setFormStructure() {
    const structure = this.ficheStructure?.regional
    this.formStructure = this.formBuilder.group({
      disableStructure: [structure?.statut === Statut.DEACTIVATED],
      raisonSociale: [
        {
          value: structure?.raisonSociale,
          disabled:
            !this.fieldsStructure!['raisonSociale']?.isSurchargeable || structure?.statut === Statut.DEACTIVATED,
        },
      ],
      enseigneCommerciale: [
        {
          value: structure?.enseigneCommerciale,
          disabled:
            !this.fieldsStructure!['enseigneCommerciale']?.isSurchargeable || structure?.statut === Statut.DEACTIVATED,
        },
      ],
      nomUsage: [
        {
          value: structure?.nomUsage,
          disabled: !this.fieldsStructure!['nomUsage']?.isSurchargeable || structure?.statut === Statut.DEACTIVATED,
        },
      ],
      siret: [
        {
          value: structure?.siret,
          disabled: !this.fieldsStructure!['siret']?.isSurchargeable || structure?.statut === Statut.DEACTIVATED,
        },
        [Validators.maxLength(14)],
      ],
      siren: [
        {
          value: structure?.siren,
          disabled: !this.fieldsStructure!['siren']?.isSurchargeable || structure?.statut === Statut.DEACTIVATED,
        },
        [Validators.maxLength(9)],
      ],
      rna: [
        {
          value: structure?.rna,
          disabled: !this.fieldsStructure!['rna']?.isSurchargeable || structure?.statut === Statut.DEACTIVATED,
        },
        [Validators.maxLength(20)],
      ],
      secteurActiviteCode: [
        {
          value: structure?.secteurActiviteCode,
          disabled:
            !this.fieldsStructure!['secteurActiviteCode']?.isSurchargeable || structure?.statut === Statut.DEACTIVATED,
        },
      ],
      categorieEtablissementCode: [
        {
          value: structure?.categorieEtablissementCode,
          disabled:
            !this.fieldsStructure!['categorieEtablissementCode']?.isSurchargeable ||
            structure?.statut === Statut.DEACTIVATED,
        },
      ],
      categorieAgrEtablissementCode: [
        {
          value: structure?.categorieAgrEtablissementCode,
          disabled:
            !this.fieldsStructure!['categorieAgrEtablissementCode']?.isSurchargeable ||
            structure?.statut === Statut.DEACTIVATED,
        },
      ],
      structureCoord: this.formBuilder.group({
        complDest: [
          {
            value: structure?.structureCoord?.complDest,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.maxLength(38)],
        ],
        complGeo: [
          {
            value: structure?.structureCoord?.complGeo,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.maxLength(38)],
        ],
        numVoie: [
          {
            value: structure?.structureCoord?.numVoie,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        indiceRepVoie: [
          {
            value: structure?.structureCoord?.indiceRepVoie,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        libTypeVoie: [
          {
            value: structure?.structureCoord?.libTypeVoie,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        libVoie: [
          {
            value: structure?.structureCoord?.libVoie,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        mentionDistVoie: [
          {
            value: structure?.structureCoord?.mentionDistVoie,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        bureauCedex: [
          {
            value: structure?.structureCoord?.bureauCedex,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        codePostal: [
          {
            value: structure?.structureCoord?.codePostal,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^[0-9]{5}$')],
        ],
        libCommune: [
          {
            value: structure?.structureCoord?.libCommune,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        codeCommune: [
          {
            value: structure?.structureCoord?.codeCommune,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^[0-9][A-B0-9][0-9]{3}$')],
        ],
        tel: [
          {
            value: structure?.structureCoord?.tel,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        fax: [
          {
            value: structure?.structureCoord?.fax,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        email: [
          {
            value: structure?.structureCoord?.email,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.email],
        ],
        portable: [
          {
            value: structure?.structureCoord?.portable,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        telOther: [
          {
            value: structure?.structureCoord?.telOther,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
          [Validators.pattern('^0[0-9](?:[ .*]?[0-9]{2}){4}$')],
        ],
        latitude: [
          {
            value: structure?.structureCoord?.latitude,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
        longitude: [
          {
            value: structure?.structureCoord?.longitude,
            disabled: structure?.statut === Statut.DEACTIVATED,
          },
        ],
      }),
    })

    if (this.id === 'new') {
      this.formStructure.addControl('type', this.formBuilder.control(TypeStructure.EG, [Validators.required]))
      this.formStructure.addControl('finessEj', this.formBuilder.control(null, [Validators.maxLength(9)]))
      this.formStructure.addControl('finessEg', this.formBuilder.control(null, [Validators.maxLength(9)]))
      this.formStructure.addControl('siren', this.formBuilder.control(null, [Validators.maxLength(9)]))
      this.formStructure.addControl('siret', this.formBuilder.control(null, [Validators.maxLength(14)]))
      this.formStructure.controls['raisonSociale'].setValidators([Validators.required])
    } else if (this.type === 'eg' && !this.ficheStructure?.national?.finessEg) {
      this.formStructure.addControl(
        'finessEg',
        this.formBuilder.control(this.ficheStructure?.regional?.finessEg, [Validators.maxLength(9)]),
      )
    }
  }

  submit() {
    if (this.formStructure?.invalid) return

    const structure = {
      ...this.formStructure?.value,
      raisonSociale: handleText(this.formStructure?.get('raisonSociale')!),
      enseigneCommerciale: handleText(this.formStructure?.get('enseigneCommerciale')!),
      nomUsage: handleText(this.formStructure?.get('nomUsage')!),
      structureCoord: {
        ...this.formStructure?.get('structureCoord')?.value,
        complDest: handleText(this.formStructure?.get('structureCoord')?.get('complDest')!),
        complGeo: handleText(this.formStructure?.get('structureCoord')?.get('complGeo')!),
        numVoie: handleText(this.formStructure?.get('structureCoord')?.get('numVoie')!),
        indiceRepVoie: handleText(this.formStructure?.get('structureCoord')?.get('indiceRepVoie')!),
        libTypeVoie: handleText(this.formStructure?.get('structureCoord')?.get('libTypeVoie')!),
        libVoie: handleText(this.formStructure?.get('structureCoord')?.get('libVoie')!),
        mentionDistVoie: handleText(this.formStructure?.get('structureCoord')?.get('mentionDistVoie')!),
        bureauCedex: handleText(this.formStructure?.get('structureCoord')?.get('bureauCedex')!),
        adresse: handleAddress(
          this.formStructure?.controls['structureCoord'] as FormGroup,
          this.ficheStructure?.national?.structureCoord,
        ),
        codePostal: this.formStructure?.get('structureCoord')?.get('codePostal')?.value?.replace(/ /g, ''),
        codeCommune: this.formStructure?.get('structureCoord')?.get('codeCommune')?.value?.replace(/ /g, ''),
        tel: this.formStructure?.get('structureCoord')?.get('tel')?.value?.replace(/[ .*]/g, ''),
        portable: this.formStructure?.get('structureCoord')?.get('portable')?.value?.replace(/[ .*]/g, ''),
        fax: this.formStructure?.get('structureCoord')?.get('fax')?.value?.replace(/[ .*]/g, ''),
        telOther: this.formStructure?.get('structureCoord')?.get('telOther')?.value?.replace(/[ .*]/g, ''),
        email: handleText(this.formStructure?.get('structureCoord')?.get('email')!, {
          unaccent: false,
          lowercase: true,
        }),
      },
    } as Structure

    if (
      structure.secteurActiviteCode &&
      this.nosValues['secteurActiviteCode'].some((secteur) => secteur.code === structure.secteurActiviteCode)
    ) {
      structure.secteurActiviteLibelle = this.nosValues['secteurActiviteCode'].find(
        (secteur) => secteur.code === structure.secteurActiviteCode,
      )!.libelle
    }
    if (
      structure.categorieEtablissementCode &&
      this.nosValues['categorieEtablissementCode'].some(
        (categorie) => categorie.code === structure.categorieEtablissementCode,
      )
    ) {
      structure.categorieEtablissementLibelle = this.nosValues['categorieEtablissementCode'].find(
        (categorie) => categorie.code === structure.categorieEtablissementCode,
      )!.libelle
    }
    if (
      structure.categorieAgrEtablissementCode &&
      this.nosValues['categorieAgrEtablissementCode'].some(
        (categorie) => categorie.code === structure.categorieAgrEtablissementCode,
      )
    ) {
      structure.categorieAgrEtablissementLibelle = this.nosValues['categorieAgrEtablissementCode'].find(
        (categorie) => categorie.code === structure.categorieAgrEtablissementCode,
      )!.libelle
      structure.categorieAgrEtablissementLibelleCourt = this.nosValues['categorieAgrEtablissementCode'].find(
        (categorie) => categorie.code === structure.categorieAgrEtablissementCode,
      )!.libelleCourt
    }

    if (this.id === 'new') {
      this.structureService.createStructure(structure).subscribe({
        next: (structure) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('common.success'),
            detail: this.translate.instant('structure.created'),
          })
          this.router.navigate(['/', 'structure', structure.id])
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('common.error'),
            detail: this.translate.instant('common.errors.' + error.status),
          })
        },
      })
    } else {
      this.structureService.updateStructure(this.id, structure).subscribe({
        next: (structure) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('common.success'),
            detail: this.translate.instant('structure.updated'),
          })
          this.ficheStructure = { ...this.ficheStructure!, regional: structure.regional }
          this.setFormStructure()
        },
        error: (error) => {
          if (this.ficheStructure?.statut === Statut.DEACTIVATED) {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('common.error'),
              detail: this.translate.instant('common.errors.deactivated'),
            })
          } else {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('common.error'),
              detail: this.translate.instant('common.errors.' + error.status),
            })
          }
        },
      })
    }
  }

  askForDeactivateOrReactivateStructure(event: InputSwitchChangeEvent) {
    if (event.checked) {
      this.confirmationService.confirm({
        message: this.translate.instant('structure.confirm.disable'),
        header: this.translate.instant('common.deactivation'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => this.checkActivitiesLinked(),
        reject: () => this.formStructure?.get('disableStructure')?.setValue(false),
      })
    } else {
      if (this.ficheStructure?.activities?.some((activity) => activity.statut === Statut.DEACTIVATED)) {
        this.confirmationService.confirm({
          message: this.translate.instant('activity.deactivated.reactivation-parent'),
          header: this.translate.instant('common.reactivation'),
          icon: 'pi pi-exclamation-triangle',
          acceptIcon: 'none',
          rejectIcon: 'none',
          rejectButtonStyleClass: 'p-button-text',
          accept: () => this.activateStructure(),
          reject: () => this.formStructure?.get('disableStructure')?.setValue(true),
        })
      } else {
        this.activateStructure()
      }
    }
  }

  private activateStructure() {
    this.structureService.reactivateStructure(this.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('common.success'),
          detail: this.translate.instant('structure.reactivated'),
        })
        this.setStructure()
      },
      error: (error) => {
        this.formStructure?.get('disableStructure')?.setValue(true)
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('common.errors.' + error.status),
        })
      },
    })
  }

  private checkActivitiesLinked() {
    if (this.ficheStructure?.activities?.some((activity) => activity.statut !== Statut.DEACTIVATED)) {
      this.confirmationService.confirm({
        message: this.translate.instant('activity.deactivated.deactivation-parent', {
          parent: this.translate.instant('structure.structure').toLowerCase(),
          parentName: this.ficheStructure?.regional?.raisonSociale ?? this.ficheStructure?.national?.raisonSociale,
          number: this.ficheStructure?.activities?.filter((activity) => activity.statut !== Statut.DEACTIVATED).length,
        }),
        header: this.translate.instant('common.deactivation'),
        key: 'nested',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'none',
        rejectIcon: 'none',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => this.deactivateStructure(),
        reject: () => this.formStructure?.get('disableStructure')?.setValue(false),
      })
    } else {
      this.deactivateStructure()
    }
  }

  private deactivateStructure() {
    this.structureService.deactivateStructure(this.id).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('common.success'),
          detail: this.translate.instant('structure.disabled'),
        })
        this.setStructure()
      },
      error: (error) => {
        this.formStructure?.get('disableStructure')?.setValue(false)
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('common.errors.' + error.status),
        })
      },
    })
  }

  getControl(control: string) {
    return this.formStructure?.get(control) as FormControl
  }

  openLinkStructureModal() {
    const ref = this.dialogService.open(LinkStructureDialogComponent, {
      header: this.translate.instant('structure.link-relation'),
      width: '50%',
      data: {
        raisonSociale: this.ficheStructure?.regional?.raisonSociale ?? this.ficheStructure?.national?.raisonSociale,
      },
    })

    ref.onClose.subscribe((result) => {
      if (result) {
        this.structureService
          .activateStructureRelation(this.id, result.parentId, result.relation)
          .pipe(
            switchMap(() => this.structureService.getFicheStructure(this.id)),
            tap((ficheStructure) => {
              this.ficheStructure = ficheStructure
            }),
          )
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                summary: this.translate.instant('common.success'),
                detail: this.translate.instant('structure.linked'),
              })
            },
            error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: this.translate.instant('common.error'),
                detail: this.translate.instant('common.errors.' + error.status),
              })
            },
          })
      }
    })
  }

  openMssDialog(mss?: Mss) {
    const ref = this.dialogService.open(MssDialogComponent, {
      header: this.translate.instant('mss.title'),
      width: '50%',
      height: '50%',
      data: mss,
    })

    ref.onClose.subscribe((result) => {
      if (result) {
        if (mss) {
          this.mssService.updateMss('structure', this.id, mss.id, result).subscribe((mss) => {
            const index = this.ficheStructure?.msses?.findIndex((m) => m.id === mss.id)
            if (index !== -1) {
              this.ficheStructure!.msses![index!] = mss
            }

            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('common.success'),
              detail: this.translate.instant('mss.updated'),
            })
          })
        } else {
          this.mssService.addMss('structure', this.id, result).subscribe((mss) => {
            this.ficheStructure = { ...this.ficheStructure!, msses: [...(this.ficheStructure?.msses ?? []), mss] }
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('common.success'),
              detail: this.translate.instant('mss.added'),
            })
          })
        }
      }
    })
  }

  askDeactivateLinkStructure(parent: StructureParent) {
    this.confirmationService.confirm({
      message: this.translate.instant('structure.confirm.disable'),
      header: this.translate.instant('common.confirm'),
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: 'none',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => this.deactivateLinkStructure(parent),
    })
  }

  private deactivateLinkStructure(parent: StructureParent) {
    this.structureService
      .deactivateStructureRelation(this.id, parent.id.parentStructureId, parent.id.relationType)
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translate.instant('common.success'),
            detail: this.translate.instant('structure.disabled'),
          })
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('common.error'),
            detail: this.translate.instant('common.errors.' + error.status),
          })
        },
      })
  }

  get activities(): ActivityStructure[] {
    if (this.showDisabledActivities) {
      return (
        this.ficheStructure?.activities
          ?.sort((a, b) => {
            if (!a[this.sortFieldActivities]) return 1
            if (!b[this.sortFieldActivities]) return -1
            return a[this.sortFieldActivities].localeCompare(b[this.sortFieldActivities])
          })
          .slice(0, this.showAllActivities ? Infinity : 3) ?? []
      )
    }

    return (
      this.ficheStructure?.activities
        ?.filter((activity) => activity.statut !== Statut.DEACTIVATED)
        .sort((a, b) => {
          if (!a[this.sortFieldActivities]) return 1
          if (!b[this.sortFieldActivities]) return -1
          return a[this.sortFieldActivities].localeCompare(b[this.sortFieldActivities])
        })
        .slice(0, this.showAllActivities ? Infinity : 3) ?? []
    )
  }

  get msses() {
    if (this.showDisabledMsses) {
      return this.ficheStructure?.msses
        ?.sort((a, b) => {
          if (a.statut === Statut.DEACTIVATED && b.statut !== Statut.DEACTIVATED) {
            return 1
          } else if (a.statut !== Statut.DEACTIVATED && b.statut === Statut.DEACTIVATED) {
            return -1
          } else {
            return Number.parseInt(b.niveauUsage.slice(1, 2)) - Number.parseInt(a.niveauUsage.slice(1, 2))
          }
        })
        .slice(0, this.showAllMsses ? Infinity : 3)
    }

    return this.ficheStructure?.msses
      ?.filter((mss) => mss.statut !== Statut.DEACTIVATED)
      .sort((a, b) => Number.parseInt(b.niveauUsage.slice(1, 2)) - Number.parseInt(a.niveauUsage.slice(1, 2)))
      .slice(0, this.showAllMsses ? Infinity : 3)
  }

  deactivateMss(mss: Mss) {
    this.mssService.deactivateMss(mss.id).subscribe((mss) => {
      const index = this.ficheStructure?.msses?.findIndex((m) => m.id === mss.id)
      if (index !== -1) {
        this.ficheStructure!.msses![index!] = mss
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('common.success'),
        detail: this.translate.instant('mss.deactivated'),
      })
    })
  }

  activateMss(mss: Mss) {
    this.mssService.activateMss(mss.id).subscribe((mss) => {
      const index = this.ficheStructure?.msses?.findIndex((m) => m.id === mss.id)
      if (index !== -1) {
        this.ficheStructure!.msses![index!] = mss
      }

      this.messageService.add({
        severity: 'success',
        summary: this.translate.instant('common.success'),
        detail: this.translate.instant('mss.activated'),
      })
    })
  }

  resetForm() {
    this.formStructure?.reset(this.ficheStructure?.regional)
    this.messageService.add({
      severity: 'info',
      summary: this.translate.instant('common.info'),
      detail: this.translate.instant('form.reset'),
    })
  }

  get type() {
    return this.ficheStructure?.regional?.type?.toLowerCase() ?? this.ficheStructure?.national?.type?.toLowerCase()
  }
}
