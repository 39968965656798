<div
  *ngIf="fusion"
  class="flex flex-col gap-3">
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "structure.finess-ej" | translate }}</span>
      <arsb-fusion-input-button
        [value]="fusion.finessEj"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'finessEj', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "structure.finess-eg" | translate }}</span>
      <arsb-fusion-input-button
        [value]="fusion.finessEg"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'finessEg', value: $event })" />
    </div>
  </div>
  <div class="grid xl:grid-cols-2 gap-4">
    <div class="flex flex-col">
      <span>{{ "structure.siret" | translate }}</span>
      <arsb-fusion-input-button
        [value]="fusion.siret"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'siret', value: $event })" />
    </div>
    <div class="flex flex-col">
      <span>{{ "structure.siren" | translate }}</span>
      <arsb-fusion-input-button
        [value]="fusion.siren"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'siren', value: $event })" />
    </div>
  </div>
  <div class="grid xl:grid-cols-3 gap-4">
    <div class="flex flex-col">
      <span>{{ "structure.rna" | translate }}</span>
      <arsb-fusion-input-button
        [value]="fusion.rna"
        [buttonRight]="!rightSide"
        (setValue)="setValue.emit({ control: 'rna', value: $event })" />
    </div>
  </div>
  <div class="flex flex-col">
    <span>{{ "structure.social-reason" | translate }}</span>
    <arsb-fusion-input-button
      [value]="fusion.raisonSociale"
      [buttonRight]="!rightSide"
      (setValue)="setValue.emit({ control: 'raisonSociale', value: $event })" />
  </div>
  <div class="flex flex-col">
    <span>{{ "structure.brand" | translate }}</span>
    <arsb-fusion-input-button
      [value]="fusion.enseigneCommerciale"
      [buttonRight]="!rightSide"
      (setValue)="setValue.emit({ control: 'enseigneCommerciale', value: $event })" />
  </div>
  <div class="flex flex-col">
    <span>{{ "structure.label-usage" | translate }}</span>
    <arsb-fusion-input-button
      [value]="fusion.nomUsage"
      [buttonRight]="!rightSide"
      (setValue)="setValue.emit({ control: 'nomUsage', value: $event })" />
  </div>
  <div class="flex flex-col">
    <span>{{ "structure.sector-activity" | translate }}</span>
    <arsb-fusion-input-button
      [value]="fusion.secteurActiviteLibelle"
      [buttonRight]="!rightSide"
      (setValue)="setValue.emit({ control: 'secteurActiviteCode', value: fusion.secteurActiviteCode })" />
  </div>
  <div class="flex flex-col">
    <span>{{ "structure.category" | translate }}</span>
    <arsb-fusion-input-button
      [value]="fusion.categorieEtablissementLibelle"
      [buttonRight]="!rightSide"
      (setValue)="setValue.emit({ control: 'categorieEtablissementCode', value: fusion.categorieEtablissementCode })" />
  </div>
  <div class="flex flex-col">
    <span>{{ "structure.aggregate-category" | translate }}</span>
    <arsb-fusion-input-button
      [value]="fusion.categorieAgrEtablissementLibelle"
      [buttonRight]="!rightSide"
      (setValue)="
        setValue.emit({ control: 'categorieAgrEtablissementCode', value: fusion.categorieAgrEtablissementCode })
      " />
  </div>
  <hr class="border-2 my-4" />
  <arsb-side-coord-fusion
    *ngIf="fusion.structureCoord"
    [rightSide]="rightSide"
    [coord]="fusion.structureCoord"
    (setValue)="setValue.emit({ control: $event.control, value: $event.value, subGroup: 'structureCoord' })" />
</div>
