import { forkJoin, map, Observable, switchMap } from 'rxjs'
import { Field, Fields } from '@models/field'
import { NosService } from '@services/nos.service'

export function getFieldsNos(
  obs: Observable<any>,
  nosService: NosService,
  fieldsName: (string | null)[] = [null],
): Observable<{
  fields: Fields
  nosValues: { [key: string]: any[] }
}> {
  let fields: Fields = {}
  let fieldsWithNos: { [key: string]: { length: number; nosTypes: any[] } } = {}
  let nosValues: { [key: string]: any[] } = {}

  return obs.pipe(
    switchMap((fieldsToHandle) => {
      fields = {}

      if (Array.isArray(fieldsToHandle) && fieldsToHandle.length > 0 && Array.isArray(fieldsToHandle[0])) {
        fieldsToHandle.forEach((fieldToHandle: Field[], i) => {
          if (fieldsName[i] !== null) {
            fields[fieldsName[i]!] = {}
          }
          for (const field of fieldToHandle) {
            if (fieldsName[i] !== null) {
              ;(fields[fieldsName[i]!] as { [key: string]: Field })[field.fieldName] = field
            } else {
              fields[field.fieldName] = field
            }
            if (field.nosCodeTypes && field.nosCodeTypes.length) {
              fieldsWithNos[field.fieldName] = { length: field.nosCodeTypes.length, nosTypes: field.nosCodeTypes }
            }
          }
        })
      } else {
        for (const field of fieldsToHandle) {
          fields[field.fieldName] = field
          if (field.nosCodeTypes && field.nosCodeTypes.length) {
            fieldsWithNos[field.fieldName] = { length: field.nosCodeTypes.length, nosTypes: field.nosCodeTypes }
          }
        }
      }

      const observables = []
      for (const field in fieldsWithNos) {
        for (const nosType of fieldsWithNos[field].nosTypes) {
          observables.push(nosService.getNosByCode(nosType.value))
        }
      }
      return forkJoin(observables)
    }),
    map((observables) => {
      let i = 0
      for (const field in fieldsWithNos) {
        nosValues[field] = []
        for (let j = 0; j < fieldsWithNos[field].length; j++) {
          nosValues[field].push(...observables[i])
          i++
        }
        nosValues[field] = nosValues[field].sort((a, b) => a.libelle.localeCompare(b.libelle))
      }

      return {
        fields,
        nosValues,
      }
    }),
  )
}
