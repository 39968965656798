import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { FichePerson } from '@models/person'
import { environment } from '@env/environment'
import { Field } from '@models/field'

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  private readonly http = inject(HttpClient)

  private readonly api = environment.apiUrl

  constructor() {}

  getFichePerson(id: string) {
    return this.http.get<FichePerson>(`${this.api}/person/${id}`)
  }

  createPerson(person: FichePerson) {
    return this.http.post<FichePerson>(`${this.api}/person`, person)
  }

  updatePerson(id: string, person: FichePerson) {
    return this.http.put<FichePerson>(`${this.api}/person/${id}`, person)
  }

  getPersonFields(subField?: string) {
    let params = new HttpParams()
    if (subField) params = params.set('subEntityPath', subField)
    return this.http.get<Field[]>(`${this.api}/person/fields`, { params })
  }

  deactivatePerson(id: string) {
    return this.http.delete<FichePerson>(`${this.api}/person/${id}`)
  }

  reactivatePerson(id: string) {
    return this.http.patch<FichePerson>(`${this.api}/person/${id}`, {})
  }
}
