import { Component, inject } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { ButtonDirective } from 'primeng/button'
import { TranslateModule } from '@ngx-translate/core'
import { ExercisePro, FicheExercisePro } from '@models/exercise-pro'
import { Fields } from '@models/field'
import { Nos } from '@models/nos'
import { CardInformationComponent } from '@shared/components/card-information/card-information.component'
import { NgClass, NgIf } from '@angular/common'
import { DropdownModule } from 'primeng/dropdown'
import { InputComponent } from '@shared/components/input/input.component'
import { InputTextModule } from 'primeng/inputtext'
import { handleText } from '@shared/utils/value-form'
import { Person } from '@models/person'

@Component({
  selector: 'arsb-new-exercise-pro',
  standalone: true,
  imports: [
    ButtonDirective,
    ReactiveFormsModule,
    TranslateModule,
    CardInformationComponent,
    NgIf,
    DropdownModule,
    InputComponent,
    InputTextModule,
    NgClass,
  ],
  templateUrl: './new-exercise-pro.component.html',
  styleUrl: './new-exercise-pro.component.scss',
})
export class NewExerciseProComponent {
  protected readonly ref = inject(DynamicDialogRef)
  private readonly config = inject(DynamicDialogConfig)
  private readonly formBuilder = inject(FormBuilder)

  protected readonly Boolean = Boolean

  personId: string = this.config.data.personId
  person?: Person = this.config.data.person
  exercisePro?: FicheExercisePro = this.config.data.exercisePro
  fields: Fields = this.config.data.fields
  nosValues: { [key: string]: Nos[] } = this.config.data.nosValues
  form = this.formBuilder.group({
    codeCiv: [this.exercisePro?.regional?.codeCiv],
    libCiv: [this.exercisePro?.regional?.libCiv],
    nom: [
      {
        value: this.exercisePro?.regional?.nom ?? this.person?.nomUsage,
        disabled: Boolean(this.person?.nomUsage),
      },
      this.exercisePro?.national?.nom ? [] : [Validators.required],
    ],
    prenom: [
      {
        value: this.exercisePro?.regional?.prenom ?? this.person?.prenomUsage,
        disabled: Boolean(this.person?.prenomUsage),
      },
      this.exercisePro?.national?.prenom ? [] : [Validators.required],
    ],
    codeProf: [
      this.exercisePro?.regional?.codeProf ?? this.exercisePro?.national?.codeProf,
      this.exercisePro?.national?.codeProf ? [] : [Validators.required],
    ],
    libProf: [this.exercisePro?.regional?.libProf ?? this.exercisePro?.national?.libProf],
    codeCatProf: [
      this.exercisePro?.regional?.codeCatProf ?? this.exercisePro?.national?.codeCatProf,
      this.exercisePro?.national?.codeCatProf ? [] : [Validators.required],
    ],
    libCatProf: [this.exercisePro?.regional?.libCatProf ?? this.exercisePro?.national?.libCatProf],
    person: this.formBuilder.group({
      id: [this.personId, Validators.required],
    }),
  })

  save() {
    if (this.form.invalid) return

    const exercisePro: ExercisePro = {
      ...(this.form.value as ExercisePro),
      nom: handleText(this.form.get('nom')!),
    }

    if (exercisePro.codeCiv && this.nosValues['codeCiv'].some((civ) => civ.code === exercisePro.codeCiv)) {
      exercisePro.libCiv = this.nosValues['codeCiv'].find((civ) => civ.code === exercisePro.codeCiv)!.libelle
    }
    if (exercisePro.codeProf && this.nosValues['codeProf'].some((civ) => civ.code === exercisePro.codeProf)) {
      exercisePro.libProf = this.nosValues['codeProf'].find((civ) => civ.code === exercisePro.codeProf)!.libelle
    }
    if (exercisePro.codeCatProf && this.nosValues['codeCatProf'].some((civ) => civ.code === exercisePro.codeCatProf)) {
      exercisePro.libCatProf = this.nosValues['codeCatProf'].find(
        (civ) => civ.code === exercisePro.codeCatProf,
      )!.libelle
    }

    this.ref.close(exercisePro)
  }
}
