import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'
import { FicheStructure } from '@models/structure'

@Injectable({
  providedIn: 'root',
})
export class ExtraElementService {
  private readonly http = inject(HttpClient)
  private readonly endpoint = environment.apiUrl

  putStructureExtraElement(structureId: string, elementId: string, value: any) {
    return this.http.put<FicheStructure>(
      `${this.endpoint}/structure/${structureId}/extraElement/${elementId}`,
      JSON.stringify(value),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  }

  putStructureFile(structureId: string, elementId: string, file: File) {
    const formData = new FormData()
    formData.append('elementFile', file)
    return this.http.put<FicheStructure>(
      `${this.endpoint}/structure/${structureId}/extraElement/${elementId}`,
      formData,
    )
  }
}
