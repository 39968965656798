import { AbstractControl } from '@angular/forms'
import { Coord } from '@models/coord'

export const setValueToNull = (control: AbstractControl): void => {
  if (!control.value || !control.value.length) {
    control.setValue(null)
  }
}

export const isDisabled = (control: AbstractControl): boolean | null => {
  return control.disabled ? true : null
}

export const getLabelPlaceholder = (control: AbstractControl, national?: string): string => {
  if (control.value === '') {
    return 'common.placeholder.empty'
  }
  if (control.value === null || control.value === undefined) {
    return national ?? ''
  }

  return ''
}

export const handleAddress = (form?: AbstractControl, national?: Coord): string => {
  return [
    form?.get('numVoie')?.value ?? national?.numVoie,
    form?.get('indiceRepVoie')?.value ?? national?.indiceRepVoie,
    form?.get('libTypeVoie')?.value ?? national?.libTypeVoie,
    form?.get('libVoie')?.value ?? national?.libVoie,
  ]
    .filter((item) => item)
    .join(' ')
}

export const handleText = (
  form: AbstractControl,
  {
    uppercase = true,
    lowercase = false,
    unaccent = true,
  }: {
    uppercase?: boolean
    lowercase?: boolean
    unaccent?: boolean
  } = {},
): string => {
  let value = form.value

  if (!value) {
    return value
  }

  if (uppercase) {
    value = value.toUpperCase()
  }

  if (lowercase) {
    value = value.toLowerCase()
  }

  if (unaccent) {
    value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  return value
}
