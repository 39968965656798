<form [formGroup]="form">
  <arsb-card-information
    *ngIf="activity"
    [id]="activity.id"
    [nationalStatut]="activity.national?.statut"
    [nationalStatutDate]="activity.national?.statutDate"
    [regionalStatut]="activity.regional?.statut"
    [regionalStatutDate]="activity.regional?.statutDate">
  </arsb-card-information>

  <div class="flex flex-col gap-2">
    <div class="flex flex-col">
      <label for="codeFonction">
        {{ "activity.fonction" | translate }}
      </label>
      <div class="gap-4 grid grid-cols-2">
        <arsb-input
          [value]="activity?.national?.libFonction"
          (updateValue)="form.controls['codeFonction'].setValue(activity?.national?.codeFonction)" />
        <p-dropdown
          #codeFonction
          inputId="codeFonction"
          styleClass="w-full"
          [options]="nosValues['codeFonction']"
          optionLabel="libelle"
          optionValue="code"
          formControlName="codeFonction"
          filter="true"
          [filterFields]="['code', 'libelle']"
          [showClear]="true"
          (onShow)="codeFonction.filterValue = null" />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="codeModeExercice">
        {{ "activity.mode-exercice" | translate }}
      </label>
      <div class="gap-4 grid grid-cols-2">
        <arsb-input
          [value]="activity?.national?.libModeExercice"
          (updateValue)="form.controls['codeModeExercice'].setValue(activity?.national?.codeModeExercice)" />
        <p-dropdown
          #codeModeExercice
          inputId="codeModeExercice"
          styleClass="w-full"
          [options]="nosValues['codeModeExercice']"
          optionLabel="libelle"
          optionValue="code"
          formControlName="codeModeExercice"
          filter="true"
          [filterFields]="['code', 'libelle']"
          [showClear]="true"
          (onShow)="codeModeExercice.filterValue = null" />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="codeGenreAct">
        {{ "activity.genre-activity" | translate }}
      </label>
      <div class="gap-4 grid grid-cols-2">
        <arsb-input
          [value]="activity?.national?.libGenreAct"
          (updateValue)="form.controls['codeGenreAct'].setValue(activity?.national?.codeGenreAct)" />
        <p-dropdown
          #codeGenreAct
          inputId="codeGenreAct"
          styleClass="w-full"
          [options]="nosValues['codeGenreAct']"
          optionLabel="libelle"
          optionValue="code"
          formControlName="codeGenreAct"
          filter="true"
          [filterFields]="['code', 'libelle']"
          [showClear]="true"
          (onShow)="codeGenreAct.filterValue = null" />
      </div>
    </div>
    <div class="flex flex-col">
      <label for="codeMotifFinAct">
        {{ "activity.motif-fin-activity" | translate }}
      </label>
      <div class="gap-4 grid grid-cols-2">
        <arsb-input
          [value]="activity?.national?.libMotifFinAct"
          (updateValue)="form.controls['codeMotifFinAct'].setValue(activity?.national?.codeMotifFinAct)" />
        <p-dropdown
          #codeMotifFinAct
          inputId="codeMotifFinAct"
          styleClass="w-full"
          [options]="nosValues['codeMotifFinAct']"
          optionLabel="libelle"
          optionValue="code"
          formControlName="codeMotifFinAct"
          filter="true"
          [filterFields]="['code', 'libelle']"
          [showClear]="true"
          (onShow)="codeMotifFinAct.filterValue = null" />
      </div>
    </div>
  </div>

  <div
    class="mt-8"
    formGroupName="structure">
    <label for="structure">{{ "structure.label" | translate }}</label>
    <div class="gap-4 grid grid-cols-2">
      <arsb-card
        *ngIf="structure?.national; else noStructure"
        icon="pi pi-building"
        [label]="structure?.fusion?.raisonSociale!"
        [date]="structure?.fusion?.statutDate"
        [statut]="structure?.fusion?.statut"></arsb-card>
      <ng-template #noStructure>
        <p-card />
      </ng-template>
      <p-autoComplete
        inputId="structure"
        formControlName="raisonSociale"
        class="w-full"
        styleClass="w-full"
        inputStyleClass="w-full"
        [suggestions]="suggestedStructures"
        (completeMethod)="researchStructures($event)"
        [showClear]="true"
        (onSelect)="selectStructure($event)">
        <ng-template
          let-item
          pTemplate="item">
          <div class="flex items-center gap-4">
            <i class="pi pi-building"></i>
            <span>{{ item.raisonSociale }}</span>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
  </div>

  <div class="mt-8 mb-4">
    <arsb-coordinates
      [national]="activity?.national?.activityCoord"
      [regional]="activity?.regional?.activityCoord"
      formGroupName="activityCoord"></arsb-coordinates>
  </div>

  <div class="mt-8 flex justify-end gap-4">
    <button
      pButton
      type="button"
      [label]="'common.cancel' | translate"
      [outlined]="true"
      (click)="ref.close()"></button>
    <button
      pButton
      type="button"
      [label]="'common.save' | translate"
      [disabled]="form.invalid"
      (click)="save()"></button>
  </div>
</form>
