<div class="flex items-center justify-evenly gap-4">
  <div class="flex flex-col gap-4">
    <div class="mx-8">
      <p-selectButton
        [options]="typeOptions"
        [(ngModel)]="values.type"
        [unselectable]="true"
        optionLabel="icon"
        optionValue="value"
        optionDisabled="constant">
        <ng-template
          let-item
          pTemplate>
          <i
            [class]="item.icon"
            [pTooltip]="item.label | translate"></i>
        </ng-template>
      </p-selectButton>
    </div>
    <div class="flex gap-4">
      <div class="flex flex-col gap-0.5">
        <label for="location">{{ "home.location" | translate }}</label>
        <input
          pInputText
          type="text"
          class="w-96"
          (keydown)="values.location.longitude = 0; values.location.latitude = 0"
          [(ngModel)]="values.location.address"
          (blur)="setCoordinates()"
          id="location" />
      </div>
      <div class="flex flex-col gap-4">
        <label for="range">{{ "home.range" | translate }} : {{ values.range }}km</label>
        <div class="mx-2">
          <p-slider
            id="range"
            min="1"
            max="100"
            styleClass="w-96 secondary"
            [(ngModel)]="values.range" />
        </div>
      </div>
    </div>
    <hr class="border-b border-secondary" />
    @switch (values.type) {
      @case ("structure") {
        <div class="flex gap-4">
          <div class="flex flex-col gap-0.5">
            <label for="finess">{{ "home.structure.finess" | translate }}</label>
            <input
              pInputText
              type="text"
              class="w-64"
              [(ngModel)]="values.structure.finessStartWith"
              id="finess" />
          </div>
          <div class="flex flex-col gap-0.5">
            <label for="siret">{{ "home.structure.siret" | translate }}</label>
            <input
              pInputText
              type="text"
              class="w-64"
              [(ngModel)]="values.structure.siretStartWith"
              id="siret" />
          </div>
          <div class="flex flex-col gap-0.5">
            <label for="rna">{{ "home.structure.rna" | translate }}</label>
            <input
              pInputText
              type="text"
              class="w-64"
              [(ngModel)]="values.structure.rnaStartWith"
              id="rna" />
          </div>
        </div>
        <div class="flex gap-4">
          <div class="flex flex-col gap-0.5">
            <label for="adresse">{{ "home.structure.adresse" | translate }}</label>
            <input
              pInputText
              type="text"
              class="w-64"
              [(ngModel)]="values.structure.adresseMatch"
              id="adresse" />
          </div>
          <div class="flex flex-col gap-0.5">
            <label for="codePostal">{{ "home.structure.code-postal" | translate }}</label>
            <input
              pInputText
              type="text"
              class="w-64"
              [(ngModel)]="values.structure.codePostalStartWith"
              id="codePostal" />
          </div>
          <div class="flex flex-col gap-0.5">
            <label for="libCommune">{{ "home.structure.lib-commune" | translate }}</label>
            <input
              pInputText
              type="text"
              class="w-64"
              [(ngModel)]="values.structure.libCommuneContain"
              id="libCommune" />
          </div>
        </div>
      }
      @case ("person") {}
    }
  </div>
  <button
    pButton
    type="submit"
    icon="pi pi-search"
    severity="secondary"
    [label]="'home.research' | translate"
    (click)="searchAdvanced()"></button>
</div>
