import { AbstractControl } from '@angular/forms'

export const hasIsRequiredError = (control: AbstractControl): boolean => {
  return control.errors?.['required'] && control.invalid
}

export const hasIsEmailError = (control: AbstractControl): boolean => {
  return control.errors?.['email'] && control.invalid
}

export const hasMinLengthError = (control: AbstractControl): boolean => {
  return control.errors?.['minlength'] && control.invalid
}

export const hasMaxLengthError = (control: AbstractControl): boolean => {
  return control.errors?.['maxlength'] && control.invalid
}

export const hasPatternError = (control: AbstractControl): boolean => {
  return control.errors?.['pattern'] && control.invalid
}
