import { Routes } from '@angular/router'
import { PersonComponent } from '@module/person/person.component'
import { StructureComponent } from '@module/structure/structure.component'
import { authenticatedGuard } from '@shared/guards/authenticated.guard'
import { FusionComponent } from '@module/fusion/fusion.component'
import { HomeComponent } from '@module/home/home.component'

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'person/:id',
    component: PersonComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'structure/:id',
    component: StructureComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: 'fusion/:type/:id1/:id2',
    component: FusionComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
]
