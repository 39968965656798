<div
  class="py-4 flex justify-between items-center bg-gray-200 my-4 rounded-lg shadow drop-shadow-lg"
  [ngClass]="{
    'bg-amber-100': isNationalAfterRegional && !isDeactivated,
    'bg-red-200': isDeactivated,
  }">
  <div class="flex flex-col lg:flex-row gap-x-8 gap-y-4 lg:items-center ms-8">
    <div
      *ngIf="id"
      class="flex flex-col">
      <div class="flex gap-4">
        <span>{{ "common.type" | translate }}</span>
        <span>{{ niveau }}</span>
      </div>
      <div class="flex gap-4">
        <span>{{ "common.id" | translate }}</span>
        <span>{{ id }}</span>
      </div>
    </div>
    <div class="flex flex-col">
      <strong class="text-xl">{{ "common.national" | translate }}</strong>
      <div class="flex gap-2">
        <span *ngIf="nationalStatut">{{ "statut." + nationalStatut | translate }}</span>
        <span *ngIf="nationalStatut">&middot;</span>
        <span *ngIf="!nationalStatut"> / </span>
        <span>{{ nationalStatutDate | date: "dd/MM/yyyy" }}</span>
      </div>
    </div>
    <div class="flex flex-col">
      <strong class="text-xl">{{ "common.regional" | translate }}</strong>
      <div class="flex gap-2">
        <span *ngIf="regionalStatut">{{ "statut." + regionalStatut | translate }}</span>
        <span *ngIf="regionalStatut">&middot;</span>
        <span *ngIf="!regionalStatut"> / </span>
        <span>{{ regionalStatutDate | date: "dd/MM/yyyy" }}</span>
      </div>
    </div>
  </div>
  <div class="mr-8">
    <ng-content select="[arsbActions]"></ng-content>
  </div>
</div>
