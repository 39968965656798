export interface GcsObject<T = string> {
  id: T
  statut: Statut
  statutDate: Date
  niveau: 'NATIONAL' | 'REGIONAL'
}

export enum Statut {
  UNKNOWN = 'UNKNOWN',
  ADDED = 'ADDED',
  DEACTIVATED = 'DEACTIVATED',
  UPDATED = 'UPDATED',
}
