import { Component, inject, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { HeaderComponent } from '@shared/components/header/header.component'
import { FooterComponent } from '@shared/components/footer/footer.component'
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api'
import { TranslateService } from '@ngx-translate/core'
import { ToastModule } from 'primeng/toast'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { NgIf } from '@angular/common'
import { KeycloakEventType, KeycloakService } from 'keycloak-angular'
import { DialogService } from 'primeng/dynamicdialog'
import { SwUpdate } from '@angular/service-worker'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, ToastModule, ConfirmDialogModule, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService, ConfirmationService, DialogService],
})
export class AppComponent implements OnInit {
  private readonly config = inject(PrimeNGConfig)
  private readonly translate = inject(TranslateService)
  private readonly swUpdate = inject(SwUpdate)
  private readonly messageService = inject(MessageService)
  private readonly keycloakService = inject(KeycloakService)

  ngOnInit() {
    this.translate.get('primeng').subscribe((res) => this.config.setTranslation(res))

    this.keycloakService.keycloakEvents$.subscribe({
      next: (event) => {
        if (event.type == KeycloakEventType.OnTokenExpired) {
          this.keycloakService.updateToken(20)
        }
      },
    })

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.subscribe((event) => {
        switch (event.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${event.version.hash}`)
            break
          case 'VERSION_READY':
            console.log(`Current app version: ${event.currentVersion.hash}`)
            console.log(`New app version ready for use: ${event.latestVersion.hash}`)
            this.messageService.add({
              severity: 'info',
              summary: this.translate.instant('app.update-available'),
              detail: this.translate.instant('app.update-available-detail'),
              sticky: true,
              data: {
                version: event.latestVersion,
              },
            })
            break
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${event.version.hash}': ${event.error}`)
            break
          case 'NO_NEW_VERSION_DETECTED':
            console.log(`No new app version detected`)
            break
        }
      })
    }
  }
}
