import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Directive({
  selector: '[arsbPhone]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhoneDirective,
      multi: true,
    },
  ],
})
export class PhoneDirective implements ControlValueAccessor {
  private onChange: ((_: any) => void) | undefined
  private onTouched: (() => void) | undefined

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('input', ['$event.target.value']) onInput(value: string) {
    value = value
      .replace(/\D/g, '')
      .replace(/(.{2})/g, '$1 ')
      .trim()
      .slice(0, 14)
    this.renderer.setProperty(this.el.nativeElement, 'value', value)

    if (this.onChange) {
      this.onChange(value)
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn
  }

  writeValue(value: any) {
    if (value != null) {
      value = value
        .replace(/\D/g, '')
        .replace(/(.{2})/g, '$1 ')
        .trim()
        .slice(0, 14)
    }
    this.renderer.setProperty(this.el.nativeElement, 'value', value)
  }
}
