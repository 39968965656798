import { Component, inject, Input, OnInit } from '@angular/core'
import { FormGroup, FormGroupDirective, ReactiveFormsModule } from '@angular/forms'
import { InputTextModule } from 'primeng/inputtext'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { ButtonDirective } from 'primeng/button'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { latLng, Map, MapOptions, marker, Marker, tileLayer } from 'leaflet'
import { handleAddress } from '@shared/utils/value-form'
import { AddressService } from '@services/address.service'
import { MessageService } from 'primeng/api'
import { debounceTime, pairwise, startWith, Subject, switchMap } from 'rxjs'

@Component({
  selector: 'arsb-center-coord-fusion',
  standalone: true,
  imports: [ReactiveFormsModule, InputTextModule, TranslateModule, ButtonDirective, LeafletModule],
  templateUrl: './center-coord-fusion.component.html',
  styleUrl: './center-coord-fusion.component.scss',
})
export class CenterCoordFusionComponent implements OnInit {
  private readonly formGroupDirective = inject(FormGroupDirective)
  private readonly messageService = inject(MessageService)
  private readonly translate = inject(TranslateService)

  private readonly addressService = inject(AddressService)

  protected readonly handleAddress = handleAddress

  @Input() formGroupName!: string

  form!: FormGroup
  private locateSubject = new Subject<string>()

  map?: Map
  mapOptions: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '...',
      }),
    ],
    zoom: 13,
    center: latLng(48.1119, -1.6806),
  }
  layers: Marker[] = []

  ngOnInit() {
    this.form = this.formGroupDirective.form.get(this.formGroupName) as FormGroup
    if (this.form.get('latitude')?.value && this.form.get('longitude')?.value) {
      const latitude = this.form.get('latitude')?.value
      const longitude = this.form.get('longitude')?.value
      this.layers = [marker([latitude, longitude])]
      this.map?.setView([latitude, longitude], 13)
    }

    this.form.valueChanges.pipe(startWith(this.form.value), pairwise()).subscribe(([prev, next]) => {
      if (
        next.libVoie &&
        (prev.codePostal !== next.codePostal ||
          prev.libCommune !== next.libCommune ||
          handleAddress(undefined, prev) !== handleAddress(undefined, next))
      ) {
        this.locate()
      }
    })

    this.locateSubject
      .pipe(
        debounceTime(300),
        switchMap((address) => this.addressService.getAddresses(address)),
      )
      .subscribe((addresses) => {
        if (addresses.length === 0) {
          this.messageService.add({
            severity: 'error',
            summary: this.translate.instant('common.error'),
            detail: this.translate.instant('common.errors.empty-addresses'),
          })
          return
        }
        this.addressService.setMapAddress(addresses[0], this.map, this.layers, this.form)
      })
  }

  locate() {
    const address = `${handleAddress(this.form)} ${this.form?.get('codePostal')?.value ?? ''} ${this.form?.get('libCommune')?.value ?? ''}`
    this.locateSubject.next(address)
  }
}
