import { Component, EventEmitter, Input, Output } from '@angular/core'
import { InputGroupModule } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { NgClass, NgIf } from '@angular/common'
import { InputTextModule } from 'primeng/inputtext'
import { TooltipModule } from 'primeng/tooltip'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'arsb-fusion-input-button',
  standalone: true,
  imports: [InputGroupModule, InputGroupAddonModule, NgIf, InputTextModule, TooltipModule, TranslateModule, NgClass],
  templateUrl: './fusion-input-button.component.html',
  styleUrl: './fusion-input-button.component.scss',
})
export class FusionInputButtonComponent {
  @Input() value!: string
  @Input() buttonRight!: boolean
  @Input() inputClass?: string

  @Output() setValue = new EventEmitter<string>()
}
