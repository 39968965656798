import { inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '@env/environment'
import { ExercisePro, FicheExercisePro } from '@models/exercise-pro'
import { Field } from '@models/field'

@Injectable({
  providedIn: 'root',
})
export class ExerciseProService {
  private readonly http = inject(HttpClient)

  private readonly api = environment.apiUrl

  getExercisesProByPerson(personId: string) {
    return this.http.get<ExercisePro[]>(`${this.api}/exercisePro/person/${personId}`)
  }

  getFields(subField?: string) {
    let params = new HttpParams()
    if (subField) params = params.set('subEntityPath', subField)
    return this.http.get<Field[]>(`${this.api}/exercisePro/fields`, { params })
  }

  getExercisePro(id: string) {
    id = id.replaceAll('#', '%23')
    return this.http.get<FicheExercisePro>(`${this.api}/exercisePro/${id}`)
  }

  updateExercisePro(id: string, exercisePro: ExercisePro) {
    id = id.replaceAll('#', '%23')
    return this.http.put<ExercisePro>(`${this.api}/exercisePro/${id}`, exercisePro)
  }

  createExercisePro(exercisePro: ExercisePro) {
    return this.http.post<ExercisePro>(`${this.api}/exercisePro`, exercisePro)
  }

  deactivateExercisePro(id: string) {
    id = id.replaceAll('#', '%23')
    return this.http.delete<ExercisePro>(`${this.api}/exercisePro/${id}`)
  }

  activateExercisePro(id: string) {
    id = id.replaceAll('#', '%23')
    return this.http.patch<ExercisePro>(`${this.api}/exercisePro/${id}`, null)
  }
}
