import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Activity } from '@models/activity'
import { ButtonDirective } from 'primeng/button'
import { TranslateModule } from '@ngx-translate/core'
import { CardModule } from 'primeng/card'
import { NgForOf, NgIf } from '@angular/common'
import { CardComponent } from '@shared/components/card/card.component'
import { TooltipModule } from 'primeng/tooltip'

@Component({
  selector: 'arsb-person-activity',
  standalone: true,
  imports: [ButtonDirective, TranslateModule, CardModule, NgIf, CardComponent, NgForOf, TooltipModule],
  templateUrl: './person-activity.component.html',
  styleUrl: './person-activity.component.scss',
})
export class PersonActivityComponent {
  @Input() activity!: Activity
  @Output() updateActivity = new EventEmitter<Activity>()
}
