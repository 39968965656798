<p-inputGroup>
  <p-inputGroupAddon
    *ngIf="!buttonRight"
    class="cursor-pointer"
    [pTooltip]="'common.update-value' | translate"
    tooltipPosition="top"
    (click)="setValue.emit(value)">
    <i class="pi pi-arrow-left"></i>
  </p-inputGroupAddon>
  <input
    pInputText
    disabled
    [ngClass]="inputClass"
    [value]="value" />
  <p-inputGroupAddon
    *ngIf="buttonRight"
    class="cursor-pointer"
    [pTooltip]="'common.update-value' | translate"
    tooltipPosition="top"
    (click)="setValue.emit(value)">
    <i class="pi pi-arrow-right"></i>
  </p-inputGroupAddon>
</p-inputGroup>
