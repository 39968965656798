import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core'
import { Table, TableModule, TablePageEvent } from 'primeng/table'
import { SearchedItem, SearchedPerson, SearchedStructure } from '@module/home/models/search'
import { TranslateModule } from '@ngx-translate/core'
import { NgClass, NgIf } from '@angular/common'
import { RouterLink } from '@angular/router'
import { SortMeta } from 'primeng/api'

@Component({
  selector: 'arsb-view-table',
  standalone: true,
  imports: [TableModule, TranslateModule, NgIf, NgClass, RouterLink],
  templateUrl: './view-table.component.html',
  styleUrl: './view-table.component.scss',
})
export class ViewTableComponent implements OnChanges {
  @Input() items: (SearchedItem | SearchedStructure | SearchedPerson)[] = []
  @Input() type!: 'simple' | 'structure' | 'person'
  @Input() totalRecords!: number

  @Output() searchNextPage = new EventEmitter<number>()
  @Output() sortFields = new EventEmitter<string[]>()

  @ViewChild('table') table: Table | undefined

  first: number = 0
  loading: boolean = true
  page: number = 0

  commonsSortableColumns: {
    [key: string]: string[]
  } = {
    simple: ['label', 'adresse'],
    structure: ['raisonSociale', 'adresse'],
    // person: ['nom', 'prenom', 'adresse', 'codePostal', 'libCommune'],
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.first = 0
      this.page = 0
      this.table?.clearState()
    }
    if (changes['items']) {
      this.loading = false
    }
  }

  typeIcon(index: number) {
    switch (this.type) {
      case 'simple':
        switch ((this.items[index - this.first] as SearchedItem).type) {
          case 'STRUCTURE':
            return 'text-xl pi pi-building'
          case 'PERSON':
            return 'text-xl pi pi-user'
          case 'EXERCISE_PRO':
            return 'text-xl pi pi-briefcase'
          case 'ACTIVITY':
            return 'text-xl pi pi-search'
        }
        return 'text-xl pi pi-search'
      case 'structure':
        return 'text-xl pi pi-building'
      case 'person':
        return 'text-xl pi pi-user'
    }
  }

  selectPage(page: TablePageEvent) {
    this.loading = true
    this.first = page.first
    this.page = page.first / 50
    this.searchNextPage.emit(page.first / 50)
  }

  baseUrlItem(index: number) {
    if (this.type === 'simple' && 'type' in this.items[index - this.first]) {
      return (this.items[index - this.first] as SearchedItem).type?.toLowerCase()
    }
    return this.type
  }

  selectSorts(event: { multisortmeta: SortMeta[] }) {
    const sorts: string[] = []
    event.multisortmeta.forEach((sort) => {
      sorts.push(`${this.getSortField(sort.field)},${sort.order === 1 ? 'asc' : 'desc'}`)
    })
    this.loading = true
    this.sortFields.emit(sorts)
    this.searchNextPage.emit(this.page)
  }

  getSortField(field: string) {
    if (this.commonsSortableColumns['simple'].includes(field)) {
      const index = this.commonsSortableColumns['simple'].indexOf(field)
      return this.commonsSortableColumns[this.type][index]
    }
    return field
  }
}
