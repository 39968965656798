import { divIcon, marker } from 'leaflet'

// Create a marker with a number on the map
export function numberMarker(number: number, lat: number, lng: number) {
  const iconDefault = divIcon({
    html: `<span>${number}</span>`,
    className: 'marker',
    iconSize: [25, 25],
    iconAnchor: [12.5, 35],
    popupAnchor: [0, -35],
  })
  return marker([lat, lng], { icon: iconDefault })
}
