<div
  *ngIf="(fichePerson || id === 'new') && formPerson"
  class="container relative">
  <p-breadcrumb
    class="max-w-full"
    [model]="breadcrumbItems"
    [home]="homeItem"></p-breadcrumb>
  <form
    [formGroup]="formPerson"
    class="py-4">
    <arsb-form-component color="bg-blue-500">
      <div
        arsbContent
        class="flex-grow">
        <h1 class="mb-4">{{ "person.person" | translate }}</h1>

        <arsb-card-information
          [id]="id"
          [nationalStatut]="fichePerson?.national?.statut"
          [nationalStatutDate]="fichePerson?.national?.statutDate"
          [regionalStatut]="fichePerson?.regional?.statut"
          [regionalStatutDate]="fichePerson?.regional?.statutDate">
          <div
            arsbActions
            class="flex gap-3">
            <div
              *ngIf="id !== 'new'"
              class="flex flex-col items-end">
              <label for="disablePerson">{{ "common.disable" | translate }}</label>
              <p-inputSwitch
                inputId="disablePerson"
                formControlName="disablePerson"
                (onChange)="askForDeactivateOrReactivatePerson($event)" />
            </div>
          </div>
        </arsb-card-information>

        <div class="flex flex-col gap-4">
          <div class="flex flex-col">
            <label for="codeCiv">
              {{ "person.civility" | translate }}
            </label>
            <div class="flex gap-4">
              <arsb-input
                [value]="fichePerson?.national?.libCiv"
                inputClass="w-52"
                [hasSetNullButton]="false"
                (updateValue)="formPerson.controls['codeCiv'].setValue(fichePerson?.national?.codeCiv)" />
              <p-dropdown
                #civ
                inputId="codeCiv"
                styleClass="w-52"
                [options]="nosValues['codeCiv']"
                optionLabel="libelle"
                optionValue="code"
                [formControl]="getControl('codeCiv')"
                filter="true"
                [filterFields]="['code', 'libelle']"
                (onShow)="civ.filterValue = null" />
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4">
            <div class="flex flex-col">
              <label for="nom-usage">
                {{ "person.nom-usage" | translate }}
              </label>
              <div class="gap-4 grid grid-cols-2">
                <arsb-input
                  [value]="fichePerson?.national?.nomUsage"
                  (updateValue)="formPerson.controls['nomUsage'].setValue($event)"
                  (setNullValue)="formPerson.controls['nomUsage'].setValue('')" />
                <input
                  pInputText
                  id="nom-usage"
                  formControlName="nomUsage"
                  (keyup.backspace)="setValueToNull(formPerson.controls['nomUsage'])"
                  [placeholder]="
                    getLabelPlaceholder(formPerson.controls['nomUsage'], fichePerson?.national?.nomUsage) | translate
                  " />
              </div>
            </div>
            <div class="flex flex-col">
              <label for="prenom-usage">
                {{ "person.prenom-usage" | translate }}
              </label>
              <div class="gap-4 grid grid-cols-2">
                <arsb-input
                  [value]="fichePerson?.national?.prenomUsage"
                  (updateValue)="formPerson.controls['prenomUsage'].setValue($event)"
                  (setNullValue)="formPerson.controls['prenomUsage'].setValue('')" />
                <input
                  pInputText
                  id="prenom-usage"
                  formControlName="prenomUsage"
                  (keyup.backspace)="setValueToNull(formPerson.controls['prenomUsage'])"
                  [placeholder]="
                    getLabelPlaceholder(formPerson.controls['prenomUsage'], fichePerson?.national?.prenomUsage)
                      | translate
                  " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </arsb-form-component>

    <!-- ExercisePro -->
    <arsb-form-component
      *ngIf="id !== 'new'"
      class="mt-8 mb-4"
      color="bg-green-500">
      <div
        arsbContent
        class="flex-grow">
        <div id="exercisePro">
          <div class="mb-4 flex justify-between">
            <h2>{{ "exercise-pro.title" | translate }}</h2>
            <div class="flex gap-4">
              <button
                pButton
                type="button"
                [ngClass]="{ 'p-button-danger': showDisabledExercisePro, 'p-button-info': !showDisabledExercisePro }"
                [icon]="showDisabledExercisePro ? 'pi pi-eye' : 'pi pi-eye-slash'"
                [pTooltip]="'toggle.invisible' | translate: { label: 'exercise-pro.title' | translate | lowercase }"
                (click)="showDisabledExercisePro = !showDisabledExercisePro"></button>
              <button
                pButton
                type="button"
                severity="success"
                icon="pi pi-plus"
                [pTooltip]="'exercise-pro.new' | translate"
                (click)="openNewExerciseProModal()"></button>
            </div>
          </div>
          <p-tabView
            #tabView
            [scrollable]="true">
            <p-tabPanel
              *ngFor="let exercisePro of filteredExercisePros"
              [header]="exercisePro.libProf"
              [headerStyleClass]="exercisePro.statut == Statut.DEACTIVATED ? 'deactivated' : ''">
              <arsb-person-exercise-pro
                [person]="fichePerson?.regional"
                [exercisePro]="exercisePro"
                (updateExercisePro)="openNewExerciseProModal($event)"
                (updateActivity)="openNewActivityModal($event.exercisePro, $event.activity)"
                (createActivity)="openNewActivityModal($event)"
                (deactivateActivity)="deactivateActivity($event)"
                (reactivateActivity)="reactivateActivity($event)"></arsb-person-exercise-pro>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </arsb-form-component>
    <!-- ExercisePro -->

    <!-- Coordinates -->
    <arsb-form-component
      class="mt-8 mb-4"
      color="bg-cyan-500">
      <div
        arsbContent
        class="flex-grow">
        <arsb-coordinates
          [national]="fichePerson?.national?.personCoord"
          [regional]="fichePerson?.regional?.personCoord"
          formGroupName="personCoord"></arsb-coordinates>
      </div>
    </arsb-form-component>
    <!-- Coordinates -->

    <!-- MSS -->
    <arsb-form-component
      *ngIf="id !== 'new'"
      class="mt-8 mb-4"
      color="bg-green-500">
      <div
        arsbContent
        class="flex-grow">
        <div class="mb-4 flex items-center justify-between">
          <h2 class="flex items-center gap-4">
            {{ "mss.title" | translate }}
            <div
              *ngIf="fichePerson?.msses?.length"
              class="circle bg-green-500 text-base"
              [pTooltip]="'mss.length' | translate: { length: fichePerson?.msses?.length }">
              {{ fichePerson?.msses?.length }}
            </div>
          </h2>
          <div class="flex gap-4">
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              severity="success"
              [pTooltip]="'mss.add-mss' | translate"
              (click)="openMssDialog()"></button>
            <button
              pButton
              type="button"
              [ngClass]="{ 'p-button-danger': showDisabledMsses, 'p-button-info': !showDisabledMsses }"
              [icon]="showDisabledMsses ? 'pi pi-eye' : 'pi pi-eye-slash'"
              [pTooltip]="'toggle.invisible' | translate: { label: 'mss.mss' | translate | lowercase }"
              (click)="showDisabledMsses = !showDisabledMsses"></button>
            <button
              *ngIf="(fichePerson?.msses?.length ?? 0) > 3"
              pButton
              type="button"
              severity="info"
              [icon]="showAllMsses ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              [pTooltip]="'toggle.see-more' | translate"
              (click)="showAllMsses = !showAllMsses"></button>
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <arsb-card
            *ngFor="let mss of msses"
            [iconLabel]="mss.niveauUsage"
            [iconTooltip]="'mss.niveau-small.' + mss.niveauUsage | translate"
            [label]="mss.mssAddress"
            [date]="mss.statutDate"
            [statut]="mss.statut"
            [canDeactivate]="true"
            [canActivate]="true"
            [canUpdate]="true"
            (deactivate)="deactivateMss(mss)"
            (activate)="activateMss(mss)"
            (update)="openMssDialog(mss)"></arsb-card>
        </div>
      </div>
    </arsb-form-component>
    <!-- MSS -->

    <div class="mt-4 flex justify-end">
      <button
        pButton
        type="button"
        [label]="'common.save' | translate"
        [disabled]="formPerson.invalid"
        class="w-36"
        (click)="submit()"></button>
    </div>
  </form>

  <div class="max-[1800px]:hidden min-[1800px]:fixed bottom-12 left-[1700px] right-8 flex justify-center gap-4">
    <button
      *ngIf="id !== 'new'"
      pButton
      type="button"
      icon="pi pi-undo"
      severity="info"
      outlined="true"
      size="large"
      [pTooltip]="'common.undo' | translate"
      tooltipPosition="left"
      (click)="resetForm()"></button>
    <button
      pButton
      type="button"
      icon="pi pi-save"
      size="large"
      [pTooltip]="'common.save' | translate"
      tooltipPosition="left"
      [disabled]="formPerson.invalid"
      (click)="submit()"></button>
  </div>
</div>
