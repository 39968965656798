import { Component, inject, Input, OnInit } from '@angular/core'
import { ElementTypes, ExtraElement, ExtraElementGroup } from '@models/extra-element'
import { ExtraElementService } from '@services/extra-element.service'
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common'
import { CheckboxModule } from 'primeng/checkbox'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { FormsModule } from '@angular/forms'
import { MessageService } from 'primeng/api'
import { TranslateService } from '@ngx-translate/core'
import { DropdownModule } from 'primeng/dropdown'
import { InputTextModule } from 'primeng/inputtext'
import { ChipsModule } from 'primeng/chips'
import { StyleClassModule } from 'primeng/styleclass'
import { ButtonDirective } from 'primeng/button'
import { FileService } from '@services/file.service'
import { FicheStructure } from '@models/structure'

@Component({
  selector: 'arsb-complements',
  standalone: true,
  imports: [
    NgForOf,
    NgClass,
    CheckboxModule,
    InputTextareaModule,
    FormsModule,
    DropdownModule,
    InputTextModule,
    ChipsModule,
    StyleClassModule,
    ButtonDirective,
    AsyncPipe,
    NgIf,
  ],
  templateUrl: './complements.component.html',
  styleUrl: './complements.component.scss',
})
export class ComplementsComponent implements OnInit {
  private readonly messageService = inject(MessageService)
  private readonly translate = inject(TranslateService)

  private readonly extraElementService = inject(ExtraElementService)
  protected readonly fileService = inject(FileService)
  protected readonly ElementTypes = ElementTypes

  @Input() id!: string
  @Input() extraElementsGroups!: ExtraElementGroup[]
  @Input() origin!: 'structure' | 'person'

  ngOnInit() {
    this.checkFiles()
  }

  postExtraElement(extraElement: ExtraElement, value: any) {
    switch (this.origin) {
      case 'structure':
        this.extraElementService.putStructureExtraElement(this.id, extraElement.element.id, value).subscribe({
          next: () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('common.success'),
            })
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('common.error'),
              detail: this.translate.instant('error.code.' + err.status),
            })
          },
        })
        break
      case 'person':
        console.log('Posting extra element for person', extraElement)
        break
    }
  }

  postFile(extraElement: ExtraElement, event: any) {
    const file = event.target.files[0]
    switch (this.origin) {
      case 'structure':
        this.extraElementService.putStructureFile(this.id, extraElement.element.id, file).subscribe({
          next: (ficheStructure: FicheStructure) => {
            this.extraElementsGroups = ficheStructure.extraElementGroups
            this.checkFiles()
            this.messageService.add({
              severity: 'success',
              summary: this.translate.instant('common.success'),
            })
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: this.translate.instant('common.error'),
              detail: this.translate.instant('error.code.' + err.status),
            })
          },
        })
        break
      case 'person':
        console.log('Posting extra element file for person', extraElement)
        break
    }
  }

  downloadFile(extraElement: ExtraElement) {
    this.fileService.download(extraElement.elementData).subscribe({
      next: (blob) => {
        console.log(blob)
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = extraElement.elementData.entityLabel
        a.click()
        window.URL.revokeObjectURL(url)
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('common.error'),
          detail: this.translate.instant('error.code.' + err.status),
        })
      },
    })
  }

  private checkFiles() {
    this.extraElementsGroups
      .filter((value) => value.extraElements.filter((element) => element.element.type === ElementTypes.FILE).length > 0)
      .forEach((group) => {
        group.extraElements
          .filter((element) => element.element.type === ElementTypes.FILE)
          .filter((fileElement) => fileElement.elementData?.entityLabel?.match(/.*\.(jpg|png|jpeg|gif|bmp|svg)$/))
          .forEach((fileElement) => {
            this.fileService.download(fileElement.elementData).subscribe({
              next: (blob) => {
                fileElement.elementData.url = window.URL.createObjectURL(blob)
              },
              error: (err) => {
                this.messageService.add({
                  severity: 'error',
                  summary: this.translate.instant('common.error'),
                  detail: this.translate.instant('error.code.' + err.status),
                })
              },
            })
          })
      })
  }
}
