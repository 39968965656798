<form [formGroup]="form">
  <span class="font-bold text-xl">{{ "structure.link.child" | translate }}</span>
  <p>{{ raisonSociale }}</p>
  <div class="mt-4 flex flex-col">
    <label
      id="parentId"
      class="font-bold text-xl">
      {{ "structure.link.parent" | translate }}
    </label>
    <p-autoComplete
      inputId="parentId"
      formControlName="parentId"
      class="w-full"
      styleClass="w-full"
      inputStyleClass="w-full"
      [suggestions]="structures"
      (completeMethod)="researchStructures($event)"
      optionValue="id"
      field="raisonSociale" />
  </div>
  <div class="mt-4 flex flex-col">
    <label
      id="relation"
      class="font-bold text-xl">
      {{ "structure.link.label" | translate }}
    </label>
    <p-dropdown
      inputId="relation"
      styleClass="w-full"
      formControlName="relation"
      [options]="relationTypes" />
  </div>

  <div class="mt-8 flex justify-end gap-4">
    <button
      pButton
      type="button"
      [label]="'common.cancel' | translate"
      [outlined]="true"
      (click)="ref.close()"></button>
    <button
      pButton
      type="button"
      [label]="'common.save' | translate"
      [disabled]="form.invalid"
      (click)="saveLink()"></button>
  </div>
</form>
