import { Component, Input } from '@angular/core'
import { NgClass } from '@angular/common'

@Component({
  selector: 'arsb-form-component',
  standalone: true,
  imports: [NgClass],
  templateUrl: './form-component.component.html',
  styleUrl: './form-component.component.scss',
})
export class FormComponentComponent {
  @Input() class!: string
  @Input() color!: string
}
