<div class="flex items-center justify-between lg:mx-8 mx-4 py-2">
  <a routerLink="/">
    <img
      src="assets/images/logo.png"
      alt="Logo"
      height="100"
      class="h-[100px]" />
  </a>
  <div class="flex items-center gap-x-6">
    <a
      routerLink="/"
      class="group text-sky-600 transition duration-300"
      routerLinkActive="active">
      {{ "link.home" | translate }}
      <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
    </a>
    <a
      routerLink="/fusion"
      class="group text-sky-600 transition duration-300"
      routerLinkActive="active">
      {{ "link.fusion" | translate }}
      <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
    </a>
    <a
      routerLink="/structure/new"
      class="group text-sky-600 transition duration-300"
      routerLinkActive="active">
      {{ "link.new-structure" | translate }}
      <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
    </a>
    <a
      routerLink="/person/new"
      class="group text-sky-600 transition duration-300"
      routerLinkActive="active">
      {{ "link.new-person" | translate }}
      <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
    </a>
  </div>
</div>
