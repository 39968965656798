<div class="container my-8">
  <h1>{{ "structure." + type | translate }}</h1>
</div>
<div
  *ngIf="structure1 && structure2"
  class="mx-8 grid grid-cols-3 gap-12 mb-12">
  <arsb-side-structure-fusion
    [structure]="structure1"
    [rightSide]="false"
    (setValue)="setValue($event)" />
  <arsb-center-structure-fusion
    *ngIf="form"
    [nosValues]="nosValues"
    [formGroup]="form" />
  <arsb-side-structure-fusion
    [structure]="structure2"
    [rightSide]="true"
    (setValue)="setValue($event)" />
</div>
