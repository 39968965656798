<div class="relative">
  <input
    pInputText
    [value]="inputValue"
    [ngClass]="inputClass"
    disabled />
  <div class="absolute right-2 top-0 bottom-0 flex gap-2 text-gray-500">
    <span
      *ngIf="hasSetNullButton"
      class="pi pi-eraser cursor-pointer flex items-center"
      [pTooltip]="'common.erase-value' | translate"
      tooltipPosition="top"
      (click)="setNullFieldValue()"></span>
    <span
      *ngIf="hasUpdateButton && inputValue?.length"
      class="pi pi-arrow-right cursor-pointer flex items-center"
      [pTooltip]="'common.update-value' | translate"
      tooltipPosition="top"
      (click)="updateFieldValue()"></span>
    <span
      *ngIf="hasCopyButton && inputValue?.length"
      class="pi pi-copy cursor-pointer flex items-center"
      [pTooltip]="'common.copy-value' | translate"
      tooltipPosition="top"
      (click)="copyFieldValue()"></span>
  </div>
</div>
