<p-table
  #table
  [value]="items"
  [paginator]="true"
  [lazy]="true"
  [loading]="loading"
  [scrollable]="true"
  scrollHeight="600px"
  [rows]="50"
  [first]="first"
  [totalRecords]="totalRecords"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="'table.paginator' | translate"
  (onPage)="selectPage($event)"
  [sortMode]="'multiple'"
  [multiSortMeta]="[{ field: 'label', order: 1 }]"
  (onSort)="selectSorts($event)">
  <ng-template pTemplate="header">
    <tr>
      <th class="w-[5%]">{{ "table.type" | translate }}</th>
      <th pSortableColumn="label">
        {{ "table.label" | translate }}
        <p-sortIcon field="label" />
      </th>
      <th pSortableColumn="adresse">
        {{ "table.address" | translate }}
        <p-sortIcon field="adresse" />
      </th>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-index="rowIndex"
    let-item>
    <tr [routerLink]="['/', baseUrlItem(index), item.id]">
      <td><i [ngClass]="typeIcon(index)"></i></td>
      <td *ngIf="type === 'simple'">{{ item.label }}</td>
      <td *ngIf="type === 'structure'">{{ item.raisonSociale }}</td>
      <td>
        <div
          *ngIf="item?.libCommune"
          class="flex flex-col">
          <span>{{ item.adresse }}</span>
          <span>{{ item.codePostal }}, {{ item.libCommune }}</span>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
