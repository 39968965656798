import { inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '@env/environment'
import { Nos } from '@models/nos'

@Injectable({
  providedIn: 'root',
})
export class NosService {
  private readonly http = inject(HttpClient)

  constructor() {}

  getNosByCode(code: string) {
    return this.http.get<Nos[]>(`${environment.apiUrl}/noscode/${code}`)
  }
}
