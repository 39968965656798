import { Component, inject, OnInit } from '@angular/core'
import { SideStructureFusionComponent } from '@module/fusion/components/side-structure-fusion/side-structure-fusion.component'
import { CenterStructureFusionComponent } from '@module/fusion/components/center-structure-fusion/center-structure-fusion.component'
import { ActivatedRoute } from '@angular/router'
import { StructureService } from '@services/structure.service'
import { forkJoin } from 'rxjs'
import { FicheStructure } from '@models/structure'
import { NgIf } from '@angular/common'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { TranslateModule } from '@ngx-translate/core'
import { getFieldsNos } from '@shared/utils/fields-nos'
import { NosService } from '@services/nos.service'
import { Nos } from '@models/nos'
import { handleAddress } from '@shared/utils/value-form'

@Component({
  selector: 'arsb-fusion',
  standalone: true,
  imports: [SideStructureFusionComponent, CenterStructureFusionComponent, NgIf, TranslateModule, ReactiveFormsModule],
  templateUrl: './fusion.component.html',
  styleUrl: './fusion.component.scss',
})
export class FusionComponent implements OnInit {
  private readonly route = inject(ActivatedRoute)
  private readonly formBuilder = inject(FormBuilder)

  private readonly structureService = inject(StructureService)
  private readonly nosService = inject(NosService)

  type: 'structure' = this.route.snapshot.params['type']
  id1: string = this.route.snapshot.params['id1']
  id2: string = this.route.snapshot.params['id2']

  structure1?: FicheStructure
  structure2?: FicheStructure
  form?: FormGroup
  nosValues: { [key: string]: Nos[] } = {}

  ngOnInit() {
    this.setForStructure()
  }

  private setForStructure() {
    forkJoin([
      this.structureService.getFicheStructure(this.id1),
      this.structureService.getFicheStructure(this.id2),
      getFieldsNos(
        forkJoin([
          this.structureService.getStructureFields(),
          this.structureService.getStructureFields('structureCoord'),
        ]),
        this.nosService,
        [null, 'structureCoord'],
      ),
    ]).subscribe(([structure1, structure2, { fields, nosValues }]) => {
      this.structure1 = structure1
      this.structure2 = structure2
      this.nosValues = nosValues
    })
    this.form = this.formBuilder.group({
      finessEj: [null],
      finessEg: [null],
      siret: [null],
      siren: [null],
      rna: [null],
      raisonSociale: [null, [Validators.required]],
      enseigneCommerciale: [null],
      nomUsage: [null],
      secteurActiviteCode: [null],
      categorieEtablissementCode: [null],
      categorieAgrEtablissementCode: [null],
      structureCoord: this.formBuilder.group({
        complDest: [null, Validators.maxLength(38)],
        complGeo: [null, Validators.maxLength(38)],
        numVoie: [null],
        indiceRepVoie: [null],
        libTypeVoie: [null],
        libVoie: [null],
        mentionDistVoie: [null],
        bureauCedex: [null],
        codePostal: [null, [Validators.pattern('^[0-9]{5}$')]],
        libCommune: [null],
        codeCommune: [null, [Validators.pattern('^[0-9]{5}$')]],
        tel: [null],
        fax: [null],
        email: [null, Validators.email],
        portable: [null],
        telOther: [null],
        latitude: [null],
        longitude: [null],
      }),
      msses: this.formBuilder.array([]),
      activities: this.formBuilder.array([]),
      parents: this.formBuilder.array([]),
    })
  }

  setValue(event: { control: string; value: string; subGroup?: string }) {
    if (event.subGroup) {
      ;(this.form?.get(event.subGroup)! as FormGroup).controls[event.control].patchValue(event.value)
    } else {
      this.form?.controls[event.control].patchValue(event.value)
    }
  }

  save() {
    if (this.form?.invalid) return

    switch (this.type) {
      case 'structure':
        this.fusionStructure()
        break
    }
  }

  private fusionStructure() {
    const structure = {
      ...this.form?.value,
      adresse: handleAddress(this.form?.get('structureCoord') as FormGroup),
    }
    this.structureService.fusion(this.id1, this.id2, structure).subscribe(() => {})
  }
}
