import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import { NgClass, NgForOf, NgIf } from '@angular/common'
import { RouterLink } from '@angular/router'
import { Circle, circle, latLng, Layer, Map, MapOptions, Marker, tileLayer } from 'leaflet'
import { environment } from '@env/environment'
import { ModifiedItem, SearchedItem, SearchedPerson, SearchedStructure } from '@module/home/models/search'
import { numberMarker } from '@shared/utils/number-marker'
import { InfiniteScrollDirective } from 'ngx-infinite-scroll'

@Component({
  selector: 'arsb-view-map',
  standalone: true,
  imports: [LeafletModule, NgForOf, NgIf, NgClass, RouterLink, InfiniteScrollDirective],
  templateUrl: './view-map.component.html',
  styleUrl: './view-map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewMapComponent implements OnChanges {
  private readonly cdr = inject(ChangeDetectorRef)

  @Input() items: (SearchedItem | SearchedStructure | SearchedPerson)[] = []
  @Input() location?: { latitude: number; longitude: number; range: number }

  @Output() searchNextPage = new EventEmitter<void>()

  @ViewChild('scrollContainer') scrollContainer?: ElementRef

  modifiedItems: ModifiedItem[] = []
  map?: Map
  mapOptions: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        minZoom: 7,
        attribution: '...',
      }),
    ],
    zoom: 8,
    center: latLng(environment.map.lat, environment.map.lng),
  }
  layers: Layer[] = []
  selectedIndex: number = 0

  ngOnChanges(changes: SimpleChanges) {
    if (changes['location'] && this.location) {
      this.setLocation()
    }
    if (changes['items'] && this.map) {
      const currentItems = changes['items'].currentValue as SearchedItem[]
      const previousItems = changes['items'].previousValue as SearchedItem[]

      const reset = Boolean(
        previousItems.length &&
          (currentItems[0]?.id !== previousItems[0].id || currentItems.length <= previousItems.length),
      )
      this.setMarkers(reset, reset ? 0 : previousItems.length)
    }
  }

  onMapLoad(map: Map) {
    this.map = map
    this.setMarkers()
    this.setLocation()
  }

  private setMarkers(reset: boolean = true, alreadyChargedLength?: number) {
    if (reset) {
      this.selectedIndex = 0
      this.modifiedItems = []
      this.scrollContainer?.nativeElement.scrollTo(0, 0)
      this.layers = this.layers.filter((layer) => !(layer instanceof Marker))
    }

    this.items.slice(alreadyChargedLength).forEach((item, index) => {
      let m: Marker
      if ('label' in item && 'adresse' in item) {
        // SearchedItem
        m = numberMarker(index + 1, item.latitude, item.longitude).bindPopup(`<b>${item.label}</b><br>${item.adresse}`)
        this.modifiedItems.push({
          ...item,
          type: item.type.toLowerCase() as 'person' | 'structure',
          latitude: item.latitude,
          longitude: item.longitude,
          label: item.label,
          adresse: item.adresse,
          codePostal: item.codePostal,
          libCommune: item.libCommune,
        })
      }

      if ('raisonSociale' in item) {
        // SearchedStructure
        m = numberMarker(index + 1, item.latitude, item.longitude).bindPopup(
          `<b>${item.raisonSociale}</b><br>${item.adresse}`,
        )
        this.modifiedItems.push({
          ...item,
          type: 'structure',
          latitude: item.latitude,
          longitude: item.longitude,
          label: item.raisonSociale,
          adresse: item.adresse,
          codePostal: item.codePostal,
          libCommune: item.libCommune,
        })
      }

      m!
        .on('popupopen', () => {
          this.selectedIndex = index + 1
          this.cdr.detectChanges()
          this.scrollToItem(index)
        })
        .on('popupclose', () => {
          this.selectedIndex = 0
        })
      this.layers.push(m!)
    })
  }

  private scrollToItem(index: number) {
    if (this.scrollContainer) {
      const element = this.scrollContainer.nativeElement.querySelectorAll('li')[index]
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      }
    }
  }

  private setLocation() {
    this.layers = this.layers.filter((layer) => !(layer instanceof Circle))
    if (!this.location?.longitude || !this.location?.latitude || !this.location?.range) {
      this.map?.setView(latLng(environment.map.lat, environment.map.lng), 8)
      return
    }

    const { latitude, longitude, range } = this.location!
    this.map?.setView(latLng(latitude, longitude), this.getZoom(range))
    this.layers.push(circle([latitude, longitude], { radius: range, fillOpacity: 0.05 }))
  }

  private getZoom(range: number) {
    // Définir les distances min et max visibles
    const minDistance = 200 // distance en mètres pour le zoom max
    const maxDistance = 10000000 // distance en mètres pour le zoom min
    const minZoom = 0 // Niveau de zoom le plus bas
    const maxZoom = 18 // Niveau de zoom le plus élevé

    // Vérifiez si la distance est dans le cadre des valeurs minimales et maximales
    if (range <= minDistance) return maxZoom
    if (range >= maxDistance) return minZoom

    // Calcul du niveau de zoom basé sur une échelle logarithmique
    return Math.floor(
      maxZoom - (Math.log(range / minDistance) / Math.log(maxDistance / minDistance)) * (maxZoom - minZoom),
    )
  }

  onScroll() {
    this.searchNextPage.emit()
  }
}
