export const environment = {
  production: false,
  apiUrl: '${API_URL}',
  map: {
    lat: 48,
    lng: -2.4,
  },
  keycloak: {
    url: '${KEYCLOAK_URL}',
    realm: '${KEYCLOAK_REALM}',
    clientId: '${KEYCLOAK_CLIENT_ID}',
  },
}
